import styles from "./Checkbox.module.css";
import cuid from "cuid";
import cx from "classnames";
import { Typography } from "../typography";
import { neutralBlack } from "../colorsPalette/colors/neutralBlack";
import { neutralWhite } from "../colorsPalette/colors/neutralWhite";
import { useMemo } from "react";

interface Props {
  checked?: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
}

export const Checkbox = ({ checked, onChange, disabled, indeterminate, label }: Props) => {
  const id = useMemo(() => cuid(), []);

  return (
    <div
      className={cx(styles.container, {
        [styles.disabled]: disabled,
        [styles.indeterminate]: indeterminate,
      })}
      onClick={() => onChange(!checked)}
    >
      <label
        htmlFor={id}
        className={cx(styles.checkMark, {
          [styles.checkMarkDisabled]: disabled,
          [styles.checkMarkActive]: checked,
          [styles.checkMarkIndeterminate]: indeterminate,
        })}
      >
        <div className={styles.mark}>
          {checked && !indeterminate && (
            <CheckBoxChecked
              fill={disabled ? neutralBlack.neutralBlack48 : neutralWhite.neutralWhite100}
            />
          )}
        </div>
      </label>
      <input
        id={id}
        type="checkbox"
        className={styles.input}
        checked={checked}
        disabled={disabled}
      />
      <Typography fontSize="12" color="neutralBlack88" fontWeight="700" className="ml-2">
        {label}
      </Typography>
    </div>
  );
};

const CheckBoxChecked = ({ fill }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="transparent"
    >
      <path
        d="M12.1061 5.20707C12.4965 5.5975 12.4965 6.23052 12.1061 6.62099L7.45011 11.2778C7.05959 11.6684 6.42638 11.6684 6.03583 11.2778L4.207 9.449C3.81653 9.05853 3.81653 8.42547 4.207 8.035C4.59747 7.64453 5.23053 7.64454 5.621 8.035L6.743 9.157L10.692 5.20714C11.0825 4.81661 11.7156 4.81658 12.1061 5.20707Z"
        fill={fill}
      />
    </svg>
  );
};
