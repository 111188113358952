import { cx, getAnyErrorKey, getIsoDateFormat } from "utilities";
import styles from "./AssignPlannedPickupArrivalModal.module.css";
import { HighlightedRow } from "api/other/models";
import { ToggleHookState, useToastr } from "hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Formik } from "formik";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useMutation } from "hooks/useMutation";
import { shippingApi } from "api/shipping/calls";
import { FormikErrorMessage } from "components/utils/errorMessage/FormikErrorMessage";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";

interface Props {
  modal: ToggleHookState;
  selectedItems: HighlightedRow[];
  resetSelection: () => void;
  closePanel: () => void;
}

export const AssignPlannedPickupArrivalModal = ({
  closePanel,
  modal,
  resetSelection,
  selectedItems,
}: Props) => {
  const toastr = useToastr();

  const reset = () => {
    modal.close();
    resetSelection();
  };

  const initialValues = {
    shipments: selectedItems.map(e => String(e.id)),
    plannedPickupArrivalDate: undefined,
    plannedPickupArrivalTime: undefined,
  };

  const patchPlannedArrivalMutation = useMutation(
    shippingApi.patchShippingShipments,
    ({ queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        closePanel();
        resetSelection();
        return;
      },
    }),
  );

  return (
    <Modal
      close={reset}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Przypisz datę odbioru przez kuriera
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => {
          patchPlannedArrivalMutation.mutate(values, {
            onSuccess: () => {
              helpers.setSubmitting(false);
            },
            onError: error => {
              helpers.setSubmitting(false);
              helpers.setErrors(error.response?.data);
              toastr.open({
                type: "warning",
                title: "Wymagane działanie...",
                text: getAnyErrorKey(error),
              });
            },
          });
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values, errors }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex flex-column px-3 mb-1">
              <div className="d-flex align-items-center gap-2">
                <DatePicker
                  look="common"
                  tabIndex={-1}
                  overwrites={{
                    popup: { className: styles.datePickerPopup },
                    input: { className: styles.datePickerInput },
                    container: { className: cx(styles.datePickerInput, "pl-0") },
                  }}
                  value={values.plannedPickupArrivalDate || null}
                  onChange={date => {
                    if (date) {
                      setFieldValue("plannedPickupArrivalDate", getIsoDateFormat(date));
                    }
                  }}
                />
                <TimePicker
                  look="common"
                  onBlur={time => setFieldValue("plannedPickupArrivalTime", time)}
                  overwrites={{
                    container: { className: styles.formHeight },
                    input: { className: styles.formHeight },
                  }}
                  value={values.plannedPickupArrivalTime || null}
                />
              </div>

              <FormikErrorMessage name="plannedPickupArrivalDate" />
              <FormikErrorMessage name="plannedPickupArrivalTime" />
            </div>

            <FormikErrorMessage name="detail" />
            <div className="d-flex align-items-center gap-3 p-3">
              <Button onClick={reset} size="medium" variant="gray" className="text-uppercase">
                Anuluj
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                size="medium"
                variant="deepPurple"
                className="text-uppercase"
              >
                Przypisz{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
