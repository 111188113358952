import { PageHeader } from "components/common";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { MdiAreaChart } from "components/miloDesignSystem/atoms/icons/MdiAreaChart";

export const Header = () => {
  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <IconButton icon={MdiDownloadFile} variant="blackT" />
            <IconButton icon={MdiUploadFile} variant="blackT" />
            <IconButton icon={MdiVisibilityOff} variant="blackT" />
            <IconButton icon={MdiAreaChart} variant="blackT" />
          </div>
        }
        bigTitle="Zestawienie zbiorcze"
        searchBar={false}
        viewLabel="WAREHOUSE_OVERVIEW"
      />
    </div>
  );
};
