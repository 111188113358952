import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

interface Props {
  tradingDocument?: { type: TradingDocumentType; invoiceType: InvoiceType };
  tradingDocumentsString: string;
  downloadFeedbackToastr: ReturnType<typeof useDownloadFeedbackToastr>;
}
export const handleDownloadMultipleOrderXml = async ({
  tradingDocument = undefined,
  tradingDocumentsString,
  downloadFeedbackToastr,
}: Props) => {
  if (!tradingDocument) throw new Error("An error occurred");
  const tstr = downloadFeedbackToastr.open({ type: "xml", calculateProgress: false });
  const { url, name } = tradingDocumentFileFactory.tradingDocumentMultipleXml(
    tradingDocument,
    tradingDocumentsString,
  );
  const response = await fileDownloader({
    url,
    name,
    type: "xml",
  });

  if (response.status === "success") {
    tstr.lazyClose();
  }
};
