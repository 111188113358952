import { OrderPositionForCreatingInvoice } from "api/trading-documents/models";
import { Assign } from "utility-types";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";

export const getInitialValues = (
  positions: (OrderPositionForCreatingInvoice & { orderSignature: string })[],
): CreateCorrectionValues => {
  // @ts-ignore
  const initialPositions: Assign<
    (OrderPositionForCreatingInvoice & { orderSignature: string })[],
    { isPositionEdited: boolean }
  > = positions.map(position => ({
    ...position,
    isPositionEdited: false,
  }));

  return {
    positions: initialPositions,
  };
};
