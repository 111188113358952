import { RouteLineItem, ScheduleConfirmationStatus } from "api/external-manufacturing/models";
import { DatePicker } from "components/utils/datePicker";
import styles from "../RouteLineItems.module.css";
import { RouteSimplified } from "api/logistics/routes/models";
import { isEqual } from "date-fns";
import { cx, getEditableDateFormat } from "utilities";
import { externalManufacturingActions } from "api/external-manufacturing/actions";

interface Props {
  routeDetails: RouteSimplified | null;
  routeLineItem: RouteLineItem;
}

export const PlannedPickupDate = ({ routeDetails, routeLineItem }: Props) => {
  const pickupBySellerPlannedAtMutation = externalManufacturingActions.usePatchMainRouteLineItem();

  const areDepartureAndPickupDatesEqual = (): boolean => {
    if (
      routeLineItem.pickupBySellerPlannedAt &&
      routeDetails &&
      routeDetails.loadingDate &&
      isEqual(
        new Date(getEditableDateFormat(routeLineItem.pickupBySellerPlannedAt)),
        new Date(getEditableDateFormat(routeDetails.loadingDate)),
      )
    )
      return true;
    return false;
  };

  const isDepartureEarlierThanPickUp = (): boolean => {
    if (
      routeLineItem.pickupBySellerPlannedAt &&
      routeDetails &&
      routeDetails.loadingDate &&
      new Date(routeLineItem.pickupBySellerPlannedAt) > new Date(routeDetails.loadingDate)
    )
      return true;
    return false;
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <DatePicker
        disabled={
          pickupBySellerPlannedAtMutation.isLoading ||
          routeLineItem.scheduleConfirmationStatus === ScheduleConfirmationStatus.CONFIRMED
        }
        look="common"
        overwrites={{
          popup: { className: styles.datePickerPopup },
          input: {
            className: cx(styles.datePickerInput, {
              [styles.equalWithDeparture]: areDepartureAndPickupDatesEqual(),
              [styles.departureEarlierThanPickup]: isDepartureEarlierThanPickUp(),
            }),
          },
          container: { className: styles.datePickerInput },
        }}
        value={routeLineItem.pickupBySellerPlannedAt || ""}
        onChange={date => {
          if (!date) {
            return pickupBySellerPlannedAtMutation.mutate({
              id: routeLineItem.id,
              toUpdate: {
                pickupBySellerPlannedAt: null,
              },
            });
          }
          return pickupBySellerPlannedAtMutation.mutate({
            id: routeLineItem.id,
            toUpdate: {
              pickupBySellerPlannedAt: getEditableDateFormat(date),
            },
          });
        }}
      />
    </div>
  );
};
