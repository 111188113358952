import { PropsWithChildren } from "react";
import { cx } from "utilities";
import { TypographyProps } from "./types";
import styles from "./Typography.module.css";
import { colorPalette } from "../colorsPalette";
import { Assign } from "utility-types";

export const Typography = (props: PropsWithChildren<TypographyProps>) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color:
          props.color === "inherit" ? "inherit" : colorPalette[props.color || "neutralBlack100"],
      }}
      className={cx(styles.typography, styles[`fs${props.fontSize}`], props.className, {
        [styles.noWrap]: props.noWrap,
      })}
    >
      {props.children}
    </div>
  );
};

Typography.WithCustomColor = (
  props: PropsWithChildren<Assign<TypographyProps, { color: string }>>,
) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color: props.color,
      }}
      className={cx(styles.typography, styles[`fs${props.fontSize}`], props.className, {
        [styles.noWrap]: props.noWrap,
      })}
    >
      {props.children}
    </div>
  );
};
