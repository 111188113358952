import { HighlightedRow } from "api/other/models";
import { shippingActions } from "api/shipping/actions";
import { ShipmentListItem, ShipmentStatus } from "api/shipping/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPackageSend } from "components/miloDesignSystem/atoms/icons/MdiPackageSend";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { useQuery, useToggle } from "hooks";
import { useState } from "react";
import { cx, queryString } from "utilities";
import styles from "./ShippingShipments.module.css";
import { useShippingShipmentsColumns } from "./useShippingShipmentsColumns";
import { SendShipmentToExternalModal } from "./components/sendShipmentToExternalModal/SendShipmentToExternal";
import { RightPanel } from "./rightPanel/RightPanel";
import { AssignCourier } from "./components/assignCourier/AssignCourier";
import { shippingShipments } from "components/common/moduleNavigation/moduleConfig/logistics/routes/shippingShipments";
import { RouteComponentProps } from "react-router";
import { MdiLocalShipping } from "components/miloDesignSystem/atoms/icons/MdiLocalShipping";
import { MdiCalendarClock } from "components/miloDesignSystem/atoms/icons/MdiCalendarClock";
import { AssignPlannedPickupArrivalModal } from "./components/assignPlannedPickupArrivalModal/AssignPlannedPickupArrivalModal";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";

export const ShippingShipments = ({
  match,
}: RouteComponentProps<{ tab: ShippingShipmentsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);
  const assignShipmentModalController = useToggle();
  const assignPlannedPickupArrivalController = useToggle();
  const sendShipmentToExternalController = useToggle();
  const downloadLabels = shippingActions.useDownloadShippingLabels();
  const cancelShipmentsMutation = shippingActions.useCancelShipments();

  const { data: shipments, error, isLoading, pagination } = shippingActions.useShippingShipments(
    search,
  );

  const columns = useShippingShipmentsColumns();

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          bottomBarButtons={
            <Button
              size="small"
              variant="gray"
              onClick={() => {
                sendShipmentToExternalController.open();
              }}
              endIcon={MdiPackageSend}
              className="text-uppercase"
            >
              zamów kuriera na przesyłki
            </Button>
          }
          tabs={{
            list: getTabs(),
            routesRoot: `logistics/${shippingShipments.url}`,
            urlSpan: "list",
          }}
          viewLabel="SHIPPING_SHIPMENTS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <DrawerHelpers name="shippingShipments">
            {drawerHelpers => (
              <>
                <Table<ShipmentListItem>
                  rows={shipments}
                  columns={columns}
                  onPaginationChange={paginationState => {
                    updateQuery({ ...query, page: paginationState.pageIndex });
                  }}
                  isLoading={isLoading}
                  error={error}
                  pagination={pagination}
                  uiSchema={mainListUiSchema}
                  overrides={() => {
                    return {
                      row: row => {
                        const showDashedRow = [
                          ShipmentStatus.CANCELED_SHIPMENT_SUCCESS,
                          ShipmentStatus.CANCELLATION_IN_PROGRESS,
                        ].includes(row.status);

                        const isRowSelected = highlightedRows.find(r => r.id === row.id);

                        return {
                          className: cx(
                            getTablePropsBasedOnDrawerHelpers(drawerHelpers)?.overrides!().row?.(
                              row,
                            )!.className,
                            {
                              dashedTableRow: showDashedRow,
                              [styles.selected]: isRowSelected,
                            },
                          ),
                        };
                      },
                    };
                  }}
                  onRowClick={(rowId, e) => {
                    const index = shipments.findIndex(e => e.id === rowId);

                    handleMultipleRowsHighlight(
                      e,
                      highlightedRows,
                      rowId,
                      index,
                      setHighlightedRows,
                      () => drawerHelpers.togglePanel(rowId),
                      shipments,
                    );
                  }}
                />

                <RightPanel />
              </>
            )}
          </DrawerHelpers>
          {sendShipmentToExternalController.isOpen && (
            <SendShipmentToExternalModal modal={sendShipmentToExternalController} />
          )}
          {assignShipmentModalController.isOpen && (
            <AssignCourier
              selectedItems={highlightedRows}
              modal={assignShipmentModalController}
              resetSelection={() => setHighlightedRows([])}
              closePanel={assignShipmentModalController.close}
            />
          )}
          {assignPlannedPickupArrivalController.isOpen && (
            <AssignPlannedPickupArrivalModal
              selectedItems={highlightedRows}
              modal={assignPlannedPickupArrivalController}
              resetSelection={() => setHighlightedRows([])}
              closePanel={assignPlannedPickupArrivalController.close}
            />
          )}
          <TableToolbar
            close={() => {
              setHighlightedRows([]);
            }}
            numberOfSelectedItems={highlightedRows.length}
          >
            <Tooltip title="Przypisz kuriera">
              <IconButton
                icon={MdiLocalShipping}
                variant="whiteT"
                onClick={() => {
                  assignShipmentModalController.open();
                }}
              />
            </Tooltip>
            <Tooltip title="Przypisz datę odbioru przez kuriera">
              <IconButton
                icon={MdiCalendarClock}
                variant="whiteT"
                onClick={() => {
                  assignPlannedPickupArrivalController.open();
                }}
              />
            </Tooltip>
            <Tooltip title="Pobierz etykiety">
              <IconButton
                icon={MdiQrCode}
                variant="whiteT"
                onClick={() => {
                  downloadLabels(highlightedRows.map(row => String(row.id)));
                }}
              />
            </Tooltip>
            <Tooltip title="Anuluj przesyłki">
              <IconButton
                icon={MdiCancel}
                variant="whiteT"
                onClick={() => {
                  cancelShipmentsMutation.mutate({
                    shipments: highlightedRows.map(row => String(row.id)),
                  });
                }}
              />
            </Tooltip>
          </TableToolbar>
        </div>
      </PageWrapper>
    </>
  );
};

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: ShippingShipmentsTab;
};

const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status: tab === "called-pickup-success" ? ShipmentStatus.CALLED_PICKUP_SUCCESS : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

type ShippingShipmentsTab = "all" | "called-pickup-success";

const getTabs = (): { label: string; name: ShippingShipmentsTab }[] => {
  return [
    { label: "Wszystkie", name: "all" },
    { label: "Przesłane do kuriera", name: "called-pickup-success" },
  ];
};
