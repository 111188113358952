import { LoadingsTab } from "api/wms/models";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import { useLoadings } from "api/wms/hooks";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { loadings as loadingsApi } from "components/common/moduleNavigation/moduleConfig/wms/routes/loadings";
import { getTabs } from "./utils/getTabs";
import { useLoadingsListColumns } from "./hooks/useLoadingsListColumns";
import { RightPanel } from "./rightPanel/RightPanel";

export const LoadingsList = ({ match }: RouteComponentProps<{ tab: LoadingsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data: loadings, error, isPreviousData, isLoading, pagination } = useLoadings(search);
  const columns = useLoadingsListColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród załadunków",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `wms/${loadingsApi.url}`,
          urlSpan: "list",
        }}
        viewLabel="WMS_LOADINGS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <DrawerHelpers name="wmsLoadings">
          {drawerHelpers => (
            <>
              <Table<any>
                rows={loadings}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
      </div>
    </PageWrapper>
  );
};
