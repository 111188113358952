import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { HighlightedRow } from "api/other/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { useState } from "react";
import { cx, getEditableDateFormat } from "utilities";
import styles from "../../../externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";

interface Props {
  highlightedRows: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
}

export const TableToolbarActions = ({ highlightedRows, setHighlightedRows }: Props) => {
  const bulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateMainLineItems();
  const [pickupBySellerPlannedAt, setPickupBySellerPlannedAt] = useState("");

  return (
    <>
      <TableToolbar
        close={() => setHighlightedRows([])}
        numberOfSelectedItems={highlightedRows.length}
      >
        <Popover
          hideOnClickOutside={false}
          content={({ hide }) => (
            <ClickOutsideHandler
              onClickOutside={() => {
                hide();
                setPickupBySellerPlannedAt("");
              }}
              outsideClickIgnoreClass={ignoreClickOutsideClassName}
            >
              <div>
                <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                  Wskaż nową datę odbioru
                </Typography>
                <div className="d-flex">
                  <div className={cx(styles.datePicker, "position-relative mr-1")}>
                    <DatePicker
                      calendarClassName={ignoreClickOutsideClassName}
                      overwrites={{
                        container: { className: ignoreClickOutsideClassName },
                        popup: {
                          className: cx(ignoreClickOutsideClassName, styles.datePicker),
                        },
                      }}
                      placeholder="Wybierz"
                      className={styles.date}
                      value={pickupBySellerPlannedAt}
                      selectsStart
                      removeDate={false}
                      tabIndex={-1}
                      onChange={date => {
                        if (!date) return;
                        setPickupBySellerPlannedAt(getEditableDateFormat(date));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-1 justify-content-end mt-3">
                  <Button
                    className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                    size="small"
                    variant="deepPurple"
                    onClick={() => {
                      bulkUpdateMutation.mutate(
                        {
                          ids: highlightedRows.map(e => String(e.id)),
                          pickupBySellerPlannedAt,
                        },
                        {
                          onSuccess: hide,
                        },
                      );
                    }}
                    isLoading={bulkUpdateMutation.isLoading}
                  >
                    Zmień datę odbioru
                  </Button>
                </div>
              </div>
            </ClickOutsideHandler>
          )}
          variant="DARK"
        >
          <div>
            <Tooltip title="Zmień datę odbioru">
              <IconButton icon={MdiEvent} variant="whiteT" />
            </Tooltip>
          </div>
        </Popover>
      </TableToolbar>
    </>
  );
};

const ignoreClickOutsideClassName = "main-manufacturer-line-items-popover-ignore-class-name";
