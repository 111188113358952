/**
 * It replaces values that are objects to their id
 */
export function parsePatchData(data: Record<string, any>) {
  return Object.entries(data).reduce((acc: Record<any, any>, [key, value]) => {
    if (key === "id") return acc;
    if (value === null) {
      acc[key] = value;
      return acc;
    } else if (typeof value === "object") {
      acc[key] = value.id;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}
