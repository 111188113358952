import { DialogOverlay, DialogContent } from "@reach/dialog";
import { animated, useTransition } from "react-spring";
import styles from "./Modal.module.css";
import { ModalProps } from "./types";
import { IconButton } from "../iconButton";
import { MdiClose } from "../icons/MdiClose";

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

export const Modal = ({
  isOpen,
  close,
  children,
  removeHeader = false,
  width,
  title,
  size,
}: ModalProps) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: 10 },
    config: { duration: 150 },
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props: animationStyles }: any) =>
          item && (
            <AnimatedDialogOverlay
              className={styles.overlay}
              style={{
                opacity: animationStyles.opacity,
                zIndex: 21,
                display: size === "auto" ? "flex" : undefined,
              }}
              onDismiss={close}
              key={key}
            >
              <AnimatedDialogContent
                aria-label="Wybieranie lokalizacji zamówienia"
                style={{
                  ...(size === "auto" ? { margin: "auto" } : {}),
                  width: width,
                  minWidth: width,
                  maxWidth: width,
                  transform: animationStyles.top.interpolate(
                    (value: number) => `translate3d(0px, ${value}px, 0px)`,
                  ),
                  padding: 0,
                }}
                className={styles.content}
              >
                {!removeHeader && (
                  <div className="d-flex justify-content-between p-3">
                    {title}
                    <IconButton icon={MdiClose} onClick={close} variant="blackT" />
                  </div>
                )}
                {children}
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          ),
      )}
    </div>
  );
};
