import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { Button } from "components/common";
import downloadXmlIcon from "assets/images/downloadXml.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import moreOptionsIcon from "assets/images/threeDots.svg";
import historyIcon from "assets/images/history.svg";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { handleDownloadOrderXml } from "pages/tradingDocuments/shared/utils/handleDownloadOrderXml";
import { Link } from "react-router-dom";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { DeleteTradingDocumentBtn } from "pages/tradingDocuments/shared/components/deleteTradingDocumentBtn/DeleteTradingDocumentBtn";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { fileDownloader } from "fileDownloader";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ close, isScrolledOver }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: correctionInvoice } = useTradingDocument({ id: panelId });
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  if (!correctionInvoice) return null;

  const handleCorrectionPdfDownload = async () => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.correctionPdf(correctionInvoice);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  };

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
        panelHeaderDuringSlide: isScrolledOver,
      })}
    >
      {isScrolledOver ? (
        <div className="slidingPanelHeader">
          <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
            <ConfirmCheckbox tradingDocument={correctionInvoice} />
            <div>Faktura VAT&nbsp;{correctionInvoice.signature}</div>
          </div>
        </div>
      ) : (
        <ConfirmTradingDocument tradingDocument={correctionInvoice} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Button
          kind="transparent-black"
          onClick={() => {
            handleDownloadOrderXml(correctionInvoice, downloadFeedbackToastr);
          }}
          size="square-s"
          title="Pobierz XML z danymi korekty"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Pobierz XML" src={downloadXmlIcon} />
          </div>
        </Button>
        <Tooltip title="Pobierz PDF korekty">
          <IconButton
            icon={<MdiDownloadPdf size="18" />}
            onClick={handleCorrectionPdfDownload}
            variant="blackT"
          />
        </Tooltip>
        <Button kind="transparent-black" size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Więcej opcji"
              src={moreOptionsIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
        <span className="divider line-divider" />
        <Button
          as={Link}
          kind="transparent-black"
          size="square-s"
          title="Historia korekty"
          to={{
            pathname: `/finances/corrections/${panelId}/history`,
            state: { correctionInvoice },
          }}
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Wyświetl historię"
              src={historyIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
        <span className="divider line-divider" />
        <DeleteTradingDocumentBtn
          close={close}
          tradingDocumentId={correctionInvoice.id}
          tradingDocumentSignature={correctionInvoice.signature}
          tradingDocumentStatus={correctionInvoice.status}
        />
        <span className="divider line-divider" />
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Zamknij panel"
              src={hidePanelIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};
