import printerIcon from "assets/images/printStatus.svg";
import lastPrintedIcon from "assets/images/printConnect.svg";
import { Button } from "components/common";
import { ToggleHookState } from "hooks";
import arrowRightIcon from "assets/images/arrowForward.svg";
import styles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import { FinancesAmountSummary } from "api/trading-documents/models";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  amountSummaryModal: ToggleHookState;
  currentlyPrintedReceiptsModal: ToggleHookState;
  isLoading: boolean;
  lastPrintedReceiptsModal: ToggleHookState;
}

export const BottomBarOptions = ({
  amountSummary,
  amountSummaryModal,
  currentlyPrintedReceiptsModal,
  isLoading,
  lastPrintedReceiptsModal,
}: Props) => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <div className="d-flex align-items-center justify-content-end gap-1">
            <div className={styles.labelText}>netto:</div>
            <strong className="body-16-800">
              {amountSummary
                ? Number(amountSummary.totalAmountWithoutTax.toFixed(2)).toLocaleString("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : isLoading
                ? "..."
                : "brak danych"}{" "}
              PLN
            </strong>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-1">
            <div className={styles.labelText}>VAT:</div>
            <strong className="body-16-800">
              {amountSummary
                ? Number(amountSummary.totalTax.toFixed(2)).toLocaleString("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : isLoading
                ? "..."
                : "brak danych"}{" "}
              PLN
            </strong>
          </div>
          <Button
            className={styles.smallBtnHeight}
            disabled={isLoading}
            kind="create"
            onClick={amountSummaryModal.open}
          >
            <div className="btnBase btnBaseSmall">
              Szczegóły
              <img alt="Przejdź" src={arrowRightIcon} />
            </div>
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        <span className="line-divider lineDividerMargin" />
        <Button
          kind="transparent-black"
          onClick={lastPrintedReceiptsModal.open}
          size="square-s"
          title="Ostatnio wydrukowane"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Paragony w druku"
              src={lastPrintedIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
        <span className="line-divider lineDividerMargin" />
        <Button
          kind="transparent-black"
          onClick={currentlyPrintedReceiptsModal.open}
          size="square-s"
          title="Paragony w druku"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Paragony w druku"
              src={printerIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </>
  );
};
