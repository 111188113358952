import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { PriorityLabel } from "../shared/components/PriorityLabel";
import { ExecutionStatus } from "./components/ExecutionStatus";
import { EstimatedDelivery } from "./components/EstimatedDelivery";
import { cx, dateFns, getStandardDateFormat } from "utilities";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import styles from "../shared/ExternalManufacturerPanel.module.css";
import { ExternalManufacturerLineItem } from "api/external-manufacturing/models";
import { countryCodesDict } from "CONSTANTS";
import { isWithinInterval } from "date-fns";
import { PickedUpBy } from "../shared/components/PickedUpBy";
import { CreatedAtLabel } from "../shared/components/CreatedAtLabel";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { DeliveryDeadline } from "../shared/components/DeliveryDeadline";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";

export const useManufacturerLineItemsColumns = () => {
  const handleDownloadExternalManufacturingLineItemLabelPdf = externalManufacturingActions.useDownloadExternalManufacturingLineItemsLabelsPdf();

  return useCreateTableColumns<ExternalManufacturerLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 210,
        cell: info => {
          const lineItem = info.getValue();
          const today = new Date();
          return (
            <div
              className={cx(
                styles.signatureContainer,
                "d-flex align-items-center justify-content-between pr-2",
              )}
            >
              <Typography fontSize="14" fontWeight="700">
                {lineItem.signature}4
              </Typography>
              {isWithinInterval(new Date(lineItem.createdAt), {
                start: dateFns.subDays(today, 1),
                end: today,
              }) &&
                !lineItem.isCancelled && <Tag label="NOWE" type="outlined" variant="success" />}
              {lineItem.isCancelled && <Tag label="anulowane" variant="warning" />}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.manufacturerName, {
        header: "produkt",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row.product, {
        header: "atrybuty",
        size: 250,
        cell: info => {
          const product: ExternalManufacturerLineItem["product"] = info.getValue();
          if (!product || !Boolean(product.attributes.length)) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center noWrap sofiaSansFont">
              <Attributes
                attributes={product.attributes.map(attribute => ({
                  id: attribute.id,
                  label: attribute.name,
                  value: attribute.value,
                }))}
                noWrap
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.address, {
        header: "kraj",
        size: 50,
        cell: info => {
          const address = info.getValue();
          if (!Boolean(address) || !address.countryCode || !Boolean(address.countryCode.length))
            return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <img
                alt="Flaga kraju"
                src={countryCodesDict[address.countryCode]}
                style={{ height: 18, width: 18 }}
              />
              <Typography fontSize="10" fontWeight="700">
                {address.countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.order.signature, {
        header: "zamówienie",
        size: 110,
        typographyProps: {
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: "",
        size: 25,
        cell: info => {
          const priority = info.getValue();
          return <PriorityLabel priority={priority} />;
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa odbiorcy",
        size: 100,
      }),
      columnHelper.accessor(row => row.executionStatus, {
        header: "status realizacji",
        size: 110,
        cell: info => {
          const status = info.getValue();
          return <ExecutionStatus status={status} />;
        },
      }),
      columnHelper.text(row => row.deliveryGroup.signature, {
        header: "trasa",
        size: 100,
        typographyProps: {
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.deliveryDeadlineDate, {
        header: "dostarczyć do:",
        size: 160,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return <DeliveryDeadline date={date} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "planowy odbiór",
        size: 180,
        cell: info => {
          const lineItem: ExternalManufacturerLineItem = info.getValue();
          if (!lineItem.shippingInfo) return <EmptyValue />;
          if (lineItem.shippingInfo.pickedUpBySeller && lineItem.shippingInfo.pickedUpAt)
            return (
              <Typography fontSize="14" fontWeight="700">
                {getStandardDateFormat(lineItem.shippingInfo.pickedUpAt)}
              </Typography>
            );
          return (
            <EstimatedDelivery
              estimatedDelivery={lineItem.shippingInfo.pickupBySellerPlannedAt}
              fontSize="12"
              fontWeight="400"
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "odebrano",
        size: 140,
        cell: info => {
          const lineItem: ExternalManufacturerLineItem = info.getValue();
          if (!lineItem.shippingInfo) return <EmptyValue />;
          if (!lineItem.shippingInfo.pickedUpBySeller)
            return <Tag label="NIE ODEBRANO" type="outlined" variant="quaternary" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Tag label="ODEBRANO" variant="success" />
              {lineItem.shippingInfo.pickedUpAt && (
                <Typography color="success500" fontSize="14" fontWeight="700">
                  {getStandardDateFormat(lineItem.shippingInfo.pickedUpAt)}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kto odbiera?",
        size: 130,
        cell: info => {
          const lineItem: ExternalManufacturerLineItem = info.getValue();
          return <PickedUpBy shipment={lineItem.shippingInfo} />;
        },
      }),
      columnHelper.accessor(row => row.orderedAt, {
        header: "zlecono dn.",
        size: 100,
        cell: info => {
          const orderedAt: ExternalManufacturerLineItem["orderedAt"] = info.getValue();
          if (!orderedAt) return <EmptyValue />;
          return <CreatedAtLabel createdAt={orderedAt} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const lineItem = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadExternalManufacturingLineItemLabelPdf(
                    lineItem.id,
                    lineItem.signature,
                  );
                }}
                variant="blackST"
              />
            </div>
          );
        },
      }),
    ];
  });
};
