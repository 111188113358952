import { AvailableFilters } from "components/common/filters/types";
import cx from "classnames";
import { TypeBasedFilter } from "components/common/filters/TypeBasedFilter";
import styles from "./FilterList.module.css";

interface Props<T> {
  filters: AvailableFilters<T>[];
}

export const FilterList = <T extends string>({ filters }: Props<T>) => {
  return (
    <div className={styles.filtersBody}>
      {filters.map((filter, index) => (
        <div className={cx(styles.wrapper, { "border-0": index === 0 })} key={filter.label}>
          <p className={styles.label}>{filter.label}</p>
          <TypeBasedFilter filter={filter} />
        </div>
      ))}
    </div>
  );
};
