import { HighlightedRow } from "api/other/models";
import { useTradingDocuments } from "api/trading-documents/hooks";
import { Tab } from "api/trading-documents/models";
import { UUID } from "api/types";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { Loader } from "components/utils/loader";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { logisticsRouteTradingDocumentsListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { NoResults } from "components/utils/noResults";
import { useQuery } from "hooks";
import { ActionToolbar } from "pages/tradingDocuments/shared/components/actionToolbar/ActionToolbar";
import { useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { getSearch } from "./utils/getSearch";

export const RouteInvoicesList = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const params = useParams<{ routeId: string }>();
  const search = getSearch({ query, tab, route: params.routeId });

  const { data: tradingDocuments, error, isLoading, pagination } = useTradingDocuments(search);

  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  const noResults = !tradingDocuments.length && !isLoading && !error;

  if (error) {
    return (
      <div>
        <ListHeader routeId={params.routeId} />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader module="logistics" />
        <ListHeader routeId={params.routeId} />
      </div>
    );
  }
  const selectMutlipleItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => {
    handleMultipleRowsHighlight(
      event,
      highlightedRows,
      id,
      index,
      setHighlightedRows,
      togglePanel,
      tradingDocuments,
    );
  };

  return (
    <div className="position-relative h-100">
      <ListHeader routeId={params.routeId} />
      <RightPanelHandler name="logisticsRouteTradingDocuments">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            {noResults ? (
              <NoResults on={noResults} subHeader="dokumenty handlowe" />
            ) : (
              <ModuleList>
                <ModuleListHeader
                  gridStyle={logisticsRouteTradingDocumentsListConfig.grid}
                  labels={logisticsRouteTradingDocumentsListConfig.labels}
                />
                <ModuleListBody>
                  {tradingDocuments.map((tradingDocument, index) => (
                    <ListItem
                      highlightedRows={highlightedRows}
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={tradingDocument.id}
                      route={params.routeId}
                      selectMutlipleItems={selectMutlipleItems}
                      togglePanel={togglePanel}
                      tradingDocument={tradingDocument}
                    />
                  ))}
                </ModuleListBody>
              </ModuleList>
            )}
            {/* RightPanel */}
            <InvoicePagination pagination={pagination} />
            {highlightedRows.length > 0 && (
              <ActionToolbar
                actualPage={query.page}
                close={() => {
                  setHighlightedRows([]);
                }}
                quantity={highlightedRows.length}
                highlightedRows={highlightedRows}
                setHighlightedRows={setHighlightedRows}
              />
            )}
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};
