import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Invoice } from "./models";
import { HighlightedRow } from "api/other/models";
import { TradingDocument } from "api/milo-settings/models";
import { queryFetch } from "apiConnectors/queryFetch";
import { FieldToUpdate } from "pages/tradingDocuments/shared/components/actionToolbar/handleBulkEditInvoices";

const api = tokenRefreshMiddleware(apiFetch);

export const patchInvoice = (data: Partial<Invoice>, id: number | string) =>
  api<any>({
    method: "PATCH",
    url: "/invoices/items/" + id,
    data,
  });

export const postInvoice = (data: Omit<Invoice, "id">) =>
  api<Invoice>({
    method: "POST",
    url: "/invoices/items",
    data,
  });

export const patchBulkInvoices = ({
  ids,
  fieldToUpdate,
  valueToUpdate,
}: {
  ids: Pick<HighlightedRow, "id">[];
  fieldToUpdate: FieldToUpdate;
  valueToUpdate: Partial<TradingDocument>;
}) => {
  const data = { ids, [fieldToUpdate]: valueToUpdate };
  return queryFetch<HighlightedRow>({
    method: "PATCH",
    url: "/finances/trading-documents/items/bulk-update",
    data,
  });
};
