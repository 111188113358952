import { LastLoadedOrder } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LinkToPage } from "components/utils/linkToPage";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { dateFns } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useLastLoadedOrdersColumns = () =>
  useCreateTableColumns<LastLoadedOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 100,
        typographyProps: {
          color: "blue300",
        },
      }),
      columnHelper.text(row => row.seller.name || null, {
        header: "producent",
        size: 110,
      }),
      columnHelper.text(row => (row.ramp ? row.ramp.name : null), {
        header: "rampa",
        size: 55,
        typographyProps: {
          color: "success500",
        },
      }),
      columnHelper.accessor(row => row.loadedAt, {
        id: "loadedAt",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wydano
          </Typography>
        ),
        size: 100,
        cell: info => {
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue()
                ? dateFns.formatRelative(new Date(info.getValue()), "dd.MM.yyyy, H:mm")
                : "---"}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row.loading, {
        header: "transport",
        size: 100,
        cell: info => {
          const loading = info.getValue();
          return (
            <LinkToPage
              content={loading.signature}
              url={`/wms/loadings/list/all?panelId=${loading.id}`}
            />
          );
        },
      }),
    ];
  });
