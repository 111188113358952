import { CarrierOrder, CarrierOrderPaymentMethod } from "api/logistics/carrierOrders/models";
import { dateFns, queryString } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DOMAIN } from "ENDPOINTS";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";

export const useCarrierOrdersListColumns = () => {
  return useCreateTableColumns<CarrierOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.route.signature, {
        header: "trasa",
        size: 120,
      }),
      columnHelper.text(row => row.carrier.companyName, {
        header: "przewoźnik",
        size: 200,
      }),
      columnHelper.text(row => dateFns.format(new Date(row.createdAt), "dd.MM.yyyy, HH:mm"), {
        header: "data zlecenia",
        size: 130,
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "zlecono przez",
        size: 150,
        cell: info => {
          if (!info.getValue()) return "---";
          return (
            <>
              <Avatar size="sm" user={info.getValue()} theme="light" />
              <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                {info.getValue().firstName} {info.getValue().lastName}
              </Typography>
            </>
          );
        },
      }),
      columnHelper.accessor(row => row.route, {
        header: "PDF",
        size: 50,
        cell: info => {
          return (
            <div>
              <IconButton
                icon={<MdiViewPdf size="22" />}
                onClick={event => {
                  event.stopPropagation();
                  window.open(
                    `${DOMAIN}carrier-orders/pdf${queryString.stringify({
                      routesIds: info.getValue().id,
                    })}`,
                    "_blank",
                  );
                }}
                variant="blackST"
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.car.registrationNumber, {
        header: "nr rejestracyjny",
        size: 100,
      }),
      columnHelper.text(
        row => {
          const drivers = row.drivers;
          if (!drivers.length) return null;
          return drivers.map(driver => `${driver.firstName} ${driver.lastName}`).join(",");
        },
        {
          header: "kierowcy",
          size: 150,
        },
      ),
      columnHelper.text(row => "---", {
        header: "data załadunku",
        size: 100,
      }),
      columnHelper.text(row => "---", {
        header: "data wyjazdu",
        size: 100,
      }),
      columnHelper.accessor(row => row.paymentMethod, {
        header: "sposób zapłaty",
        size: 100,
        cell: info => {
          if (info.getValue() === "") return "---";
          const data = paymentMethodDict[info.getValue() as CarrierOrderPaymentMethod];
          return <Tag label={data.label} variant={data.variant} />;
        },
      }),
    ];
  });
};

export const paymentMethodDict: Record<
  CarrierOrderPaymentMethod | "",
  { label: string; variant: "warning" | "info" }
> = {
  [CarrierOrderPaymentMethod.CASH]: { label: "gotówka", variant: "warning" },
  [CarrierOrderPaymentMethod.ONLINE]: { label: "przelew", variant: "info" },
  "": { label: "---", variant: "info" },
};
