import { CallCenterRouteListItem } from "api/call-center/routes/models";
import cx from "classnames";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import styles from "../../../shared/CallCenter.module.css";
import faceIcon from "assets/images/face.svg";
import { useSelector } from "hooks";
import { useRouteListItemPatchMutation } from "api/call-center/routes/hooks";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";

interface Props {
  route: CallCenterRouteListItem;
}

export const ResponsibleForRoute = ({ route }: Props) => {
  const assignResponsibleForRouteMutation = useRouteListItemPatchMutation();
  const me = useSelector(store => store.auth.user!);

  return (
    <div>
      {route.responsible ? (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between gap-1">
            {route.responsible.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={route.responsible.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarMedium)}
                firstName={route.responsible.firstName}
                lastName={route.responsible.lastName}
                style={{ margin: 0, background: "#00cf91" }}
              />
            )}
            <div className={cx(styles.overflow, "fw-700")}>
              {route.responsible.firstName} {route.responsible.lastName}
            </div>
          </div>

          <IconButton
            className={styles.deleteIcon}
            icon={MdiClose}
            variant="white"
            onMouseDown={e => {
              e.stopPropagation();
              assignResponsibleForRouteMutation.mutate({
                id: route.id,
                toUpdate: {
                  responsible: null,
                },
              });
            }}
          />
        </div>
      ) : (
        <Tooltip title="Przydziel do mnie">
          <button
            className={cx(styles.avatar, styles.avatarMedium, styles.avatarPlaceholder)}
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              assignResponsibleForRouteMutation.mutate({
                id: route.id,
                toUpdate: {
                  responsible: me,
                },
              });
            }}
          >
            <img alt="Przypisz" src={faceIcon} style={{ height: "18px", width: "18px" }} />
          </button>
        </Tooltip>
      )}
    </div>
  );
};
