import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { useState } from "react";
import { cx, getEditableDateFormat } from "utilities";
import styles from "../../shared/ExternalManufacturerPanel.module.css";
import { HighlightedRow } from "api/other/models";
import { ExecutionStatus } from "api/external-manufacturing/models";
import { MdiSchedule } from "components/miloDesignSystem/atoms/icons/MdiSchedule";
import { MdiTaskAlt } from "components/miloDesignSystem/atoms/icons/MdiTaskAlt";
import { MdiFactory } from "components/miloDesignSystem/atoms/icons/MdiFactory";
import { MdiPrecisionManufacturing } from "components/miloDesignSystem/atoms/icons/MdiPrecisionManufacturing";
import { MdiLocalShipping } from "components/miloDesignSystem/atoms/icons/MdiLocalShipping";

interface Props {
  highlightedRows: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
}

export const TableToolbarActions = ({ highlightedRows, setHighlightedRows }: Props) => {
  const bulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateLineItems();
  const pickupBySellerPlannedAtBulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateLineItems();
  const [pickupBySellerPlannedAt, setPickupBySellerPlannedAt] = useState("");

  return (
    <TableToolbar
      close={() => {
        setHighlightedRows([]);
      }}
      numberOfSelectedItems={highlightedRows.length}
    >
      <Tooltip title="Potwierdź datę odbioru">
        <IconButton
          icon={MdiCheck}
          variant="whiteT"
          onClick={() => {
            bulkUpdateMutation.mutate({
              ids: highlightedRows.map(e => String(e.id)),
              pickedUpBySeller: true,
              pickedUpAt: getEditableDateFormat(new Date()),
            });
          }}
        />
      </Tooltip>
      <Popover
        hideOnClickOutside={false}
        content={({ hide }) => (
          <ClickOutsideHandler
            onClickOutside={() => {
              hide();
              setPickupBySellerPlannedAt("");
            }}
            outsideClickIgnoreClass={ignoreClickOutsideClassName}
          >
            <div>
              <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                Wskaż nową datę odbioru
              </Typography>
              <div className="d-flex">
                <div className={cx(styles.datePicker, "position-relative mr-1")}>
                  <DatePicker
                    calendarClassName={ignoreClickOutsideClassName}
                    overwrites={{
                      container: { className: ignoreClickOutsideClassName },
                      popup: {
                        className: cx(ignoreClickOutsideClassName, styles.datePicker),
                      },
                    }}
                    placeholder="Wybierz"
                    className={styles.date}
                    value={pickupBySellerPlannedAt}
                    selectsStart
                    removeDate={false}
                    tabIndex={-1}
                    onChange={date => {
                      if (!date) return;
                      setPickupBySellerPlannedAt(getEditableDateFormat(date));
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-1 justify-content-end mt-3">
                <Button
                  className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                  size="small"
                  variant="deepPurple"
                  onClick={() => {
                    pickupBySellerPlannedAtBulkUpdateMutation.mutate(
                      {
                        ids: highlightedRows.map(e => String(e.id)),
                        pickupBySellerPlannedAt,
                      },
                      {
                        onSuccess: hide,
                      },
                    );
                  }}
                  isLoading={pickupBySellerPlannedAtBulkUpdateMutation.isLoading}
                >
                  Zmień datę odbioru
                </Button>
              </div>
            </div>
          </ClickOutsideHandler>
        )}
        variant="DARK"
      >
        <div>
          <Tooltip title="Zmień datę odbioru">
            <IconButton icon={MdiEvent} variant="whiteT" />
          </Tooltip>
        </div>
      </Popover>
      <Tooltip title="Ustaw priorytet A">
        <IconButton
          onClick={() => {
            bulkUpdateMutation.mutate({
              ids: highlightedRows.map(e => String(e.id)),
              priority: 2,
            });
          }}
          icon={
            <Typography fontSize="12" fontWeight="600" color={priorityDict[2].color}>
              {priorityDict[2].label}
            </Typography>
          }
          variant="whiteT"
        />
      </Tooltip>
      <Tooltip title="Ustaw priorytet B">
        <IconButton
          onClick={() => {
            bulkUpdateMutation.mutate({
              ids: highlightedRows.map(e => String(e.id)),
              priority: 1,
            });
          }}
          icon={
            <Typography fontSize="12" fontWeight="600" color={priorityDict[1].color}>
              {priorityDict[1].label}
            </Typography>
          }
          variant="whiteT"
        />
      </Tooltip>
      <Tooltip title="Ustaw priorytet C">
        <IconButton
          onClick={() => {
            bulkUpdateMutation.mutate({
              ids: highlightedRows.map(e => String(e.id)),
              priority: 0,
            });
          }}
          icon={
            <Typography fontSize="12" fontWeight="600" color={priorityDict[0].color}>
              {priorityDict[0].label}
            </Typography>
          }
          variant="whiteT"
        />
      </Tooltip>
      <Popover
        hideOnClickOutside={false}
        content={({ hide }) => (
          <ClickOutsideHandler
            onClickOutside={hide}
            outsideClickIgnoreClass={ignoreClickOutsideClassName}
          >
            <div className={styles.executionStatusContainer}>
              <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                Wskaż status realizacji:
              </Typography>
              <div className="d-flex align-items-center flex-column gap-2">
                <Button
                  className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                  onClick={() => {
                    bulkUpdateMutation.mutate(
                      {
                        ids: highlightedRows.map(e => String(e.id)),
                        executionStatus: ExecutionStatus.NOT_STARTED,
                      },
                      {
                        onSuccess: hide,
                      },
                    );
                  }}
                  size="small"
                  startIcon={MdiSchedule}
                  variant="gray"
                >
                  Nierozpoczęto
                </Button>
                <Button
                  className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                  onClick={() => {
                    bulkUpdateMutation.mutate(
                      {
                        ids: highlightedRows.map(e => String(e.id)),
                        executionStatus: ExecutionStatus.IN_PROGRESS,
                      },
                      {
                        onSuccess: hide,
                      },
                    );
                  }}
                  size="small"
                  startIcon={MdiFactory}
                  variant="gray"
                >
                  W produkcji
                </Button>
                <Button
                  className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                  onClick={() => {
                    bulkUpdateMutation.mutate(
                      {
                        ids: highlightedRows.map(e => String(e.id)),
                        executionStatus: ExecutionStatus.READY_TO_DELIVER,
                      },
                      {
                        onSuccess: hide,
                      },
                    );
                  }}
                  size="small"
                  startIcon={MdiTaskAlt}
                  variant="gray"
                >
                  Gotowe do odbioru
                </Button>
              </div>
            </div>
          </ClickOutsideHandler>
        )}
        variant="DARK"
      >
        <div>
          <Tooltip title="Zmień status realizacji">
            <IconButton icon={MdiPrecisionManufacturing} variant="whiteT" />
          </Tooltip>
        </div>
      </Popover>
      <Tooltip title="Potwierdź odbiór przez zamawiającego">
        <IconButton
          icon={MdiLocalShipping}
          variant="whiteT"
          onClick={() => {
            bulkUpdateMutation.mutate({
              ids: highlightedRows.map(e => String(e.id)),
              pickedUpBySeller: true,
            });
          }}
        />
      </Tooltip>
    </TableToolbar>
  );
};

const ignoreClickOutsideClassName = "manufacturer-line-items-popover-ignore-class-name";
