import { LoadingGroupPackage, LoadingPackageStatus } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { loadingPackageStatusDict } from "../lineItemsDetailsModal/LineItemsDetailsModal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";

export const useLoadingWorkspaceColumns = () =>
  useCreateTableColumns<LoadingGroupPackage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => (Boolean(row.name.length) ? row.name : "---"), {
        header: "nazwa paczki",
        size: 570,
      }),
      columnHelper.text(row => row.location, {
        header: "lokalizacja",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
          color: "success500",
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 120,
        cell: info => {
          const status: LoadingPackageStatus = info.getValue();
          return (
            <Tag
              label={loadingPackageStatusDict[status].label}
              variant={loadingPackageStatusDict[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          const pickedUpAt = info.getValue().pickedUpAt;
          const loadedAt = info.getValue().loadedAt;
          const status = info.getValue().status;
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {(status === LoadingPackageStatus.DURING_LOADING ||
                status === LoadingPackageStatus.PICKED_UP) &&
              pickedUpAt
                ? dateFns.formatRelative(new Date(pickedUpAt), "dd.MM.yyyy, H:mm")
                : status === LoadingPackageStatus.LOADED && loadedAt
                ? dateFns.formatRelative(new Date(loadedAt), "dd.MM.yyyy, H:mm")
                : "---"}
            </Typography>
          );
        },
      }),
    ];
  });
