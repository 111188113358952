import styles from "./Radio.module.css";
import { ReactNode } from "react";
import cx from "classnames";
import { Assign } from "utility-types";

interface Item {
  id: number | string;
  name: ReactNode;
}

interface Props<T extends Item, U extends string> {
  items: readonly T[];
  onChange: (item: T | null, name: U | "") => void;
  disabled?: boolean;
  value: T["id"] | null;
  allowUncheck?: boolean;
  itemToDisplay?: (item: T) => ReactNode;
  className?: string;
  name?: U;
  label?: string;
}

const noopItemToDisplay = (item: Item) => item.name;

export function RadioGroup<T extends Item, U extends string>({
  items,
  onChange,
  disabled,
  value,
  allowUncheck,
  itemToDisplay = noopItemToDisplay,
  className,
  name,
  label,
}: Props<T, U>) {
  return (
    <div>
      {label && <div className={cx(styles.mainLabel, "fieldset_label")}>{label}</div>}
      <div className={cx(className)}>
        {items.map(item => {
          const checked = value === item.id;
          return (
            <RadioItem
              key={item.id}
              checked={checked}
              item={item}
              onChange={onChange}
              disabled={disabled}
              allowUncheck={allowUncheck}
              itemToDisplay={itemToDisplay}
              name={name}
            />
          );
        })}
      </div>
    </div>
  );
}

function RadioItem<T extends Item, U extends string>({
  checked,
  item,
  disabled,
  allowUncheck,
  onChange,
  itemToDisplay = noopItemToDisplay,
  name,
  overrides = {},
}: Assign<
  Omit<Props<T, U>, "value" | "items">,
  {
    item: T;
    checked: boolean;
    overrides?: { radioMark?: { className?: string } };
  }
>) {
  return (
    <label
      className={cx(styles.option, styles.label, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        type="radio"
        value={item.id}
        checked={checked}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (checked && !allowUncheck) return;
          if (checked) {
            onChange(null, name || "");
          }
        }}
        onChange={() => onChange(item, name || "")}
        className={styles.input}
        name={name}
      />
      <div className="d-flex">
        <span className={cx(styles.radioMark, overrides.radioMark?.className)}></span>
        <div style={{ minHeight: 18 }}>{itemToDisplay(item)}</div>
      </div>
    </label>
  );
}

RadioGroup.Item = RadioItem;
