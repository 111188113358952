import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import cx from "classnames";

interface Props {
  convertionRate?: number;
  currency: string;
  fieldName: "amountWithoutTax" | "amountWithTax" | "taxAmountBeforeRounding";
  isMainSummary?: boolean;
  values: CreateCorrectionValues;
}

export const WholeAmountToPay = ({
  convertionRate,
  currency,
  fieldName,
  isMainSummary,
  values,
}: Props) => {
  const getAmount = () => {
    return values.positions
      .filter(
        position =>
          position[fieldName] !== null &&
          position[fieldName] !== undefined &&
          !position.alreadyInvoiced,
      )
      .reduce((acc, position) => acc + position[fieldName] * position.quantity, 0);
  };

  const getCurrencyConvertion = () => {
    return values.positions
      .filter(
        position =>
          position[fieldName] !== null &&
          position[fieldName] !== undefined &&
          !position.alreadyInvoiced,
      )
      .reduce(
        (acc, position) => acc + position[fieldName] * position.quantity * convertionRate!,
        0,
      );
  };

  if (
    values.positions
      .filter(position => !position.alreadyInvoiced)
      .some(
        position =>
          position[fieldName] === undefined ||
          position[fieldName] === null ||
          position.quantity < 0 ||
          position[fieldName] < 0,
      )
  ) {
    return (
      <div className="d-flex align-items-center justify-content-end text-color-coral">
        Błąd przeliczania
      </div>
    );
  }

  if (convertionRate) {
    return (
      <>
        <strong
          className={cx({
            "body-14 fw-800": isMainSummary === undefined,
            "body-18-800": isMainSummary,
          })}
        >
          <FinanceAmountFormat value={getCurrencyConvertion()} />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <FinanceAmountFormat value={getAmount()} />
      <div className="fs-12 text-color-grey">{currency}</div>
    </div>
  );
};
