import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { assignedUsersListConfig } from "../utils/panelTablesConfig";
import { AvatarWithName } from "components/common/avatarWithName";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ManufacturingStage } from "api/manufacturingNew/models";
import { useMutation } from "hooks/useMutation";
import { AsyncInput } from "components/utils";
import { AddEmployeeModal } from "./addEmployeeModal/AddEmployeeModal";
import face from "assets/images/face.svg";
import {
  deleteManufacturingStageEmployee,
  patchManufacturingStageEmployee,
} from "api/manufacturingNew/calls";
import { useConfirmModal, useToggle } from "hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import styles from "../ManufacturingStagesList.module.css";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiKeyboardArrowRight } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowRight";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const Employees = ({ manufacturingStage }: Props) => {
  const { handleMutate } = useManufacturingStage(manufacturingStage.id, {
    enabled: false,
  });
  const addEmployeeModal = useToggle();
  const confirmModal = useConfirmModal();

  const patchMutation = useMutation(patchManufacturingStageEmployee, {
    onMutate: toUpdate => {
      return handleMutate(draft => {
        let changedEmployee = draft.settings.employees.find(
          employee => employee.id === toUpdate.id,
        );
        if (changedEmployee) {
          changedEmployee = {
            ...changedEmployee,
            ...toUpdate.data,
          };
        }
      });
    },

    onError: (error, _, rollback) => {
      rollback?.(error);
    },
  });

  const deleteMutation = useMutation(deleteManufacturingStageEmployee, {
    onMutate: toUpdate => {
      return handleMutate(draft => {
        const employeesFiltered = draft.settings.employees.filter(
          employee => employee.id !== toUpdate.id,
        );
        draft.settings = { ...draft.settings, employees: employeesFiltered };
      });
    },
    onError: (error, _, rollback) => {
      rollback?.(error);
    },
  });

  return (
    <RightPanelSection title="Ustawienia dotyczące pracowników">
      {manufacturingStage.settings.employees.length > 0 && (
        <RightPanelTableRow
          className="tableHeaderGreyBg"
          hover={false}
          grid={assignedUsersListConfig.grid}
        >
          <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
            #
          </Typography>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
            osoba
          </Typography>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
            l. miejsc
          </Typography>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
            usuń
          </Typography>
        </RightPanelTableRow>
      )}
      {manufacturingStage.settings.employees.map((employee, index) => (
        <RightPanelTableRow key={employee.id} grid={assignedUsersListConfig.grid}>
          <Typography fontSize="12" fontWeight="700">
            {index + 1}.
          </Typography>
          <AvatarWithName className="fs-12 fw-700" user={employee} />
          <div className="d-flex align-items-center gap-2 w-100">
            <AsyncInput
              value={employee.number}
              name="number"
              type="number"
              className="inputSmall"
              disabled={patchMutation.isLoading}
              overwrites={{
                container: { className: styles.employeeNumberInput },
              }}
              onChange={value => {
                patchMutation.mutate({
                  data: { number: value },
                  id: employee.id,
                });
              }}
            />
            <Typography color="neutralBlack48" fontSize="12" fontWeight="500">
              /dzień
            </Typography>
          </div>
          <div className="d-flex align-items-start">
            <Tooltip title="Usuń pracownika z etapu">
              <IconButton
                icon={MdiDelete}
                onClick={() => {
                  confirmModal.open({
                    text: `Czy na pewno chcesz usunąć pracownika "${employee.firstName} ${employee.lastName}"?`,
                    confirmText: "Usuń",
                    callback: () => deleteMutation.mutate({ id: employee.id }),
                  });
                }}
                variant="blackT"
              />
            </Tooltip>
          </div>
        </RightPanelTableRow>
      ))}
      <div className="mt-3">
        {manufacturingStage.settings.employees.length > 0 ? (
          <Button startIcon={MdiAdd} onClick={addEmployeeModal.open} variant="gray" size="small">
            Dodaj kolejną osobę
          </Button>
        ) : (
          <div className="pb-3">
            <div className={styles.sectionEmpty}>
              <img
                alt=""
                className={styles.icon}
                src={face}
                style={{ height: "24px", width: "24px" }}
              />
              <Typography className={styles.sectionEmptyTitle} fontSize="12" fontWeight="700">
                Jeszcze nie przypisano żadnej osoby do etapu
              </Typography>

              <div className="py-2 d-flex align-items-center gap-2">
                <Button
                  endIcon={MdiKeyboardArrowRight}
                  onClick={addEmployeeModal.open}
                  size="small"
                  startIcon={MdiAdd}
                  variant="gray"
                >
                  Dodaj teraz
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {addEmployeeModal.isOpen && (
        <AddEmployeeModal close={addEmployeeModal.close} manufacturingStage={manufacturingStage} />
      )}
    </RightPanelSection>
  );
};
