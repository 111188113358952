import ambro from "assets/images/shippingService/ambro.svg";
import mebelTaxi from "assets/images/shippingService/mebelTaxi.svg";

export enum ShippingService {
  AMBRO = "AMBRO",
  MEBEL_TAXI = "MEBEL_TAXI",
}
export const shippingServiceConstants: Record<
  ShippingService,
  { logo: string; externalStatus: string[]; name: string }
> = {
  AMBRO: {
    logo: ambro,
    name: "Ambro",
    externalStatus: [
      "Anulowano",
      "Anulowano zwrot",
      "Odbiorca odmówił przyjęcia",
      "Awizo",
      "Awizo II",
      "Zwrot dokumentów",
      "Błędne dane adresowe",
      "Brak przesyłki",
      "Brak czasu",
      "Błędny rejon doręczenia",
      "Brak Gotówki",
      "Błędny sort",
      "Doręczono",
      "Doręczono przesyłkę wewnętrzną",
      "Do wyjaśnienia",
      "Wydrukowano DWP",
      "Etykieta Przeterminowana",
      "IDWO",
      "Inny status",
      "Interwencja",
      "Inwentaryzacja stanu magazynowego",
      "Likwidacja przesyłki",
      "Mylny Kierunek",
      "Kontakt telefoniczny nieudany",
      "Nadana w POK",
      "Odbiór własny",
      "Odebrana z POK",
      "Przeadresowanie",
      "Przekazano do doręczenia",
      "Powrót do oddziału",
      "Przyjęta od Klienta przez Kuriera",
      "Przyjęta od kuriera przez magazyn",
      "Przekierowanie na adres",
      "Przekierowanie na PM/POK",
      "Posortowana",
      "Przygotowana przez nadawcę",
      "Przyjęta w oddziale",
      "Przyjęta w POK",
      "Przyjęta w sortowni",
      "Przesyłka uszkodzona",
      "Umówiony termin doręczenia",
      "Weryfikacja wagi przesyłki",
      "Wysłana z oddziału",
      "Wysłana z sortowni",
      "Zaginiona",
      "Zlecenie oczekujące do realizacji",
      "Zwrot do magazynu przesyłek Niedoręczalnych",
      "Zwrot do nadawcy",
      "Zlecenie odrzucone przez kuriera",
      "Błędna trasa",
      "Zlecenie Przyjęte od Klienta",
      "Zlecenie powielone",
      "Przesyłka nieprzygotowana",
      "Zlecenie przeniesione",
      "Wydanie zlecenia na kuriera",
      "Zwrot",
      "Zwrot z POK",
      "Zlecenie Zrealizowane",
    ],
  },
  MEBEL_TAXI: {
    logo: mebelTaxi,
    name: "Mebel taxi",
    externalStatus: [
      "Przesyłka zarejestrowana w systemie",
      "Przesyłka na magazynie",
      "Przesyłka wysłana",
      "Przesyłka odebrana",
      "Przesyłka anulowana",
    ],
  },
};
