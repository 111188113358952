import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import warehouseIcon from "assets/images/warehouseAlt2.svg";
import { Unloadings } from "routes/wms/Unloadings";

export const warehouse: ModuleLink = {
  url: "warehouse",
  label: "Magazyn",
  icon: warehouseIcon,
  subSection: <></>,
  routing: Unloadings,
};
