import {
  invoicePaymentStatusDict,
  invoiceStatusDict,
  TradingDocument,
} from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import cx from "classnames";
import { purchaseInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { dateFns, pluralize } from "utilities";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { dateExceedsToday } from "pages/tradingDocuments/shared/utils/dateExceedsToday";
import { PaymentDeadlineDeltaLabel } from "pages/tradingDocuments/shared/components/PaymentDeadlineDeltaLabel";
import { getStatusLabelKind } from "pages/tradingDocuments/shared/utils/getStatusLabelKind";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import matchGrayIcon from "assets/images/matchGray.svg";
import matchPurpleIcon from "assets/images/matchPurple.svg";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  togglePanel: (id: string) => void;
  purchaseInvoice: TradingDocument;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  togglePanel,
  purchaseInvoice,
  selectMutlipleItems,
}: Props) => {
  return (
    <ModuleRow
      className={cx({
        active: isActive(purchaseInvoice.id),
        highlighted:
          isHighlighted(purchaseInvoice.id) ||
          highlightedRows.some(row => row.id === purchaseInvoice.id),
      })}
      gridStyle={purchaseInvoiceListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, purchaseInvoice.id, index, () =>
          togglePanel(String(purchaseInvoice.id)),
        )
      }
    >
      <div>
        <ConfirmCheckbox tradingDocument={purchaseInvoice} />
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={cx(styles.client, "fw-700")}>
          {purchaseInvoice.sellerCompanyName ? (
            purchaseInvoice.sellerCompanyName
          ) : (
            <>
              {purchaseInvoice.sellerFirstName} {purchaseInvoice.sellerLastName}
            </>
          )}
        </div>
        {!isDateOlderThan48Hours(purchaseInvoice.createdAt) && (
          <StateLabel
            kind="green"
            opacity={
              purchaseInvoice.createdAt && isDateOlderThan24Hours(purchaseInvoice.createdAt)
                ? "high"
                : "default"
            }
          >
            <div>{invoiceStatusDict.NEW}</div>
          </StateLabel>
        )}
      </div>
      <div className={styles.signature}>{purchaseInvoice.signature}</div>
      <div>
        {purchaseInvoice.isGrnConfirmed ? (
          <img alt="" className="imgM" src={matchPurpleIcon} />
        ) : (
          <img alt="" className="imgM" src={matchGrayIcon} />
        )}
      </div>
      <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
        <FinanceAmountFormat value={purchaseInvoice.amountSummary.totalWithTax} />
        <div className="fs-10 text-color-grey">{purchaseInvoice.currency}</div>
      </div>
      <div className={styles.date}>
        {purchaseInvoice.invoiceIssueDate
          ? dateFns.format(new Date(purchaseInvoice.invoiceIssueDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div className={cx(styles.date, "d-flex align-items-center")}>
        {purchaseInvoice.paymentDeadline ? (
          <div
            className={cx({
              "text-red-6": dateExceedsToday(purchaseInvoice.paymentDeadline),
            })}
          >
            {dateFns.format(new Date(purchaseInvoice.paymentDeadline), "dd.MM.yyyy")}
          </div>
        ) : (
          "---"
        )}
        {purchaseInvoice.basePaymentDeadlineDelta && (
          <div>
            &nbsp;&nbsp;({purchaseInvoice.basePaymentDeadlineDelta}{" "}
            {pluralize.pl(purchaseInvoice.basePaymentDeadlineDelta, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}
            )
          </div>
        )}
      </div>
      <div>
        <StateLabel
          kind={getStatusLabelKind(purchaseInvoice.paymentStatus, purchaseInvoice.paymentDeadline)}
        >
          {purchaseInvoice.paymentStatus === "PAID" ? (
            <>{invoicePaymentStatusDict[purchaseInvoice.paymentStatus]}</>
          ) : (
            "Nieopłacona"
          )}
        </StateLabel>
      </div>
      <div className="fw-700">
        {purchaseInvoice.paymentDeadlineDelta !== null &&
          purchaseInvoice.paymentStatus !== "PAID" && (
            <PaymentDeadlineDeltaLabel
              paymentDeadlineDelta={purchaseInvoice.paymentDeadlineDelta}
            />
          )}
      </div>
      <div>
        {purchaseInvoice.assignedTo && (
          <div className="d-flex align-items-center gap-1">
            {purchaseInvoice.assignedTo.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={purchaseInvoice.assignedTo.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarSmall)}
                firstName={purchaseInvoice.assignedTo.firstName}
                lastName={purchaseInvoice.assignedTo.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {purchaseInvoice.assignedTo.firstName} {purchaseInvoice.assignedTo.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.subject}>
        {purchaseInvoice.recipientCompanyName ? (
          purchaseInvoice.recipientCompanyName
        ) : (
          <>
            {purchaseInvoice.recipientFirstName} {purchaseInvoice.recipientLastName}
          </>
        )}
      </div>
    </ModuleRow>
  );
};
