import { FinancesAmountSummary } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ToggleHookState } from "hooks";
import styles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  isLoading: boolean;
  uploadPurchaseInvoicePdf: ToggleHookState;
}

export const BottomBarOptions = ({ amountSummary, isLoading, uploadPurchaseInvoicePdf }: Props) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Typography
            className={styles.labelText}
            color="neutralBlack48"
            fontSize="12"
            fontWeight="700"
          >
            netto:
          </Typography>
          <Typography fontSize="16" fontWeight="700">
            {amountSummary
              ? Number(amountSummary.totalAmountWithoutTax.toFixed(2)).toLocaleString("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : isLoading
              ? "..."
              : "brak danych"}{" "}
            PLN
          </Typography>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Typography
            className={styles.labelText}
            color="neutralBlack48"
            fontSize="12"
            fontWeight="700"
          >
            VAT:
          </Typography>
          <Typography fontSize="16" fontWeight="700">
            {amountSummary
              ? Number(amountSummary.totalTax.toFixed(2)).toLocaleString("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : isLoading
              ? "..."
              : "brak danych"}{" "}
            PLN
          </Typography>
        </div>
        <span className="line-divider lineDividerMargin" />
        <Button
          onClick={uploadPurchaseInvoicePdf.open}
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Importuj fakturę
        </Button>
      </div>
    </div>
  );
};
