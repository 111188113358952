import { deleteLoading } from "api/wms/calls";
import { useLoadingPatchMutation } from "api/wms/hooks";
import { wmsKeys } from "api/wms/keys";
import { LoadingDetails, LoadingStatus } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { dateFns, getAnyErrorKey } from "utilities";
import { useDownloadLoadingPdf } from "../../hooks/useDownloadLoadingPdf";

interface Props {
  close: () => void;
  loading: LoadingDetails;
}

export const PanelHeader = ({ close, loading }: Props) => {
  const startLoadingMutation = useLoadingPatchMutation();
  const finishLoadingMutation = useLoadingPatchMutation();
  const handleDownloadLoadingPdf = useDownloadLoadingPdf();

  const deleteMutation = withDeleteConfirmation(
    useMutation(deleteLoading, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsKeys.loading.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto załadunek "${loading.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten załadunek?",
  )();

  return (
    <RightPanelHeader>
      {!loading.startedAt && !loading.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={startLoadingMutation.isLoading}
          onClick={() =>
            startLoadingMutation.mutate({
              id: loading.id,
              toUpdate: {
                startedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                status: LoadingStatus.IN_PROGRESS,
              },
            })
          }
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Rozpocznij załadunek
        </Button>
      )}
      {loading.startedAt && !loading.finishedAt && (
        <Button
          className="text-uppercase"
          disabled={finishLoadingMutation.isLoading}
          onClick={() =>
            finishLoadingMutation.mutate({
              id: loading.id,
              toUpdate: {
                finishedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                status: LoadingStatus.FINISHED,
              },
            })
          }
          size="small"
          startIcon={MdiCheck}
          theme="light"
          variant="outline"
        >
          Zakończ załadunek
        </Button>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <IconButton
          icon={<MdiQrCode size="16" />}
          onClick={() => handleDownloadLoadingPdf(loading)}
          variant="blackT"
        />
        <IconButton icon={<MdiDownloadPdf size="16" />} variant="blackT" />
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="blackT" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                disabled: loading.status !== LoadingStatus.NOT_STARTED,
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(loading.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="blackT" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
