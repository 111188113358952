import { PageHeader } from "components/common";
import { useToggle } from "hooks";
import { DeliveredSalesReportModal } from "./deliveredSalesReportModal/DeliveredSalesReportModal";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import { MdiAreaChart } from "components/miloDesignSystem/atoms/icons/MdiAreaChart";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const Header = () => {
  const deliveredSalesReportModal = useToggle();

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Tooltip title="Pobierz raport dla biura rachunkowego">
              <IconButton
                icon={MdiDownloadFile}
                onClick={deliveredSalesReportModal.open}
                variant="blackT"
              />
            </Tooltip>
            <IconButton icon={MdiUploadFile} variant="blackT" />
            <IconButton icon={MdiVisibilityOff} variant="blackT" />
            <IconButton icon={MdiAreaChart} variant="blackT" />
          </div>
        }
        bigTitle="Księgowość"
        searchBar={false}
        viewLabel="INVOICE_DASHBOARD"
      />
      {deliveredSalesReportModal.isOpen && (
        <DeliveredSalesReportModal close={deliveredSalesReportModal.close} />
      )}
    </div>
  );
};
