import styles from "./PageHeader.module.css";
import cx from "classnames";
import { Link, useParams } from "react-router-dom";
import { getAnyErrorKey, queryString } from "utilities";
import { usePrevious, useQuery } from "hooks";
import { Button } from "../buttonLegacy";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { HeaderUserDropdown } from "../headerUserDropdown/HeaderUserDropdown";
import { ModuleMenuDropdown } from "./components/ModuleMenuDropdown";
import { SearchWithHistory } from "components/utils/searchWithHistory/SearchWithHistory";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Assign } from "utility-types";
import { useMutation } from "hooks/useMutation";
import { postSearchHistory } from "api/other/calls";

export type FilterType = "signature" | "client" | "search" | "productName" | "multipleNameFinder";

export type ViewLabel =
  | "EXTERNAL_MANUFACTURER_ITEMS"
  | "EXTERNAL_MANUFACTURER_LINE_ITEMS"
  | "FINANCES_OVERVIEW_SOLD_PACKAGES"
  | "FINANCES_OVERVIEW_SOLD_INDEXES"
  | "SHIPPING_SHIPMENTS"
  | "SHIPPING_COURIERS"
  | "LOGISTICS_ROUTE"
  | "LOGISTICS_ROUTE_HISTORY"
  | "LOGISTICS_DASHBOARD"
  | "LOGISTICS_ROUTES"
  | "LOGISTICS_CARRIERS"
  | "LOGISTICS_CARRIER_ORDERS"
  | "LOGISTICS_ROUTE_LINE_ITEMS"
  | "CALL_CENTER_ROUTE"
  | "CALL_CENTER_ROUTES"
  | "CALL_CENTER_ORDERS"
  | "SALES_INVOICES"
  | "SALES_INVOICE_HISTORY"
  | "RECEIPTS"
  | "PURCHASE_INVOICES"
  | "PURCHASE_INVOICE_HISTORY"
  | "CONNECT_INVOICE"
  | "CORRECTION_INVOICES"
  | "CORRECTION_INVOICE_HISTORY"
  | "IMPORT_PURCHASE_INVOICES"
  | "IMPORT_PURCHASE_INVOICE_HISTORY"
  | "MY_IMPORT_PURCHASE_INVOICES_LIST"
  | "WAREHOUSE_FINANCIAL_STATE"
  | "INVOICE_DASHBOARD"
  | "INVENTORY_DASHBOARD"
  | "INVENTORY_PRODUCTS"
  | "REPORTS_DASHBOARD"
  | "HR_DASHBOARD"
  | "REPORTS_DASHBOARD"
  | "MANUFACTURING_DASHBOARD"
  | "WAREHOUSEMAN"
  | "WMS_DASHBOARD"
  | "WAREHOUSE_OVERVIEW"
  | "WMS_UNLOADINGS"
  | "WMS_PICKINGS"
  | "WMS_LOADINGS"
  | "WMS_RAMPS"
  | "REPORTS_DASHBOARD"
  | "MANUFACTURING_PLANS"
  | "EXTERNAL_MANUFACTURING_ORDERS"
  | "MANUFACTURING_SCHEMAS"
  | "MANUFACTURING_STAGES"
  | "MANUFACTURING_EMPLOYEES";

type NewQueryModel = Assign<{ [K in FilterType]: string | number }, { page: number }>;

interface Props {
  actionButtons?: React.ReactNode;
  additionalListParams?: {
    [x: string]: string;
  };
  bigTitle?: string;
  bottomBarButtons?: React.ReactNode;
  bottomBarTitle?: React.ReactNode;
  createButton?: {
    alt?: string;
    disabled?: boolean;
    img?: string;
    label: string;
    onClick: () => void;
  };
  hideModulesNavigation?: boolean;
  searchBar?: boolean;
  searchInput?: {
    label?: string;
    variants?: FilterType[];
    tags?: {
      name: string;
      label: string;
      value: string | number | boolean | null;
      valueLabel?: string;
    }[];
  };
  subtitle?: string;
  tabs?: {
    list: { label: string; name: string; path?: string; exactPath?: boolean }[];
    routesRoot: string;
    urlSpan: string;
  };
  title?: string | React.ReactNode;
  viewLabel: ViewLabel;
}

export const PageHeader = ({
  actionButtons,
  additionalListParams,
  bigTitle,
  bottomBarButtons,
  bottomBarTitle,
  createButton,
  searchBar = true,
  hideModulesNavigation = false,
  searchInput,
  subtitle,
  tabs,
  title,
  viewLabel,
}: Props) => {
  const params = useParams<{ tab: string; navbarTab: string }>();
  const { query, setQuery } = useQuery();

  function getInitialState() {
    const initialQueryParameterFromUrl = Object.entries(query).find(
      ([key, val]: [any, string]) => searchInput?.variants?.includes(key) && val,
    );
    const initialState =
      initialQueryParameterFromUrl?.[0] ??
      (searchInput?.variants && searchInput?.variants[0]) ??
      "search";
    return initialState as FilterType;
  }

  const [filterType] = useState<FilterType>(getInitialState);

  const initialMount = useRef(true);
  const previousFilterType = usePrevious(filterType);
  const search = query[filterType] || query[previousFilterType] || "";
  const queryRef = useRef(query);
  const filterTypeRef = useRef(filterType);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  useEffect(() => {
    filterTypeRef.current = filterType;
  }, [filterType]);

  useEffect(() => {
    if (initialMount.current) return;
    const newQuery: NewQueryModel = {
      ...queryRef.current,
      multipleNameFinder: "",
      signature: "",
      client: "",
      search: "",
      productName: "",
      page: 1,
    };
    if (previousFilterType) {
      newQuery[filterType] = queryRef.current[previousFilterType];
    }
    setQuery(newQuery);
  }, [filterType, setQuery, previousFilterType]);

  const updateSearch = useCallback(
    (searchValue: string) => {
      const newQuery: NewQueryModel = {
        ...queryRef.current,
        multipleNameFinder: "",
        signature: "",
        client: "",
        search: "",
        productName: "",
        [filterTypeRef.current]: searchValue,
        page: 1,
      };
      setQuery(newQuery);
    },
    [setQuery],
  );

  const saveSearchHistoryMutation = useMutation(
    () => {
      const queryToSend = { ...query };
      delete queryToSend["page"];
      delete queryToSend["search"];
      const postData = {
        kind: viewLabel,
        tab: params.tab ? params.tab.toUpperCase() : "",
        phrase: search,
        ...queryToSend,
        ...additionalListParams,
      };
      return postSearchHistory(postData);
    },
    ({ toastr }) => ({
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  useEffect(() => {
    initialMount.current = false;
  }, []);

  const getPath = (tab: NonNullable<any>[number]) => {
    const search = queryString.stringify({ ...query, page: "" });
    if (tab.exactPath) {
      return String(tab.path);
    }
    if (tab.path) {
      return `${tab.path}/${tab.name}${search}`;
    }
    return `/${tabs?.routesRoot}/${tabs?.urlSpan}/${tab.name}${
      params.navbarTab ? "/" + params.navbarTab : ""
    }${search}`;
  };

  const handleTitle = (): JSX.Element => {
    if (typeof title === "string") {
      return <h2 className={cx(styles.pageHeaderTitle, "mb-1")}>{title}</h2>;
    } else if (React.isValidElement(title)) {
      return (
        <h2 className={cx(styles.pageHeaderTitle, styles.pageHeaderAdditionalMarginBottom)}>
          {title}
        </h2>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.pageHeader}>
      <div className={cx(styles.topBar, "gap-1")}>
        <div className={styles.tabContainer}>
          {tabs ? (
            tabs.list.map(tab => (
              <Link
                className={cx(styles.tab, {
                  [styles.tabActive]: params.tab === tab.name,
                })}
                key={tab.name}
                to={getPath(tab)}
              >
                {tab.label}
              </Link>
            ))
          ) : bigTitle ? (
            <div className={styles.bigTitle}>{bigTitle}</div>
          ) : (
            handleTitle()
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end gap-1 pt-1 pb-1">
          {createButton && (
            <Button
              className={styles.createBtnOverride}
              disabled={createButton.disabled}
              kind="create"
              onClick={createButton.onClick}
            >
              <div className="btnBase btnBaseSmall">
                {createButton.img ? (
                  <img
                    alt={createButton.alt ?? "Działanie"}
                    src={createButton.img}
                    style={{ height: "16px", width: "16px" }}
                  />
                ) : (
                  <img alt="Dodaj" src={darkPlusIcon} />
                )}
                {createButton.label}
              </div>
            </Button>
          )}
          {actionButtons}
          {!hideModulesNavigation && <ModuleMenuDropdown />}
        </div>

        <span className={cx("line-divider", styles.dividerMargin)} />

        <HeaderUserDropdown />
      </div>
      {(searchBar || bottomBarButtons || subtitle || bottomBarTitle) && (
        <div
          className={cx(styles.bottomBar, {
            [styles.bottomBarFixedHeight]: subtitle && !searchBar,
          })}
        >
          {searchBar && (
            <SearchWithHistory
              additionalListParams={additionalListParams}
              onUpdate={updateSearch}
              saveSearchHistoryMutation={saveSearchHistoryMutation}
              searchInput={searchInput}
              value={search}
              viewLabel={viewLabel}
            />
          )}
          {bottomBarTitle && !searchBar && subtitle === undefined && bottomBarTitle}
          {subtitle && !searchBar && bottomBarTitle === undefined && (
            <div className={cx(styles.subtitle, "d-flex align-items-center")}>
              <span>{subtitle}</span>
            </div>
          )}
          <div
            className={cx(
              styles.bottomBarOptions,
              "d-flex align-items-center justify-content-end gap-1 py-2",
            )}
          >
            {bottomBarButtons}
          </div>
        </div>
      )}
    </div>
  );
};
