import styles from "../NotificationSection.module.css";
import cx from "classnames";
import { Button } from "components/common";
import { FormInput, Modal } from "components/utils";
import { Formik } from "formik";
import { UUID } from "api/types";
import { useSendEmailNotification } from "../hooks/useSendEmailNotification";

interface Props {
  close: () => void;
  tradingDocumentId: UUID;
}
export const SendToSpecificAddressModal = ({ close, tradingDocumentId }: Props) => {
  const sendEmailNotificationMutation = useSendEmailNotification();

  return (
    <Modal size={{ all: { width: "400px" } }} isOpen={true} close={close}>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={values => {
          const mutationValues = {
            tradingDocumentId: tradingDocumentId,
            recipientEmail: values.email,
          };
          sendEmailNotificationMutation.mutate(mutationValues, {
            onSuccess: () => close(),
          });
        }}
      >
        {({ handleSubmit, isValid }) => (
          <form
            onSubmit={handleSubmit}
            className={cx({ "was-validated": !isValid }, styles.sections)}
          >
            <h5>Wyślij fakturę na wskazany adres</h5>
            <FormInput className="mt-3" type="email" name="email" label="E-mail" />

            <Button
              type="submit"
              kind="primary"
              size="medium"
              disabled={sendEmailNotificationMutation.isLoading}
            >
              Wyślij
            </Button>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
