import { postNotificationToRecipient } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useSendEmailNotification = () => {
  return useMutation(postNotificationToRecipient, ({ queryClient, toastr }) => ({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        tradingDocumentsKeys.tradingDocument.details(variables.tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Pomyślnie wysłano powiadomienie o fakturze",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};
