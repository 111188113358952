import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { StateLabel } from "components/common/stateLabel";
import { dateFns, getAnyErrorKey } from "utilities";
import { postFiscalizeReceipt } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { QueryClient } from "react-query";
import { Link } from "react-router-dom";
import styles from "./CorrespondingReceiptSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";

interface Props {
  salesInvoice: TradingDocument;
}

export const CorrespondingReceiptSection = ({ salesInvoice }: Props) => {
  const handleRollback = (prevPanel: TradingDocument, queryClient: QueryClient) => {
    queryClient.setQueryData<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(salesInvoice.id),
      currentDocument => {
        assertIsDefined(currentDocument);
        return {
          ...currentDocument,
          correspondingReceipt: {
            id: prevPanel.correspondingReceipt!.id,
            signature: prevPanel.correspondingReceipt!.signature,
            printingStatus: prevPanel.correspondingReceipt!.printingStatus,
            printingRequestedAt: prevPanel.correspondingReceipt!.printingRequestedAt,
          },
        };
      },
    );
  };

  const fiscalizeReceiptOptions = useMutationOptions(
    () => {
      const data = { id: salesInvoice.correspondingReceipt!.id };
      return postFiscalizeReceipt(data);
    },
    ({ toastr, queryClient }) => ({
      onMutate: () => {
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(salesInvoice.id),
        );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(salesInvoice.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              correspondingReceipt: {
                id: currentDocument.correspondingReceipt!.id,
                signature: currentDocument.correspondingReceipt!.signature,
                printingStatus: "IN_PROGRESS",
                printingRequestedAt: null,
              },
            };
          },
        );

        return { prevPanel };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(salesInvoice.id),
        );

        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zlecono fiskalizację.",
        });
      },
      onError: (error, _, context) => {
        const { prevPanel } = context as {
          prevPanel: TradingDocument;
        };

        handleRollback(prevPanel, queryClient);

        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const fiscalizeReceiptMutation = useMutation(
    fiscalizeReceiptOptions.mutationFn,
    fiscalizeReceiptOptions,
  );

  if (!salesInvoice.correspondingReceipt) return null;

  return (
    <RightPanelSection>
      {salesInvoice.correspondingReceipt.printingStatus === "NOT_STARTED" && (
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              <div className="d-flex align-items-center gap-3">
                <div className="body-14-600 text-black-6">nie wydrukowany</div>
                {Boolean(salesInvoice.items.flatMap(item => item.tradingDocumentItems).length) ? (
                  <Button
                    className="text-uppercase"
                    onClick={() => fiscalizeReceiptMutation.mutate({})}
                    size="small"
                    startIcon={MdiReceiptLong}
                    theme="light"
                    variant="gray"
                  >
                    Fiskalizuj
                  </Button>
                ) : (
                  <Typography fontSize="12" fontWeight="700">
                    brak pozycji na fakturze
                  </Typography>
                )}
              </div>
            </InfoLabel>
          </div>
        </>
      )}
      {salesInvoice.correspondingReceipt.printingStatus === "IN_PROGRESS" && (
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              <StateLabel kind="limeBlack">w trakcie fiskalizacji</StateLabel>
            </InfoLabel>
          </div>
        </>
      )}
      {salesInvoice.correspondingReceipt.printingStatus === "FAILED" && (
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              <div className="d-flex align-items-center gap-3">
                <StateLabel kind="deepOrange3">niepowodzenie</StateLabel>
                {Boolean(salesInvoice.items.flatMap(item => item.tradingDocumentItems).length) ? (
                  <Button
                    className="text-uppercase"
                    onClick={() => fiscalizeReceiptMutation.mutate({})}
                    size="small"
                    startIcon={MdiReceiptLong}
                    theme="light"
                    variant="gray"
                  >
                    Fiskalizuj
                  </Button>
                ) : (
                  <Typography fontSize="12" fontWeight="700">
                    brak pozycji na fakturze
                  </Typography>
                )}
              </div>
            </InfoLabel>
          </div>
        </>
      )}
      {salesInvoice.correspondingReceipt.printingStatus === "FINISHED" && (
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              <div className="d-flex align-items-center gap-3">
                <StateLabel kind="blue">wydrukowano</StateLabel>
                <div>
                  {salesInvoice.correspondingReceipt.printingRequestedAt ? (
                    <span className="body-10-400">
                      {dateFns.format(
                        new Date(salesInvoice.correspondingReceipt.printingRequestedAt),
                        "dd.MM.yyyy, HH:mm",
                      )}
                    </span>
                  ) : (
                    <span className="body-10-400">--</span>
                  )}
                </div>
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <InfoLabel title="nr paragonu:">
              <Link
                className={styles.link}
                to={`/finances/receipts/list/all?panelId=${salesInvoice.correspondingReceipt.id}`}
              >
                <div>{salesInvoice.correspondingReceipt.signature}</div>
              </Link>
            </InfoLabel>
          </div>
        </>
      )}
    </RightPanelSection>
  );
};
