import { AvailableViewKeys } from "ducks/ui";
import { useQuery, useRedux, useSelector } from "hooks";
import { omit } from "utilities";

export const useDrawer = (name: AvailableViewKeys, overrittenPanelKey?: string) => {
  const [dispatch, { ui }] = useRedux();
  const highlighted = useSelector(store => store.ui.highlights[name]);
  const panelKey = overrittenPanelKey ?? "panelId";
  const { highlight } = ui;
  const { query, setQuery } = useQuery();
  const searchParamValue = query[panelKey];
  const isActive = (id: number | string) => String(id) === String(searchParamValue);
  const isLastOpened = (id: number | string) => String(id) === String(highlighted);

  const currentQuery = query ?? {};

  const togglePanel = (id: number | string) => {
    const updatedQuery = (() => {
      const omittedQuery = omit(currentQuery, panelKey);
      if (String(id) === searchParamValue) {
        return { ...omittedQuery, [panelKey]: "" };
      }
      dispatch(highlight({ name, id }));
      return { ...omittedQuery, [panelKey]: String(id) };
    })();

    setQuery(updatedQuery);
  };

  const close = () => setQuery({ ...(query ?? {}), [panelKey]: "" });

  return { close, isActive, isLastOpened, togglePanel };
};
