import { WorkingDay } from "api/new-production-plans/models";
import { dateFns } from "utilities";
import { UUID } from "api/types";
import { polishDaysNamesDict } from "CONSTANTS";
import localStyles from "./DateBar.module.css";
import cx from "classnames";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { RemoveWorkingDayButton } from "./components/RemoveWorkingDayButton";
import { MdiWorkProgress } from "components/miloDesignSystem/atoms/icons/MdiWorkProgress";

interface Props {
  days: WorkingDay[];
  productionPlanId: UUID;
}

export const DateBar = ({ days, productionPlanId }: Props) => {
  return (
    <div className={localStyles.dateBar}>
      {days.map((day, index) => (
        <div
          key={day.id}
          className={cx(
            localStyles.dateColumn,
            "d-flex align-items-center justify-content-between",
            {
              [localStyles.dateColumnSaturday]: day.dayOfTheWeek === "SATURDAY",
              [localStyles.dateColumnSunday]: day.dayOfTheWeek === "SUNDAY",
              [localStyles.dateColumnHoliday]: day.isHoliday,
            },
          )}
        >
          <div className="d-flex align-items-center gap-2">
            <strong>Dzień {index + 1}</strong>
            <span className={localStyles.dateName}>{polishDaysNamesDict[day.dayOfTheWeek]}</span>
            <span className="separator" />
            <strong className={localStyles.date}>
              {dateFns.format(new Date(day.date), "dd.MM.yyyy")}
            </strong>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-1">
            <div className="position-relative">
              <Tooltip title="Podgląd wszystkich zleceń z całego dnia">
                <IconButton icon={MdiWorkProgress} variant="blackT" />
              </Tooltip>
            </div>

            <div className="line-divider new-divider" />

            <div className="position-relative">
              <RemoveWorkingDayButton day={day} productionPlanId={productionPlanId} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
