import localStyles from "./FiltersBar.module.css";
import cx from "classnames";
import checkListIcon from "assets/images/checklist.svg";
import { Button } from "components/common";
import { RadioLabels } from "components/utils";
import { Filters } from "../../Shelf";
import { SearchField } from "components/utils/searchField/SearchField";
import { ShelfFilters } from "api/new-production-plans/models";
import { useToggle } from "hooks";
import { AutoAssignModal } from "../shelfContent/subcomponents/autoAssignModal/AutoAssignModal";
import { UUID } from "api/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";

interface Props {
  filters: Filters;
  productionPlanId: UUID;
  search: string;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  shelfFilters: ShelfFilters;
}

const groupByOptions: { id: Filters["groupBy"]; name: string }[] = [
  { id: "collection", name: "trasa/grupa" },
  { id: "departureDate", name: "data wyjazdu" },
  { id: "productCategories", name: "kategoria produktu" },
];

export const FiltersBar = ({
  filters,
  productionPlanId,
  search,
  setFilters,
  shelfFilters,
}: Props) => {
  const autoAssignModal = useToggle();

  return (
    <>
      <div className={cx(localStyles.toolbarHeader, "justify-content-between px-3 py-1")}>
        <div className="d-flex align-items-center">
          <SearchField
            isNewLayout={true}
            onUpdate={value => {
              setFilters(filters => {
                return { ...filters, search: value };
              });
            }}
            overrides={{
              input: { className: localStyles.searchInput },
              wrapper: { className: localStyles.searchWrapper },
            }}
            value={filters.search}
          />
          <span className={cx(localStyles.filtersLabel, "mr-2 ml-3")}>sortuj wg:</span>
          <RadioLabels
            label=""
            name="groupBy"
            allowUncheck={false}
            value={filters.groupBy}
            items={groupByOptions}
            onChange={({ value }) =>
              setFilters(filters => {
                return { ...filters, groupBy: (value as unknown) as Filters["groupBy"] };
              })
            }
            overrides={{ wrapper: { className: "pt-1" } }}
          />
          <div className="line-divider new-divider" />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button kind="submit-medium" onClick={autoAssignModal.open}>
            <div className="btnBase btnBaseMedium btnBase16">
              <img alt="" src={checkListIcon} />
              Przypisz automatycznie
            </div>
          </Button>
          <div className="line-divider new-divider" />
          <Tooltip title="Podgląd planu">
            <IconButton icon={MdiDownloadPdf} variant="blackT" />
          </Tooltip>
        </div>
      </div>
      {autoAssignModal.isOpen && (
        <AutoAssignModal
          close={autoAssignModal.close}
          filters={shelfFilters}
          productionPlanId={productionPlanId}
          search={search}
        />
      )}
    </>
  );
};
