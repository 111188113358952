import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import manufacturerLineItemsIcon from "assets/images/singleOrders.svg";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";
import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { countryCodes } from "CONSTANTS";
import { dateFns, getEditableDateFormat } from "utilities";
import { addWeeks, subWeeks } from "date-fns";

export const manufacturerLineItems: ModuleLink = {
  url: "manufacturer-line-items",
  label: "Poj. zlecenia produkcyjne",
  icon: manufacturerLineItemsIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.DateRangeWithOptions,
          label: "data odbioru",
          name: ["minPickedUpAt", "maxPickedUpAt"],
          options: [
            {
              label: "DZISIAJ",
              value: [getEditableDateFormat(new Date()), getEditableDateFormat(new Date())],
            },
            {
              label: "JUTRO",
              value: [
                getEditableDateFormat(dateFns.addDays(new Date(), 1)),
                getEditableDateFormat(dateFns.addDays(new Date(), 1)),
              ],
            },
            {
              label: "W CIĄGU 3 DNI",
              value: [
                getEditableDateFormat(new Date()),
                getEditableDateFormat(dateFns.addDays(new Date(), 3)),
              ],
            },
            {
              label: "W CIĄGU 5 DNI",
              value: [
                getEditableDateFormat(new Date()),
                getEditableDateFormat(dateFns.addDays(new Date(), 5)),
              ],
            },
          ],
        },
        {
          type: FilterType.Select,
          label: "Odebrane przez sprzedawcę",
          name: "pickedUpBySeller",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "sortuj wg",
          name: "ordering",
          options: [{ label: "Najnowsze zlecenia", value: "-createdAt" }],
        },
        {
          type: FilterType.DateRangeWithOptions,
          label: "data zlecenia",
          name: ["minOrderedAt", "maxOrderedAt"],
          options: [
            {
              label: "POPRZEDNI TYDZIEŃ",
              value: [
                getEditableDateFormat(dateFns.startOfWeek(subWeeks(new Date(), 1))),
                getEditableDateFormat(dateFns.endOfWeek(subWeeks(new Date(), 1))),
              ],
            },
            {
              label: "TEN TYDZIEŃ",
              value: [
                getEditableDateFormat(dateFns.startOfWeek(new Date(), { weekStartsOn: 1 })),
                getEditableDateFormat(dateFns.endOfWeek(new Date(), { weekStartsOn: 1 })),
              ],
            },
            {
              label: "NASTĘPNY TYDZIEŃ",
              value: [
                getEditableDateFormat(dateFns.startOfWeek(addWeeks(new Date(), 1))),
                getEditableDateFormat(dateFns.endOfWeek(addWeeks(new Date(), 1))),
              ],
            },
          ],
        },
        {
          type: FilterType.Select,
          label: "państwo",
          name: "countryCode",
          multiple: true,
          kind: "label",
          options: countryCodes.map(({ icon, value }) => ({
            label: value,
            value,
            icon,
          })),
        },
        {
          type: FilterType.Select,
          label: "priorytet",
          name: "priority",
          options: Object.entries(priorityDict)
            .map(([key, value]) => ({
              label: value.label,
              value: key,
            }))
            .reverse(),
        },
        {
          type: FilterType.Select,
          label: "potwierdzono",
          name: "hasConfirmedDeliveryDate",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
      ]}
    />
  ),
  routing: ManufacturerLineItems,
};
