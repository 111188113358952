import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "hooks";
import { Select } from "components/utils/select/Select";
import localStyles from "./NewAutocomplete.module.css";
import { getDateRangesForFilters } from "utilities/getDateRangesForFilters";

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isOpen
 * @param {any} param0.children
 */
const BaseMenu = forwardRef(({ isOpen, children, ...rest }, ref) => {
  const styles = {};
  if (isOpen) {
    styles.boxShadow = "0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05)";
  } else {
    styles.display = "none";
  }
  return (
    <div ref={ref} style={styles} {...rest}>
      {children}
    </div>
  );
});

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isOpen
 */
export const Menu = forwardRef(({ isOpen, ...props }, ref) => {
  return <BaseMenu ref={ref} isOpen={isOpen} {...props} />;
});


/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isOpen
 * @param {any} param0.children
 * @param {any} param0.asyncHandler
 */
 export const ConnectInvoiceBaseMenu = forwardRef(({ isOpen, children, asyncHandler, onChange, ...rest }, ref) => {
  const dateRanges = getDateRangesForFilters();
  const dateRangesToPick = dateRanges.map(range => {
    return {
      id: range.id,
      name: range.label,
    }
  })
  const manufacturers = useSelector(store => store.partials.manufacturers);
  const [manufacturer, setManufacturer] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const handleManufacturerFilter = (manufacturer) => {
    if (asyncHandler) {
      asyncHandler.filterManufacturer({ manufacturer });
    }
  };

  const handleCreatedAtFilter = (id) => {
    const pickedDateRange = dateRanges.find(range => range.id === id);
    if (asyncHandler && pickedDateRange !== undefined) {
      asyncHandler.filterCreatedAt({ whEntryCreatedAtFrom: pickedDateRange.from, whEntryCreatedAtTo: pickedDateRange.to })
    } else {
      asyncHandler.filterCreatedAt({});
    }
  };

  const styles = {};
  if (isOpen) {
    styles.height = "350px";
    styles.boxShadow = "0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05)";
  } else {
    styles.display = "none";
  }
  return (
    <div ref={ref} style={styles} {...rest}>
      {isOpen && 
        <div className="d-flex gap-2 px-3 pt-3 pb-2">
          <Select
            enableClear={true}
            items={manufacturers}
            label="Producent"
            onChange={value => {
              if (value) {
                handleManufacturerFilter(value.id);
                setManufacturer((value));
              } else {
                handleManufacturerFilter(null);
                setManufacturer(null);
              }
            }}
            overwrites={{ button: { className: localStyles.manufacturerButton }}}
            placeholder="Wybierz producenta"
            selectedItem={manufacturer?.id ?? null}
            style={{ bottom: "100%", top: "", zIndex: 50 }}
            width="auto"
          />
          <Select
            enableClear={true}
            items={dateRangesToPick}
            onChange={value => {
              if (value) {
                setDateRange(value);
                handleCreatedAtFilter(value.id);
              } else {
                handleCreatedAtFilter(null);
                setDateRange({});
              }
            }}
            placeholder="Wybierz przedział czasowy"
            selectedItem={dateRange?.id ?? null}
            overwrites={{ button: { className: localStyles.dateRangeButton }}}
            style={{ bottom: "100%", top: "", zIndex: 50}}
            width="auto"
          />
        </div>
      }
      {children}
    </div>
  );
});

Menu.defaultProps = {
  isOpen: false,
};

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isActive
 * @param {any} param0.children
 * @param {string} param0.className
 */
export const Item = ({ isActive, isSelected, isDisabled, children, ...rest }) => {
  const styles = {};
  if (isActive) {
    styles.color = "rgba(0,0,0,.95)";
    styles.background = "rgba(0,0,0,.03)";
  }
  if (isSelected) {
    styles.color = "rgba(0,0,0,.95)";
    styles.fontWeight = "700";
  }
  if (isDisabled) {
    styles.color = "#666";
    styles.opacity = 0.6;
    styles.cursor = "default";
    styles.pointerEvents = "none";
  }
  return (
    <div style={styles} {...rest}>
      {children}
    </div>
  );
};
