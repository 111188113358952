import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";

interface Props {
  conversionRate?: number;
  fieldName: "amountWithoutTax" | "amountWithTax" | "taxAmountBeforeRounding";
  values: CreateCorrectionValues;
  vatRate: number;
}

export const AmountPerVatRate = ({ conversionRate, fieldName, values, vatRate }: Props) => {
  const filteredPositions = values.positions.filter(position => {
    return (
      !position.alreadyInvoiced &&
      position.vatRate === vatRate &&
      position[fieldName] !== null &&
      position[fieldName] !== undefined
    );
  });

  const getAmount = () => {
    const totalAmount = filteredPositions.reduce((acc, position) => {
      return acc + position[fieldName] * position.quantity;
    }, 0);

    return totalAmount;
  };

  const getCurrencyConvertion = () => {
    const totalAmount = filteredPositions.reduce((acc, position) => {
      return acc + position[fieldName] * position.quantity * conversionRate!;
    }, 0);

    return totalAmount;
  };

  if (
    values.positions
      .filter(position => position.vatRate === vatRate && !position.alreadyInvoiced)
      .some(
        position =>
          position[fieldName] === undefined ||
          position[fieldName] === null ||
          position.quantity < 0 ||
          position[fieldName] < 0,
      )
  ) {
    return (
      <div className="d-flex align-items-center justify-content-end text-color-coral">
        Błąd przeliczania
      </div>
    );
  }

  if (conversionRate) {
    return (
      <>
        <strong className="body-14 fw-800">
          <FinanceAmountFormat value={getCurrencyConvertion()} />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-end">
      <FinanceAmountFormat value={getAmount()} />
    </div>
  );
};
