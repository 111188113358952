import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { cx, dateFns, getStandardDateFormat } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../RightPanel.module.css";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { Link } from "components/miloDesignSystem/atoms/link";
import { AsyncInput } from "components/utils";
import { currenciesToPick } from "CONSTANTS";

interface Props {
  shipment: Shipment;
}

export const GeneralInfoSection = ({ shipment }: Props) => {
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const serviceMutation = shippingActions.usePatchShipping();
  const plannedPickupArrivalDateMutation = shippingActions.usePatchShipping();
  const plannedPickupArrivalTimeMutation = shippingActions.usePatchShipping();
  const cashOnDeliveryMutation = shippingActions.usePatchShipping();
  const currencyMutation = shippingActions.usePatchShipping();

  return (
    <div className="px-3 pt-2 pb-2">
      <Link.Dark
        to={`/orders/list/active/all?panelId=${shipment.order.id}`}
        fontSize="26"
        fontWeight="700"
      >
        {shipment.order.signature}
      </Link.Dark>
      <Typography fontSize="18" fontWeight="700">
        {shipment.trackingNumber || "---"}
      </Typography>
      {shipment.errorNote && (
        <div className={cx(styles.errorWrapper, "d-flex flex-1 align-items-center p-2 mt-3 gap-2")}>
          <div>
            <Typography fontSize="12" fontWeight="600" color="danger500" noWrap className="flex-1">
              Wystąpił następujący błąd:
            </Typography>
          </div>

          <div className="w-75">
            <Tag label={shipment.errorNote} variant="danger" type="outlined" />
          </div>
        </div>
      )}
      <div className="d-flex align-items-start justify-content-between gap-2 mt-3">
        <div className="flex-1">
          <InfoLabel title="pobranie">
            <div className="d-flex align-items-center gap-2">
              <AsyncInput
                disabled={cashOnDeliveryMutation.isLoading}
                inProgress={cashOnDeliveryMutation.isLoading}
                onChange={value =>
                  cashOnDeliveryMutation.mutate({
                    id: shipment.id,
                    toUpdate: { cashOnDelivery: value },
                  })
                }
                overwrites={{
                  container: { className: styles.cashOnDelivery },
                  input: { className: styles.input },
                  floatingLabel: { className: styles.floatingLabel },
                }}
                placeholder="pobranie"
                value={shipment.cashOnDelivery || ""}
              />
              <Select
                className={styles.currency}
                items={currenciesToPick.map(currency => ({
                  value: currency.value,
                  text: currency.label,
                  type: MenuItemType.TEXT,
                }))}
                onChange={currency => {
                  if (currency) {
                    currencyMutation.mutate({
                      id: shipment.id,
                      toUpdate: {
                        currency: currency as string,
                      },
                    });
                  }
                }}
                selected={shipment.currency || null}
              />
            </div>
          </InfoLabel>
          <InfoLabel title="kurier">
            <Select
              items={shippingServices.map(shippingService => ({
                value: shippingService.id,
                text: shippingService.name,
                type: MenuItemType.TEXT,
              }))}
              onChange={id => {
                if (id) {
                  serviceMutation.mutate({
                    id: shipment.id,
                    toUpdate: {
                      shippingService: shippingServices.find(e => e.id === id),
                    },
                  });
                }
              }}
              selected={shipment.shippingService?.id || null}
            />
          </InfoLabel>
          <InfoLabel title="status u kuriera">
            {shipment.externalStatus && shipment.lastStatusUpdate ? (
              <div className="d-flex gap-2 align-items-center">
                <Tag label={shipment.externalStatus} variant="info" type="outlined" />

                <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                  w statusie od:{" "}
                </Typography>
                <Typography fontSize="12" fontWeight="600">
                  {dateFns.format(new Date(shipment.lastStatusUpdate), "dd.MM.yyyy, HH:mm")}
                </Typography>
              </div>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="data odbioru przez kuriera">
            <div className="d-flex align-items-center gap-2">
              <DatePicker
                disabled={plannedPickupArrivalDateMutation.isLoading}
                look="common"
                overwrites={{
                  popup: { className: styles.datePickerPopup },
                  input: { className: styles.datePickerInput },
                  container: { className: cx(styles.datePickerInput, "pl-0") },
                }}
                value={shipment.plannedPickupArrivalDate}
                onChange={date => {
                  return plannedPickupArrivalDateMutation.mutate({
                    id: shipment.id,
                    toUpdate: {
                      plannedPickupArrivalDate: date
                        ? dateFns.format(new Date(date), "yyyy-MM-dd")
                        : null,
                    },
                  });
                }}
              />
              <TimePicker
                disabled={plannedPickupArrivalTimeMutation.isLoading}
                inProgress={plannedPickupArrivalTimeMutation.isLoading}
                look="common"
                onBlur={time =>
                  plannedPickupArrivalTimeMutation.mutate({
                    id: shipment.id,
                    toUpdate: { plannedPickupArrivalTime: time || null },
                  })
                }
                overwrites={{
                  container: { className: styles.formHeight },
                  input: { className: styles.formHeight },
                }}
                value={shipment.plannedPickupArrivalTime}
              />
            </div>
          </InfoLabel>

          <InfoLabel title="odebrano przez kuriera">
            {shipment.courierPickupDate ? (
              <Typography fontSize="14" fontWeight="700">
                {getStandardDateFormat(shipment.courierPickupDate)}
              </Typography>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="dostarczono przez kuriera">
            {shipment.courierDeliveredDate ? (
              <Typography fontSize="14" fontWeight="700">
                {getStandardDateFormat(shipment.courierDeliveredDate)}
              </Typography>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
        </div>
      </div>
    </div>
  );
};
