import { ordersKeys, routeKeys } from "api/keys";
import { getOrderForRoute_query, getPointsForRoute } from "api/orders/calls";
import { OrderPointsForRoute } from "api/orders/models";
import {
  getComments,
  getIndexesForReception,
  getPinToRouteHistory,
  getRampLoadingStatus,
  getReceptionEntries,
  getRoute,
  getRouteAutomaticSmsSchedule,
  getRouteDriversSchedule,
  getRouteEmailsMessages,
  getRouteOrdersComments,
  getRouteProgress,
  getRoutes,
  getRouteSmsMessages,
  getSettledRoute,
  patchRoute_query,
  putRoute,
} from "api/routes/calls";
import { Toastr } from "components/common";
import { useToastr } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { useQueryUtils } from "hooks/hooks";
import { useMutation } from "hooks/useMutation";
import immer from "immer";
import { useRouteSearch } from "pages/routes/creator/hooks/useRouteSearch";
import { useRouteUtils } from "pages/routes/creator/hooks/useRouteUtils";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import { sortOrdersBasedOnOrderPositions } from "pages/routes/creator/utils";
import { QueryClient, useQueryClient } from "react-query";
import { PartialOf } from "typeUtilities";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { parsePatchData } from "utilities/parsePatchData";
import {
  OrderPositions,
  OrderPositionTypes,
  Route,
  RouteOrder,
  RouteOrdersComments,
  RoutePutMutationSchema,
  RoutePutSchema,
} from "./models";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";

export const useComments = createPrimitivePaginatedHook(getComments);
export const useRouteDriversSchedule = createPrimitiveHook(getRouteDriversSchedule);

//TODO usunąć wszystkie key prop errors
export const useRoute = createApiQuery(getRoute);
export const useSettledRoute = createApiQuery(getSettledRoute);

export const useRoutes = createPaginatedApiQuery(getRoutes);
export const useRouteSmsMessages = createApiQuery(getRouteSmsMessages);
export const useRouteEmailMessages = createApiQuery(getRouteEmailsMessages);

export const usePointsForRoute = createApiQuery(getPointsForRoute);

export const useRouteOrdersComments = createApiQuery(getRouteOrdersComments);

export const useDriverLocations = createApiQuery(getRouteProgress);
export const useAutomaticSmsScheduleForRoute = createPaginatedApiQuery(
  getRouteAutomaticSmsSchedule,
);

export const usePinToRouteHistory = createPaginatedApiQuery(getPinToRouteHistory);

export const useReceptionIndexes = createApiQuery(getIndexesForReception);
export const useReceptionEntries = createPaginatedApiQuery(getReceptionEntries);

export const useRampLoadingStatus = createApiQuery(getRampLoadingStatus);

export const useRoutePutMutation = () => {
  const { handleMutate, rollback } = useQueryUtils();
  const {
    getNewRouteBasedOnPutSchema,
    getOrderForRoute,
    getRoute,
    generatePlannedDeliveryTimesAndReturnDate,
  } = useRouteUtils();

  const search = useRouteSearch();
  const queryClient = useQueryClient();
  const actions = useRouteViewState("slave", state => state.actions);
  const toastr = useToastr();

  return useMutation(
    ({ data, route }: { data: RoutePutMutationSchema; route: Route["id"] }) => {
      const {
        returnDate,
        ordersPositions,
        departureTime,
        departureDate,
      } = generatePlannedDeliveryTimesAndReturnDate(data);

      const deliveryTimes = getDeliveryTimes(ordersPositions);
      //TODO to remove WHEN: waiting for backend
      const ordersPositionsWithDuration = ordersPositions.map(el => ({
        ...el,
        duration: el.meta.delivery.time,
      }));

      return putRoute(
        {
          ...data,
          ordersPositions: ordersPositionsWithDuration,
          returnDate,
          departureDate,
          deliveryTimes,
          departureTime,
        },
        route,
      );
    },
    {
      onMutate: async ({ data, route }) => {
        RouteEditedHandler(route);
        actions.setIsGraphhopperPolylineVisible(false);

        const {
          ordersPositions,
          returnDate,
          departureTime,
          departureDate,
        } = generatePlannedDeliveryTimesAndReturnDate(data);

        //Fetch order if not exist in cache
        if (data.operation?.method === "assign") {
          const orderId = data.operation.orders[0];
          const cachedOrder = getOrderForRoute(orderId);

          if (!cachedOrder) {
            await fetchOrder(queryClient, toastr, orderId);
          }
        }

        const prevRoutePoints = (() => {
          if (data.operation) {
            const prevPoints = {
              assign: removePoint,
              unassign: addPoint,
            }[data.operation.method]();

            return prevPoints;

            function addPoint() {
              const prev = queryClient.getQueryData(routeKeys.routeCreatorPoints(search));
              const orderId = data.operation!.orders[0];
              const order = getRoute()?.orders.find(el => el.id === orderId);

              assertIsDefined(order);

              queryClient.setQueryData<any>(
                routeKeys.routeCreatorPoints(search),
                (currentList: OrderPointsForRoute) => {
                  if (!currentList) return;

                  return immer(currentList, draft => {
                    draft.results = [
                      ...draft.results,
                      {
                        id: order.id,
                        hasUpholstery: order.hasUpholstery,
                        leftDays: order.leftDays,
                        point: order.delivery.point,
                        type: order.type,
                        countryName: order.delivery.countryName,
                        numberOfDaysFromCreatedDate: order.numberOfDaysFromCreatedDate,
                        priority: order.priority,
                        warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                        hideOnMapTo: null,
                        hideUntilIssueIsSolved: false,
                        isHidden: false,
                      },
                    ];
                    draft.count = draft.count + 1;
                  });
                },
              );

              return prev;
            }

            function removePoint() {
              const orderId = data.operation!.orders[0];
              const prev = queryClient.getQueryData(routeKeys.routeCreatorPoints(search));

              queryClient.setQueryData<any>(
                routeKeys.routeCreatorPoints(search),
                (currentList: OrderPointsForRoute) => {
                  if (!currentList) return;

                  return immer(currentList, draft => {
                    draft.results = draft.results.filter(el => el.id !== orderId);
                    draft.count = draft.count - 1;
                  });
                },
              );
              return prev;
            }
          } else {
            return queryClient.getQueryData(routeKeys.routeCreatorPoints(search));
          }
        })();

        const prevRouteComments = (() => {
          if (data.operation) {
            const prevPoints = {
              assign: addComment,
              unassign: removeComment,
            }[data.operation.method]();

            return prevPoints;

            function addComment() {
              const prev = queryClient.getQueryData(
                routeKeys.routeCreatorOrdersComments(String(route)),
              );

              const orderId = data.operation!.orders[0];
              const order = getOrderForRoute(orderId);

              assertIsDefined(order);

              queryClient.setQueryData<any>(
                routeKeys.routeCreatorOrdersComments(String(route)),
                (currentList: RouteOrdersComments) => {
                  if (!currentList) return;

                  return immer(currentList, draft => {
                    draft.orders = [
                      ...draft.orders,
                      {
                        id: order.id,
                        comments: order.comments,
                        customer: order.customer
                          ? {
                              ...order.customer,
                              color: "",
                              textColor: "",
                            }
                          : null,
                        delivery: order.delivery,
                        messageToSeller: "",
                        signature: order.signature,
                      },
                    ];
                  });
                },
              );

              return prev;
            }

            function removeComment() {
              const orderId = data.operation!.orders[0];
              const prev = queryClient.getQueryData(
                routeKeys.routeCreatorOrdersComments(String(route)),
              );

              queryClient.setQueryData<any>(
                routeKeys.routeCreatorOrdersComments(String(route)),

                (currentList: RouteOrdersComments) => {
                  if (!currentList) return;

                  return immer(currentList, draft => {
                    draft.orders = draft.orders.filter(el => el.id !== orderId);
                  });
                },
              );
              return prev;
            }
          } else {
            return queryClient.getQueryData(routeKeys.routeCreatorPoints(search));
          }
        })();

        const deliveryTimes = getDeliveryTimes(ordersPositions);

        //TODO to remove WHEN: waiting for backend
        const ordersPositionsWithDuration = ordersPositions.map(el => ({
          ...el,
          duration: el.meta.delivery.time,
        }));

        const prevRoute = handleMutate(
          routeKeys.route(String(route)),
          getNewRouteBasedOnPutSchema({
            ...data,
            ordersPositions: ordersPositionsWithDuration,
            deliveryTimes,
            returnDate,
            departureTime,
            departureDate,
          }),
        );

        return { prevRoute, prevRoutePoints, prevRouteComments };
      },

      //@ts-ignore
      onError: (error, { route }, { prevRoute, prevRoutePoints, prevRouteComments }) => {
        actions.closeLoader();
        rollback(routeKeys.routeCreatorPoints(search), prevRoutePoints);
        rollback(routeKeys.route(String(route)), prevRoute);
        rollback(routeKeys.routeCreatorOrdersComments(String(route)), prevRouteComments);

        if (error.response?.status === 500) {
          toastr.open({
            type: "failure",
            title: "Oj, coś nie tak...",
            text: getAnyErrorKey(error),
          });
          return;
        }

        //handle multiple users working on the same route
        if (error.response?.data?.code && error.response.data.code.includes("412")) {
          queryClient.resetQueries(routeKeys.routeCreatorPoints(search));
          queryClient.resetQueries(routeKeys.route(String(route)));
          toastr.open({
            type: "warning",
            title: getAnyErrorKey(error),
            text: "Odświeżanie strony..",
          });
          return;
        }

        toastr.open({
          type: "warning",
          title: getAnyErrorKey(error),
          text: "",
        });
      },
      onSuccess: (payload, { data, route }) => {
        if (data.operation) {
          if (data.operation.method === "assign") {
            queryClient.setQueryData<any>(
              routeKeys.routeCreatorPoints(search),
              (currentList: OrderPointsForRoute) => {
                if (!currentList) return;

                return immer(currentList, draft => {
                  payload.categories.forEach(category => {
                    const categoryToUpdate = draft.categories.find(el => el.id === category.id);
                    assertIsDefined(categoryToUpdate);
                    categoryToUpdate.count = categoryToUpdate.count - category.count;
                  });
                });
              },
            );
          }

          if (data.operation.method === "unassign") {
            queryClient.setQueryData<any>(
              routeKeys.routeCreatorPoints(search),

              (currentList: OrderPointsForRoute) => {
                if (!currentList) return;

                return immer(currentList, draft => {
                  payload.categories.forEach(category => {
                    const categoryToUpdate = draft.categories.find(el => el.id === category.id);
                    assertIsDefined(categoryToUpdate);
                    categoryToUpdate.count = categoryToUpdate.count + category.count;
                  });
                });
              },
            );
          }
        }
        queryClient.invalidateQueries(routeKeys.route(String(route)));
      },
      onSettled: () => {
        actions.closeLoader();
      },
    },
  );
};

export const useRoutePatchMutation = () => {
  const { handleMutate, rollback, handlePaginatedListUpdate, rollbackList } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<Route> }) =>
      patchRoute_query(parsePatchData(toUpdate), id),
    {
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        const prevPanel = handleMutate(routeKeys.route(String(id)), toUpdate);
        const prevList = handlePaginatedListUpdate(routeKeys.routes(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList, prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
        rollback(routeKeys.route(String(id)), prevPanel, error);
        rollbackList(routeKeys.routes(), prevList, id);
      },
    },
  );
};

export const useAssignManyOrdersMutation = () => {
  const queryClient = useQueryClient();
  const actions = useRouteViewState("slave", state => state.actions);
  const search = useRouteSearch();
  const { generatePlannedDeliveryTimesAndReturnDate } = useRouteUtils();

  return useMutation(
    ({ data, route }: { data: RoutePutMutationSchema; route: Route["id"] }) => {
      const {
        ordersPositions,
        returnDate,
        departureTime,
        departureDate,
      } = generatePlannedDeliveryTimesAndReturnDate(data);

      //TODO to remove WHEN: waiting for backend
      const ordersPositionsWithDuration = ordersPositions.map(el => ({
        ...el,
        duration: el.meta.delivery.time,
      }));
      const deliveryTimes = getDeliveryTimes(ordersPositions);

      return putRoute(
        {
          ...data,
          ordersPositions: ordersPositionsWithDuration,
          deliveryTimes,
          returnDate,
          departureTime,
          departureDate,
        },
        route,
      );
    },
    {
      onMutate: ({ data }) => {
        queryClient.setQueryData<any>(
          routeKeys.routeCreatorPoints(search),

          (currentList: OrderPointsForRoute) => {
            if (!currentList) return;

            return immer(currentList, draft => {
              draft.results = draft.results.filter(
                el => !data.operation?.orders.some(id => id === el.id),
              );

              draft.count = draft.count - data.operation!.orders.length;
            });
          },
        );
      },
      onSettled: (data, _, { route }) => {
        queryClient.invalidateQueries(routeKeys.routeCreatorPoints());
        queryClient.invalidateQueries(routeKeys.route(String(route)));
        actions.closeLoader();
      },
    },
  );
};

async function fetchOrder(queryClient: QueryClient, toastr: Toastr, orderId: number) {
  try {
    const payloadOrder = await getOrderForRoute_query(orderId).fetcher();

    queryClient.setQueryData(ordersKeys.orderForRoute(String(payloadOrder.id)), payloadOrder);
  } catch (err) {
    if (err)
      toastr.open({
        type: "warning",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(err),
      });
  }
}

function getDeliveryTimes(ordersPositions: OrderPositions): RoutePutSchema["deliveryTimes"] {
  return ordersPositions
    .filter(el => el.type === "order")
    .map(({ id, meta: { delivery: { time, distance, stopoverTime, plannedDeliveryTime } } }) => ({
      order: Number(id),
      time,
      distance,
      stopoverTime,
      plannedDeliveryTime,
    }));
}

export function transformMeta(route: Route): Route {
  const testRoute = { ...route };
  const orders = sortOrdersBasedOnOrderPositions(route.ordersPositions, route.orders);

  const ordersPositions = (() => {
    const orderDict: Record<string, RouteOrder> = route.orders.reduce((acc, el) => {
      Object.assign(acc, { [el.id]: el });
      return acc;
    }, {});

    const ordersPositionsWithMeta = route.ordersPositions.map(el => {
      if (el.type === "stopover") {
        const meta: OrderPositionTypes["stopover"]["meta"] = {
          point: null,
          delivery: {
            //@ts-ignore
            time: el.duration,
            distance: 0,
            stopoverTime: 0,
            plannedDeliveryTime: null,
            address: null,
          },
        };
        return { ...el, meta };
      }

      if (el.type === "order") {
        const order = orderDict[el.id];
        const meta: OrderPositionTypes["order"]["meta"] = {
          point: order.delivery.point,
          delivery: {
            time: order.delivery.time,
            distance: order.delivery.distance,
            stopoverTime: order.delivery.stopoverTime,
            plannedDeliveryTime: order.delivery.plannedDeliveryTime,
            address: null,
          },
        };
        return { ...el, meta };
      }

      return el;
    });

    return ordersPositionsWithMeta;
  })();
  return { ...testRoute, orders, ordersPositions };
}
