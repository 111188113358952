import { deleteTradingDocument } from "api/trading-documents/calls";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { getAnyErrorKey } from "utilities";
import { TradingDocumentStatus } from "api/trading-documents/models";
import popUpstyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import deleteRed from "assets/images/delete_red.svg";
import cx from "classnames";

interface Props {
  close: () => void;
  tradingDocumentId: UUID;
  tradingDocumentSignature: string;
  tradingDocumentStatus: TradingDocumentStatus;
}

export const DeleteTradingDocumentBtn = ({
  close,
  tradingDocumentId,
  tradingDocumentSignature,
  tradingDocumentStatus,
}: Props) => {
  const deleteMutation = withDeleteConfirmation(
    useMutation(deleteTradingDocument, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto fakturę ${tradingDocumentSignature}`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();
  return (
    <button
      disabled={deleteMutation.isLoading || tradingDocumentStatus === "CONFIRMED"}
      onClick={() => deleteMutation.mutate(tradingDocumentId)}
      title={
        tradingDocumentStatus === "CONFIRMED"
          ? "Nie można usunąć zatwierdzonej faktury"
          : "Usuń fakturę"
      }
      className={cx(" px-3 py-2 w-100", popUpstyles.optionRow)}
    >
      <div className="d-flex align-items-center justify-content-between gap-2">
        <div className="d-flex align-items-center gap-2">
          <img src={deleteRed} alt="Usuń" />
          <div className="body-14-600 text-red-4">Usuń</div>
        </div>
      </div>
    </button>
  );
};
