import { ordersKeys, routeKeys } from "api/keys";
import { OrderForRoute } from "api/orders/models";
import { RoutePutMutationSchema, RoutePutSchema, Route, OrderPositions } from "api/routes/models";
import { addSeconds, subSeconds } from "date-fns";
import { useQuery, useSelector } from "hooks";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { dateFns } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import immer from "immer";
import {
  transformOrderForRouteToMatchRouteOrder,
  getDepartureFullDate,
  getOrderToStopoverDurationDict,
} from "../utils";

function getDeliveryTimesBasedOnAverageSpeed(putData: RoutePutMutationSchema, route: Route) {
  if (!putData.shouldCalculateAverageSpeed) return putData.ordersPositions;
  if (putData.averageSpeed === null) return putData.ordersPositions;

  if (putData.averageSpeed || route.averageSpeed) {
    const newAverageSpeed = (() => {
      if (putData.averageSpeed) {
        return putData.averageSpeed;
      }
      if (route.averageSpeed) {
        return route.averageSpeed;
      }
      return null;
    })();

    const newPutData = putData.ordersPositions.filter(el => el.type !== "stopover");

    const duration =
      newPutData.reduce((acc, el) => acc + (el.meta.delivery.time || 0), 0) +
      Number(putData.returnToStartingPointTime);

    const distance =
      newPutData.reduce((acc, el) => acc + (el.meta.delivery.distance || 0), 0) +
      Number(putData.returnToStartingPointDistance);

    const graphhopperAverageSpeed = convertMeterPerSecondToKilometerPerHour(distance / duration);

    function convertMeterPerSecondToKilometerPerHour(val: number) {
      return val * 3.6;
    }

    const speedFactor = Number(graphhopperAverageSpeed.toFixed(0)) / Number(newAverageSpeed);

    function calculateTimeWithSpeedFactor(time: number) {
      return Math.round(time * speedFactor);
    }

    return putData.ordersPositions.map(el => {
      if (el.meta.point) {
        return {
          ...el,
          meta: {
            ...el.meta,
            delivery: {
              ...el.meta.delivery,
              time: calculateTimeWithSpeedFactor(el.meta.delivery.time || 0),
            },
          },
        };
      }
      return el;
    });
  } else {
    return putData.ordersPositions;
  }
}
export const useRouteUtils = () => {
  const params = useParams<{ routeId: string }>();
  const { query } = useQuery();

  const routeId = (() => {
    if (params.routeId) {
      return params.routeId;
    } else {
      return query.panelId;
    }
  })();

  const queryClient = useQueryClient();
  const startingPoints = useSelector(state => state.partials.startingPoints);

  const getRoute = (): Route | undefined => {
    return queryClient.getQueryData(routeKeys.route(routeId));
  };

  const getOrderForRoute = (orderId: number): OrderForRoute | undefined => {
    return queryClient.getQueryData(ordersKeys.orderForRoute(String(orderId)));
  };

  function getNewRouteBasedOnPutSchema(putData: RoutePutSchema): Route {
    const route = getRoute();
    assertIsDefined(route);

    const newRoute = immer(route, draft => {
      draft.ordersPositions = putData.ordersPositions;
      draft.returnDistance = putData.returnToStartingPointDistance;
      draft.returnTime = Number(putData.returnToStartingPointTime);
      draft.length = putData.length;

      if (putData.includeLastPointInOptimization !== undefined) {
        draft.includeLastPointInOptimization = putData.includeLastPointInOptimization;
      }
      if (putData.vehicleType !== undefined) {
        draft.vehicleType = putData.vehicleType;
      }

      if (putData.defaultStopoverTime !== undefined) {
        draft.defaultStopoverTime = putData.defaultStopoverTime;
      }

      if (putData.averageSpeed !== undefined) {
        draft.averageSpeed = putData.averageSpeed;
      }

      if (putData.firstArrivalTime !== undefined) {
        draft.firstArrivalTime = putData.firstArrivalTime;
      }

      if (putData.returnDate !== undefined) {
        draft.returnDate = putData.returnDate;
      }

      if (putData.startingPoint !== undefined) {
        const newStartingPoint = startingPoints.find(el => el.id === putData.startingPoint);
        assertIsDefined(newStartingPoint);
        draft.startingPoint = newStartingPoint;
      }

      if (putData.departureTime !== undefined) {
        draft.departureTime = putData.departureTime;
      }

      if (putData.departureDate !== undefined) {
        draft.departureDate = putData.departureDate;
      }

      const ordersPositionDict: Record<
        string,
        OrderPositions[number]
      > = putData.ordersPositions.reduce((acc, el) => {
        Object.assign(acc, { [el.id]: el });
        return acc;
      }, {});

      if (putData.operation) {
        if (putData.operation.method === "assign") {
          const order = getOrderForRoute(putData.operation!.orders[0]);
          assertIsDefined(order);
          const transformedOrder = transformOrderForRouteToMatchRouteOrder(order);

          draft.orders = [
            ...draft.orders,
            {
              ...transformedOrder,
              delivery: {
                ...transformedOrder.delivery,
                ...ordersPositionDict[transformedOrder.id].meta!.delivery,
              },
            },
          ];
          return draft;
        }

        if (putData.operation.method === "unassign") {
          draft.orders = draft.orders.filter(el => el.id !== putData.operation!.orders[0]);
        }
      }

      draft.orders = draft.orders.map(order => ({
        ...order,
        delivery: {
          ...order.delivery,
          time: ordersPositionDict[order.id].meta!.delivery.time,
          distance: ordersPositionDict[order.id].meta!.delivery.distance,
          plannedDeliveryTime: ordersPositionDict[order.id].meta!.delivery.plannedDeliveryTime,
          stopoverTime: ordersPositionDict[order.id].meta!.delivery.stopoverTime,
        },
      }));
    });

    return newRoute;
  }

  function generatePlannedDeliveryTimesAndReturnDate(
    putData: RoutePutMutationSchema,
  ): {
    returnDate: RoutePutSchema["returnDate"];
    ordersPositions: RoutePutSchema["ordersPositions"];
    departureTime: RoutePutSchema["departureTime"];
    departureDate?: RoutePutSchema["departureDate"];
  } {
    const route = getRoute();
    assertIsDefined(route);
    const ordersPositionWithAverageSpeed = getDeliveryTimesBasedOnAverageSpeed(putData, route);

    // clear planned delivery time and return date if user set departure to null
    if (putData.departureDate === null || putData.departureTime === null) {
      return {
        returnDate: null,
        ordersPositions: removePlannedDeliveryTimes(ordersPositionWithAverageSpeed),
        departureTime: null,
        departureDate: null,
      };
    }

    const departureDateBasedOnFirstArrivalTime = getDepartureDateBasedOnFirstArrivalTime(
      putData,
      route,
      ordersPositionWithAverageSpeed,
    );

    const fullDepartureDate = (() => {
      if (departureDateBasedOnFirstArrivalTime) {
        return departureDateBasedOnFirstArrivalTime;
      }
      if (putData.departureDate !== undefined && putData.departureTime !== undefined) {
        return getDepartureFullDate(putData.departureDate, putData.departureTime);
      }
      return getDepartureFullDate(route.departureDate, route.departureTime);
    })();

    if (fullDepartureDate === null)
      return {
        ordersPositions: removePlannedDeliveryTimes(ordersPositionWithAverageSpeed),
        returnDate: null,
        departureTime: route.departureTime,
        departureDate: route.departureDate,
      };

    const orderToStopoverDurationDict = getOrderToStopoverDurationDict(putData.ordersPositions);
    let orderDepartureDate = new Date(fullDepartureDate);

    const ordersPositionsWithPlannedDeliveryTime = (() => {
      const ordersPositions: RoutePutSchema["ordersPositions"] = [];
      ordersPositionWithAverageSpeed.forEach(item => {
        if (item.type === "stopover") {
          ordersPositions.push(item);
          return;
        }

        orderDepartureDate = addSeconds(
          orderDepartureDate!,
          (item.meta.delivery.time || 0) + orderToStopoverDurationDict[item.id].stopoverDuration,
        );

        const toAdd = {
          ...item,
          meta: {
            ...item.meta,
            delivery: {
              ...item.meta.delivery,
              plannedDeliveryTime: dateFns.format(orderDepartureDate, "yyyy-MM-dd HH:mm:ss"),
            },
          },
        } as RoutePutSchema["ordersPositions"][number];

        ordersPositions.push(toAdd);

        orderDepartureDate = addSeconds(orderDepartureDate, item.meta.delivery.stopoverTime);
      });

      return ordersPositions;
    })();

    const lastStopoversDuration = getLastStopoversDuration(putData.ordersPositions);

    const fullReturnDate = addSeconds(
      new Date(orderDepartureDate),
      lastStopoversDuration + Number(putData.returnToStartingPointTime),
    );

    const returnDate = dateFns.format(fullReturnDate, "yyyy-MM-dd HH:mm:ss");

    return {
      ordersPositions: ordersPositionsWithPlannedDeliveryTime,
      returnDate,
      departureDate: dateFns.format(fullDepartureDate, "yyyy-MM-dd"),
      departureTime: departureDateBasedOnFirstArrivalTime
        ? dateFns.format(departureDateBasedOnFirstArrivalTime, "HH:mm")
        : putData.departureTime,
    };
  }

  return {
    getRoute,
    getOrderForRoute,
    getNewRouteBasedOnPutSchema,
    generatePlannedDeliveryTimesAndReturnDate,
  };
};

function removePlannedDeliveryTimes(
  ordersPositions: RoutePutMutationSchema["ordersPositions"],
): OrderPositions {
  return ordersPositions.map(el => ({
    ...el,
    meta: { ...el.meta, delivery: { ...el.meta.delivery, plannedDeliveryTime: null } },
  }));
}

function getDepartureDateBasedOnFirstArrivalTime(
  putData: RoutePutMutationSchema,
  route: Route,
  ordersPositions: RoutePutMutationSchema["ordersPositions"],
) {
  if (putData.firstArrivalTime === null) {
    return null;
  }
  if (!putData.firstArrivalTime && !route.firstArrivalTime) {
    return null;
  }

  const firstArrivalSeconds: string = (() => {
    if (putData.firstArrivalTime) {
      return putData.firstArrivalTime;
    }
    return route.firstArrivalTime!;
  })();

  const departureDate: string = (() => {
    if (putData.departureDate) return putData.departureDate;

    if (putData.firstArrivalDate) return putData.firstArrivalDate;

    if (route.firstArrivalDate) return route.firstArrivalDate;

    return route.departureDate!;
  })();

  const [hours, minutes] = firstArrivalSeconds.split(":").map(Number);
  const departure = new Date(departureDate);
  departure.setHours(hours, minutes, 0);
  const newDate = subSeconds(departure, ordersPositions[0].meta.delivery.time!);

  return newDate;
}

function getLastStopoversDuration(ordersPositions: RoutePutMutationSchema["ordersPositions"]) {
  let seconds = 0;
  const reversedOrdersPositions = [...ordersPositions].reverse();

  reversedOrdersPositions.some(item => {
    if (item.type === "stopover") {
      seconds = seconds + (item.meta.delivery?.time || 0);
      return false;
    }
    return item.type === "order";
  });
  return seconds;
}
