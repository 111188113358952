import { ButtonHTMLAttributes, ForwardedRef } from "react";
import styles from "./IconButton.module.css";
import cx from "classnames";
import { noop } from "utilities";
import React from "react";
import { BaseIcon } from "../icons/types";
import { ColorPalette } from "../colorsPalette";

const DEFAULT_ICON_SIZE = "16" as const;

type ButtonVariant =
  | "deepPurple"
  | "danger"
  | "blackST"
  | "black"
  | "blackT"
  | "outlineSuccessLight"
  | "outline"
  | "success"
  | "white"
  | "whiteST"
  | "whiteT"
  | "outlineSuccessDark";

enum ButtonTheme {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

const themeSelector: Record<ButtonVariant, { theme: ButtonTheme; color: ColorPalette }> = {
  deepPurple: { theme: ButtonTheme.LIGHT, color: "neutralWhite100" },
  danger: { theme: ButtonTheme.LIGHT, color: "neutralWhite100" },
  blackST: { theme: ButtonTheme.LIGHT, color: "neutralBlack88" },
  black: { theme: ButtonTheme.LIGHT, color: "neutralWhite100" },
  blackT: { theme: ButtonTheme.LIGHT, color: "neutralBlack88" },
  outlineSuccessLight: { theme: ButtonTheme.LIGHT, color: "neutralBlack88" },
  white: { theme: ButtonTheme.DARK, color: "neutralBlack88" },
  whiteST: { theme: ButtonTheme.DARK, color: "neutralWhite100" },
  whiteT: { theme: ButtonTheme.DARK, color: "neutralWhite100" },
  outlineSuccessDark: { theme: ButtonTheme.DARK, color: "success400" },
  outline: { theme: ButtonTheme.LIGHT, color: "neutralBlack88" },
  success: { theme: ButtonTheme.LIGHT, color: "neutralBlack20" },
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  icon: React.FunctionComponent<BaseIcon> | JSX.Element;
  isLoading?: boolean;
  size?: "small" | "medium";
  variant: ButtonVariant;
}

export const IconButton = React.forwardRef(
  (
    {
      className,
      disabled = false,
      isLoading = false,
      icon: Icon,
      size = "medium",
      variant,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    if (disabled)
      return (
        <DisabledIconButton
          className={className}
          disabled={disabled}
          icon={Icon}
          size={size}
          variant={variant}
          {...rest}
        />
      );

    if (isLoading)
      return (
        <LoadingIconButton
          className={className}
          disabled={disabled}
          icon={Icon}
          size={size}
          variant={variant}
          {...rest}
        />
      );

    return (
      <button
        className={cx(styles.button, styles[variant], styles[size], className)}
        ref={ref}
        type="button"
        {...rest}
      >
        {Icon && typeof Icon === "function" ? (
          <Icon color={themeSelector[variant].color} size={DEFAULT_ICON_SIZE} />
        ) : Icon ? (
          Icon
        ) : null}
      </button>
    );
  },
);

const DisabledIconButton = ({
  className,
  disabled = false,
  isLoading,
  icon: Icon,
  size = "medium",
  variant,
  ...rest
}: Props) => {
  return (
    <button
      className={cx(
        styles.button,
        styles[size],
        {
          [styles["disabledB"]]: themeSelector[variant].theme === ButtonTheme.LIGHT,
          [styles["disabledW"]]: themeSelector[variant].theme === ButtonTheme.DARK,
        },
        className,
      )}
      style={{ cursor: "not-allowed" }}
      type="button"
      {...rest}
      onClick={noop}
    >
      {Icon && typeof Icon === "function" ? (
        <Icon color={themeSelector[variant].color} size={DEFAULT_ICON_SIZE} />
      ) : Icon ? (
        Icon
      ) : null}
    </button>
  );
};

const LoadingIconButton = ({
  className,
  disabled = false,
  icon: Icon,
  size = "medium",
  variant,
  ...rest
}: Props) => {
  return (
    <button
      className={cx(styles.button, styles[size], {
        [styles["disabledB"]]: themeSelector[variant].theme === ButtonTheme.LIGHT,
        [styles["disabledW"]]: themeSelector[variant].theme === ButtonTheme.DARK,
      })}
      style={{ cursor: "wait" }}
      type="button"
      {...rest}
    >
      {Icon && typeof Icon === "function" ? (
        <Icon color={themeSelector[variant].color} size={DEFAULT_ICON_SIZE} />
      ) : Icon ? (
        Icon
      ) : null}
    </button>
  );
};
