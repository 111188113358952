import { HighlightedRow } from "api/other/models";
import {
  invoicePaymentStatusDict,
  invoiceStatusDict,
  TradingDocument,
  tradingDocumentNotificationStatusDict,
} from "api/trading-documents/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../RouteInvoicesList.module.css";
import { logisticsRouteTradingDocumentsListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { StateLabel } from "components/common/stateLabel";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { countryCodesDict } from "CONSTANTS";
import { dateFns, pluralize } from "utilities";
import { dateExceedsToday } from "pages/tradingDocuments/shared/utils/dateExceedsToday";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { getStatusLabelKind } from "pages/tradingDocuments/shared/utils/getStatusLabelKind";
import { PaymentDeadlineDeltaLabel } from "pages/tradingDocuments/shared/components/PaymentDeadlineDeltaLabel";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  route: string | number;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
  tradingDocument: TradingDocument;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  route,
  selectMutlipleItems,
  togglePanel,
  tradingDocument,
}: Props) => {
  return (
    <ModuleRow
      className={cx({
        active: isActive(tradingDocument.id),
        highlighted:
          isHighlighted(tradingDocument.id) ||
          highlightedRows.some(row => row.id === tradingDocument.id),
      })}
      gridStyle={logisticsRouteTradingDocumentsListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, tradingDocument.id, index, () => togglePanel(tradingDocument.id))
      }
    >
      <div>
        <ConfirmCheckbox route={route} tradingDocument={tradingDocument} />
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={cx(styles.client, styles.overflow, "fw-700")}>
          {tradingDocument.recipientCompanyName ? (
            tradingDocument.recipientCompanyName
          ) : (
            <>
              {tradingDocument.recipientFirstName} {tradingDocument.recipientLastName}
            </>
          )}
        </div>
        {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
          <StateLabel
            kind="green"
            opacity={
              tradingDocument.createdAt && isDateOlderThan24Hours(tradingDocument.createdAt)
                ? "high"
                : "default"
            }
          >
            <div>{invoiceStatusDict.NEW}</div>
          </StateLabel>
        )}
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={styles.signature}>
          {tradingDocument.signature.length > 0 ? tradingDocument.signature : "--"}
        </div>
        {tradingDocument.type === "INVOICE" && <StateLabel kind="indigo">faktura</StateLabel>}
        {tradingDocument.type === "RECEIPT" && <StateLabel kind="deepPurple">paragon</StateLabel>}
      </div>
      <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
        <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
        <div className="fs-10 text-color-grey">{tradingDocument.currency}</div>
      </div>
      {Boolean(tradingDocument.countryCode.length) ? (
        <div className="d-flex align-items-center gap-1">
          <img alt="Flaga kraju" src={countryCodesDict[tradingDocument.countryCode]} />
          <Typography fontSize="12" fontWeight="700">
            {tradingDocument.countryCode}
          </Typography>
        </div>
      ) : (
        <Typography className="d-flex justify-content-center" fontSize="12" fontWeight="700">
          --
        </Typography>
      )}
      <div className={styles.date}>
        {tradingDocument.invoiceIssueDate
          ? dateFns.format(new Date(tradingDocument.invoiceIssueDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div className={cx(styles.date, "d-flex align-items-center")}>
        {tradingDocument.paymentDeadline ? (
          <div
            className={cx({
              "text-red-6": dateExceedsToday(tradingDocument.paymentDeadline),
            })}
          >
            {dateFns.format(new Date(tradingDocument.paymentDeadline), "dd.MM.yyyy")}
          </div>
        ) : (
          "---"
        )}
        {tradingDocument.basePaymentDeadlineDelta && (
          <div>
            &nbsp;&nbsp;({tradingDocument.basePaymentDeadlineDelta}{" "}
            {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}
            )
          </div>
        )}
      </div>
      <div className={cx(styles.signature, "d-flex align-items-center")}>
        {tradingDocument.orders.slice(0, 1).map(order => {
          return order ? order.signature : "";
        })}{" "}
        &nbsp;
        {tradingDocument.orders.filter(order => order).length - 1 > 0 ? (
          <MoreDataCounter
            counter={tradingDocument.orders.filter(order => order).length - 1}
            style={{ width: "max-content" }}
          />
        ) : null}
      </div>
      <div>
        <StateLabel
          kind={getStatusLabelKind(tradingDocument.paymentStatus, tradingDocument.paymentDeadline)}
        >
          {tradingDocument.paymentStatus === "PAID" ? (
            <>{invoicePaymentStatusDict[tradingDocument.paymentStatus]}</>
          ) : (
            "Nieopłacona"
          )}
        </StateLabel>
      </div>
      <div className="fw-700">
        {tradingDocument.paymentDeadlineDelta !== null &&
          tradingDocument.paymentStatus !== "PAID" && (
            <PaymentDeadlineDeltaLabel
              paymentDeadlineDelta={tradingDocument.paymentDeadlineDelta}
            />
          )}
      </div>
      <div className="fw-700">
        {tradingDocument.latestMessageStatus && tradingDocument.latestMessageStatus.length > 0 ? (
          <>{tradingDocumentNotificationStatusDict[tradingDocument.latestMessageStatus]}</>
        ) : (
          "--"
        )}
      </div>
      <div>
        {tradingDocument.assignedTo && (
          <div className="d-flex align-items-center gap-1">
            {tradingDocument.assignedTo.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={tradingDocument.assignedTo.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarSmall)}
                firstName={tradingDocument.assignedTo.firstName}
                lastName={tradingDocument.assignedTo.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {tradingDocument.assignedTo.firstName} {tradingDocument.assignedTo.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={cx(styles.subject, styles.overflow)}>
        {tradingDocument.sellerCompanyName ? (
          tradingDocument.sellerCompanyName
        ) : (
          <>
            {tradingDocument.sellerFirstName} {tradingDocument.sellerLastName}
          </>
        )}
      </div>
    </ModuleRow>
  );
};
