import { deleteCarrier } from "api/logistics/carriers/calls";
import { carriersKeys } from "api/logistics/carriers/keys";
import { Carrier } from "api/logistics/carriers/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { cx, getAnyErrorKey } from "utilities";

interface Props {
  carrier: Carrier;
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ carrier, close, isScrolledOver }: Props) => {
  const deleteMutation = withDeleteConfirmation(
    useMutation(deleteCarrier, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(carriersKeys.carriers.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto przewoźnika "${carrier.name}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć przewoźnika?",
  )();

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
        })}
      >
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{carrier.name}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Usuń przewoźnika">
            <IconButton
              icon={MdiDelete}
              onClick={() => deleteMutation.mutate(carrier.id)}
              variant="blackT"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="blackT" />
          </Tooltip>
        </div>
      </RightPanelHeader>
    </>
  );
};
