import styles from "./TableToolbar.module.css";
import cx from "classnames";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { PropsWithChildren } from "react";

interface Props {
  close: () => void;
  numberOfSelectedItems: number;
}

export const TableToolbar = ({
  close,
  numberOfSelectedItems,
  children,
}: PropsWithChildren<Props>) => {
  if (numberOfSelectedItems < 2) return null;

  return (
    <div className={cx(styles.changeMany, {})}>
      <Typography fontSize="14" fontWeight="600" color="neutralWhite100" className="mr-2">
        Zaznaczono {numberOfSelectedItems}
      </Typography>

      {children}

      <IconButton icon={MdiClose} onClick={() => close()} variant="whiteT" />
    </div>
  );
};
