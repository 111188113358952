import { HighlightedRow } from "api/other/models";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";
import { TradingDocument } from "api/trading-documents/models";

export type ValueToUpdate = Partial<Pick<TradingDocument, FieldToUpdate>>;
export type FieldToUpdate = "ledgerAccount";

interface Props {
  tradingDocumentsArray: Pick<HighlightedRow, "id">[];
  fieldToUpdate: FieldToUpdate;
  valueToUpdate: any;
  mutation: UseMutationResult<
    HighlightedRow,
    QueryFetchError,
    {
      ids: Pick<HighlightedRow, "id">[];
      fieldToUpdate: FieldToUpdate;
      valueToUpdate: Partial<TradingDocument>;
    },
    unknown
  >;
}
export const handleBulkEditInvoices = ({
  tradingDocumentsArray,
  fieldToUpdate,
  valueToUpdate,
  mutation,
}: Props) => {
  const mutationObject = {
    ids: tradingDocumentsArray,
    fieldToUpdate: fieldToUpdate,
    valueToUpdate: valueToUpdate,
  };
  mutation.mutate(mutationObject);
};
