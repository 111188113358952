import * as React from "react";

interface Props {
  status?: number | string;
  show?: boolean;
  text?: React.ReactNode;
}

const statusesDict: { [key: string]: string } = {
  0: "Brak połączenia z serwerem lub internetem",
  400: "Wystąpił błąd komunikacji z serwerem",
  401: "Brak dostępu - nie rozpoznano użytkownika",
  403: "Brak dostępu",
  404: "Nie znaleziono",
  500: "Wystąpił błąd serwera",
  front: "Wystąpił błąd aplikacji",
};

export const CommonError = ({ status = "", show = true, text }: Props) => {
  if (!show) return null;
  const message = text || statusesDict[status] || "Błąd serwera";
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="card">
        <div className="card-body">{message}</div>
      </div>
    </div>
  );
};
