import cx from "classnames";
import styles from "./LocationFiltersBar.module.css";
import reclamationImg from "assets/images/p31.png";
import marker1Img from "assets/images/p30.png";
import marker2Img from "assets/images/p1.png";
import marker3Img from "assets/images/p5.png";
import marker4Img from "assets/images/p9.png";
import marker5Img from "assets/images/p13.png";
import marker6Img from "assets/images/p17.png";
import marker7Img from "assets/images/p21.png";
import marker8Img from "assets/images/p25.png";
import othersImg from "assets/images/p35.png";
import hasCarryingImg from "assets/images/p54.png";
import standardImg from "assets/images/p34.png";
import boxImg from "assets/images/p42.png";
import normalPriority from "assets/images/priority_normal.svg";
import highPriority from "assets/images/priority_high.svg";
import criticalPriority from "assets/images/priority_critical.svg";
import { TimeGroupBtn } from "./TimeGroupBtn";
import { useQuery, useSettings } from "hooks";
import { useCheckboxFilter } from "../hooks/useCheckboxFilter";
import { PriorityBtn } from "./PriorityBtn";

// awaiting icons
import awaitingNormal0 from "assets/images/mapMarkers/awaiting/awaiting0.svg";
import awaitingNormal1 from "assets/images/mapMarkers/awaiting/awaiting1.svg";
import awaitingNormal2 from "assets/images/mapMarkers/awaiting/awaiting2.svg";
import awaitingNormal3 from "assets/images/mapMarkers/awaiting/awaiting3.svg";
import awaitingNormal4 from "assets/images/mapMarkers/awaiting/awaiting4.svg";
import awaitingNormal5 from "assets/images/mapMarkers/awaiting/awaiting5.svg";
import awaitingNormal6 from "assets/images/mapMarkers/awaiting/awaiting6.svg";
import awaitingNormal7 from "assets/images/mapMarkers/awaiting/awaiting7.svg";

export const LocationFiltersBar = () => {
  const { updateQuery, query } = useQuery();
  const { isChecked, updateCheckbox } = useCheckboxFilter("timeGroup");
  const transport = useSettings().transport;

  return (
    <div className={styles.mapFilters}>
      <div className="d-flex flex-wrap justify-content-center">
        <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
          <label className={styles["version-box-option"]}>
            <input
              type="checkbox"
              name="points"
              checked={query.type === "COMPLAINT"}
              onChange={() => {
                query.type === "COMPLAINT"
                  ? updateQuery({ type: "" })
                  : updateQuery({ type: "COMPLAINT" });
              }}
            />
            <img src={reclamationImg} alt="" />
          </label>
          <label className={styles["version-box-option"]}>
            <input
              type="checkbox"
              name="points"
              checked={query.type === "STANDARD"}
              onChange={() => {
                query.type === "STANDARD"
                  ? updateQuery({ type: "" })
                  : updateQuery({ type: "STANDARD" });
              }}
            />
            <img src={standardImg} alt="" />
          </label>
        </div>
        <ProductTypeFilters />
        <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
          <label className={styles["version-box-option"]} title="Z wniesieniem">
            <input
              type="checkbox"
              name="hasCarrying"
              checked={query.hasCarrying === "true"}
              onChange={() => {
                query.hasCarrying === "true"
                  ? updateQuery({ hasCarrying: "" })
                  : updateQuery({ hasCarrying: "true" });
              }}
            />
            <img src={hasCarryingImg} alt="" />
          </label>
        </div>

        <PriorityFilters />

        <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
          <TimeGroupBtn
            isChecked={isChecked("1")}
            updateCheckbox={val => updateCheckbox(val, "1")}
            img={marker2Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("2")}
            updateCheckbox={val => updateCheckbox(val, "2")}
            img={marker3Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("3")}
            updateCheckbox={val => updateCheckbox(val, "3")}
            img={marker4Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("4")}
            updateCheckbox={val => updateCheckbox(val, "4")}
            img={marker5Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("5")}
            updateCheckbox={val => updateCheckbox(val, "5")}
            img={marker6Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("6")}
            updateCheckbox={val => updateCheckbox(val, "6")}
            img={marker7Img}
          />
          <TimeGroupBtn
            isChecked={isChecked("7")}
            updateCheckbox={val => updateCheckbox(val, "7")}
            img={marker8Img}
          />
        </div>

        {transport.showOnMapOrdersOnWayToWarehouse && (
          <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
            <TimeGroupBtn
              isChecked={isChecked("8")}
              updateCheckbox={val => updateCheckbox(val, "8")}
              img={awaitingNormal0}
            />
            <TimeGroupBtn
              isChecked={isChecked("9")}
              updateCheckbox={val => updateCheckbox(val, "9")}
              img={awaitingNormal1}
            />
            <TimeGroupBtn
              isChecked={isChecked("10")}
              updateCheckbox={val => updateCheckbox(val, "10")}
              img={awaitingNormal2}
            />
            <TimeGroupBtn
              isChecked={isChecked("11")}
              updateCheckbox={val => updateCheckbox(val, "11")}
              img={awaitingNormal3}
            />
            <TimeGroupBtn
              isChecked={isChecked("12")}
              updateCheckbox={val => updateCheckbox(val, "12")}
              img={awaitingNormal4}
            />
            <TimeGroupBtn
              isChecked={isChecked("13")}
              updateCheckbox={val => updateCheckbox(val, "13")}
              img={awaitingNormal5}
            />
            <TimeGroupBtn
              isChecked={isChecked("14")}
              updateCheckbox={val => updateCheckbox(val, "14")}
              img={awaitingNormal6}
            />
            <TimeGroupBtn
              isChecked={isChecked("15")}
              updateCheckbox={val => updateCheckbox(val, "15")}
              img={awaitingNormal7}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ProductTypeFilters = () => {
  const { isChecked, updateCheckbox } = useCheckboxFilter("productsKind");
  return (
    <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
      <label className={styles["version-box-option"]} title="Tapicerowane">
        <input
          type="checkbox"
          name="upholstery"
          checked={isChecked("upholstery")}
          onChange={e => updateCheckbox(e.target.checked, "upholstery")}
        />
        <img src={marker1Img} alt="" />
      </label>
      <label className={styles["version-box-option"]} title="Skrzyniowe">
        <input
          type="checkbox"
          name="box"
          checked={isChecked("box")}
          onChange={e => updateCheckbox(e.target.checked, "box")}
        />
        <img src={boxImg} alt="" />
      </label>
      <label className={styles["version-box-option"]} title="Inne / mieszane">
        <input
          type="checkbox"
          name="other"
          checked={isChecked("other")}
          onChange={e => updateCheckbox(e.target.checked, "other")}
        />
        <img src={othersImg} alt="" />
      </label>
    </div>
  );
};

const PriorityFilters = () => {
  const { isChecked, updateCheckbox } = useCheckboxFilter("priority");
  return (
    <div className={cx(styles["map-points-filters"], "d-flex align-items-center flex-wrap")}>
      <PriorityBtn
        isChecked={isChecked("NORMAL")}
        updateCheckbox={val => updateCheckbox(val, "NORMAL")}
        img={normalPriority}
        name="NORMAL"
        title="Normalne"
      />
      <PriorityBtn
        isChecked={isChecked("HIGH")}
        updateCheckbox={val => updateCheckbox(val, "HIGH")}
        img={highPriority}
        name="HIGH"
        title="Pilne"
      />
      <PriorityBtn
        isChecked={isChecked("CRITICAL")}
        updateCheckbox={val => updateCheckbox(val, "CRITICAL")}
        img={criticalPriority}
        name="CRITICAL"
        title="Krytyczne"
      />
    </div>
  );
};
