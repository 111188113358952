import {
  CreateTradingDocumentPayment,
  TradingDocument,
  tradingDocumentPaymentKindToPick,
  tradingDocumentPaymentProviderToPick,
  tradingDocumentPaymentTypeToPick,
} from "api/trading-documents/models";
import styles from "./AddPaymentModal.module.css";
import cx from "classnames";
import { Formik, FormikHelpers } from "formik";
import { dateFns, getAnyErrorKey } from "utilities";
import { useSelector } from "hooks";
import { FormInput, FormSelect } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { Button } from "components/common";
import { useMutation } from "hooks/useMutation";
import { postTradingDocumentPayment } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { currenciesToPick } from "CONSTANTS";
import { validationSchema } from "./validationSchema";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export const AddPaymentModal = ({ close, tradingDocument }: Props) => {
  const me = useSelector(store => store.auth.user!);

  const initialValues: CreateTradingDocumentPayment = {
    amount: "",
    bankTransactionDate: null,
    bankTransactionNumber: "",
    commission: null,
    createdAt: dateFns.format(new Date(), "yyyy-MM-dd"),
    createdBy: me,
    currency: "PLN",
    kind: "ADVANCE",
    paymentDate: null,
    paymentNumber: "",
    provider: "",
    tradingDocument: tradingDocument.id,
    type: "CASH",
  };

  const createPaymentMutation = useMutation(
    (formData: CreateTradingDocumentPayment) => postTradingDocumentPayment(formData),
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano płatność",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: CreateTradingDocumentPayment,
    actions: FormikHelpers<CreateTradingDocumentPayment>,
  ) => {
    createPaymentMutation.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        // @ts-ignore
        actions.setErrors(error);
      },
    });
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj płatność
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={tradingDocumentPaymentKindToPick}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Typ płatności"
                  name="kind"
                  placeholder="Wybierz typ płatności"
                  selectedItem={values.kind}
                  width="full"
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className={cx(styles.inputBox, "w-100")}>
                  <FormInput label="Nr płatności" name="paymentNumber" />
                </div>
                <div className={cx(styles.inputBox, styles.datePick)}>
                  <DatePicker
                    className={styles.datePickContainer}
                    overwrites={{
                      popup: { className: styles.datePickPopUp },
                    }}
                    portalId={undefined}
                    label="Data płatności"
                    look="common"
                    onChange={date => {
                      if (!date) {
                        setFieldValue("paymentDate", null);
                      } else {
                        setFieldValue("paymentDate", dateFns.format(new Date(date), "yyyy-MM-dd"));
                      }
                    }}
                    placeholder="dd/mm/yy"
                    value={values.paymentDate || ""}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100 gap-3">
                <div className="d-flex align-items-center w-100">
                  <div className={cx(styles.inputBox, "w-100")}>
                    <FormInput label="Kwota" name="amount" type="number" />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 fw-400">
                  <div className={cx(styles.inputBox, "w-100")}>
                    <FormInput label="Prowizja" name="commission" type="number" />
                  </div>
                </div>
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={currenciesToPick.map(currency => {
                    return { id: currency.value, name: currency.label };
                  })}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Waluta"
                  name="currency"
                  placeholder="Wybierz walutę"
                  selectedItem={values.currency}
                  width="full"
                />
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={tradingDocumentPaymentTypeToPick}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Sposób"
                  name="type"
                  placeholder="Wybierz sposób płatności"
                  selectedItem={values.type}
                  width="full"
                />
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={[{ id: "", name: "nie dotyczy" }, ...tradingDocumentPaymentProviderToPick]}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Usługa"
                  name="provider"
                  placeholder="Wybierz usługę"
                  selectedItem={values.provider}
                  width="full"
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className={cx(styles.inputBox, "w-100")}>
                  <FormInput label="Nr przelewu" name="bankTransactionNumber" />
                </div>
                <div className={cx(styles.inputBox, styles.datePick)}>
                  <DatePicker
                    className={styles.datePickContainer}
                    overwrites={{
                      popup: { className: styles.datePickPopUp },
                    }}
                    portalId={undefined}
                    label="Data przelewu"
                    look="common"
                    onChange={date => {
                      if (!date) {
                        setFieldValue("bankTransactionDate", null);
                      } else {
                        setFieldValue(
                          "bankTransactionDate",
                          dateFns.format(new Date(date), "yyyy-MM-dd"),
                        );
                      }
                    }}
                    placeholder="dd/mm/yy"
                    value={values.bankTransactionDate || ""}
                  />
                </div>
              </div>
              <>
                <div className="fs-10 text-color-grey">Odpowiedzialny(/a)</div>
                <div className="d-flex align-items-center gap-2 pl-1 pb-1">
                  <AvatarOrInitials
                    avatarSrc={values.createdBy.avatar}
                    firstName={values.createdBy.firstName}
                    lastName={values.createdBy.lastName}
                    className={styles.avatar}
                  />
                  <div>
                    {values.createdBy.firstName} {values.createdBy.lastName}
                  </div>
                </div>
              </>
              <>
                <div className="fs-10 text-color-grey">Dodano(/a)</div>
                <div>{dateFns.format(new Date(values.createdAt), "dd.MM.yyyy")}</div>
              </>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="uppercase" kind="create-medium-transparent" onClick={close}>
                <div className="btnBaseMedium btnBase">Anuluj</div>
              </Button>
              <Button disabled={isSubmitting} kind="submit-medium" type="submit">
                <div className="btnBaseMedium btnBase">Dodaj{isSubmitting && "..."}</div>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
