import { UnloadingDetails } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns } from "utilities";
import styles from "../../RightPanel.module.css";
import QRCode from "qrcode.react";
import { UnloadingStart } from "pages/wms/unloadingsList/components/UnloadingStart";
import { UnloadingFinish } from "pages/wms/unloadingsList/components/UnloadingFinish";
import { useSelector, useStateModal } from "hooks";
import { useUnloadingPatchMutation, useUnloadingPatchMutationWithoutParsing } from "api/wms/hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { getParsedDuration } from "pages/wms/shared/utils/getParsedDuration";
import {
  UnloadingWorkspaceModal,
  UnloadingWorkspaceState,
} from "../unloadingWorkspaceModal/UnloadingWorkspaceModal";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { AsyncInput } from "components/utils";

interface Props {
  unloading: UnloadingDetails;
}

export const GeneralInfoSection = ({ unloading }: Props) => {
  const ramps = useSelector(store => store.partials.wmsRamps);
  const rampMutation = useUnloadingPatchMutationWithoutParsing();
  const estimatedDateOfArrivalDateMutation = useUnloadingPatchMutation();
  const estimatedDateOfArrivalTimeMutation = useUnloadingPatchMutation();
  const descriptionMutation = useUnloadingPatchMutation();
  const unloadingWorkspaceModal = useStateModal<UnloadingWorkspaceState>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <div className="d-flex align-items-start justify-content-between gap-2">
          <div>
            <InfoLabel title="rampa">
              <div>
                <Select
                  items={ramps.map(ramp => ({
                    text: ramp.name,
                    type: MenuItemType.TEXT,
                    value: ramp.id,
                  }))}
                  onChange={ramp => {
                    const foundRamp = ramps.find(_ramp => _ramp.id === ramp);
                    if (foundRamp) {
                      rampMutation.mutate({
                        id: unloading.id,
                        toUpdate: {
                          ramp: {
                            id: foundRamp.id,
                            name: foundRamp.name,
                          },
                        },
                      });
                    }
                  }}
                  selected={unloading.ramp?.id ?? ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="planowany początek rozładunku">
              <div className="d-flex align-items-center gap-2">
                <DatePicker
                  disabled={estimatedDateOfArrivalDateMutation.isLoading}
                  look="common"
                  overwrites={{
                    popup: { className: styles.datePickerPopup },
                    input: { className: styles.datePickerInput },
                    container: { className: styles.datePickerInput },
                  }}
                  value={unloading.estimatedDateOfArrivalDate || ""}
                  onChange={date => {
                    if (!date) {
                      return estimatedDateOfArrivalDateMutation.mutate({
                        id: unloading.id,
                        toUpdate: {
                          estimatedDateOfArrivalDate: null,
                        },
                      });
                    }
                    return estimatedDateOfArrivalDateMutation.mutate({
                      id: unloading.id,
                      toUpdate: {
                        estimatedDateOfArrivalDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                      },
                    });
                  }}
                />
                <TimePicker
                  disabled={estimatedDateOfArrivalTimeMutation.isLoading}
                  inProgress={estimatedDateOfArrivalTimeMutation.isLoading}
                  look="common"
                  onBlur={time =>
                    estimatedDateOfArrivalTimeMutation.mutate({
                      id: unloading.id,
                      toUpdate: { estimatedDateOfArrivalTime: time || null },
                    })
                  }
                  overwrites={{
                    container: { className: styles.formHeight },
                    input: { className: styles.formHeight },
                  }}
                  value={unloading.estimatedDateOfArrivalTime || ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="opis">
              <div>
                <AsyncInput
                  disabled={descriptionMutation.isLoading}
                  inProgress={descriptionMutation.isLoading}
                  onChange={value =>
                    descriptionMutation.mutate({
                      id: unloading.id,
                      toUpdate: { description: value },
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: { className: styles.input },
                  }}
                  placeholder="opis"
                  value={unloading.description}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="przyjazd">
              <Typography fontSize="14" fontWeight="600">
                {unloading.actualTimeOfArrival
                  ? dateFns.format(new Date(unloading.actualTimeOfArrival), "H: mm")
                  : "---"}
              </Typography>
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 mb-1">
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  start:
                </Typography>
                <UnloadingStart fontSize="14" fontWeight="500" unloading={unloading} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  zakończenie:
                </Typography>
                <UnloadingFinish fontSize="14" fontWeight="500" unloading={unloading} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  czas rozładunku:
                </Typography>
                <Typography fontSize="14" fontWeight="600">
                  {unloading.duration && Boolean(unloading.duration.length)
                    ? getParsedDuration(unloading.duration)
                    : "---"}
                </Typography>
              </div>
            </div>
            <InfoLabel title="kontrahenci">
              <Typography fontSize="14" fontWeight="700" noWrap>
                {unloading.suppliers && Boolean(unloading.suppliers.length)
                  ? unloading.suppliers.map(supplier => supplier.name).join(", ")
                  : "---"}
              </Typography>
            </InfoLabel>
            <InfoLabel className={styles.collaborators} title="osoby zaangażowane">
              <div className="d-flex flex-column gap-1">
                {unloading.collaborators ? (
                  unloading.collaborators.map(collaborator => (
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      onClick={() =>
                        unloadingWorkspaceModal.open({
                          user: collaborator.id,
                          unloading: unloading.id,
                        })
                      }
                    >
                      <Avatar size="sm" user={collaborator} theme="light" />
                      <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                        {collaborator.firstName} {collaborator.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <Typography fontSize="14" fontWeight="700">
                    ---
                  </Typography>
                )}
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex flex-column gap-1">
            <div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                QR kod przyjęcia
              </Typography>
            </div>
            <QRCode value={unloading.qrCode} />
          </div>
        </div>
      </RightPanelSection>
      {unloadingWorkspaceModal.isOpen &&
        unloadingWorkspaceModal.state?.unloading &&
        unloadingWorkspaceModal.state.user && (
          <UnloadingWorkspaceModal
            close={unloadingWorkspaceModal.close}
            state={unloadingWorkspaceModal.state}
          />
        )}
    </>
  );
};
