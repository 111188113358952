import { Route } from "api/routes/models";
import routeMarkerImg from "assets/images/mapMarkers/p29.png";
import markerImg from "assets/images/42.svg";
import { Button, useToastr } from "components/common";
import {
  AsyncInput,
  FormInput,
  Modal,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { GoogleGeocodeStatus, ToggleHookState, useGeocoder, useQueryUtils } from "hooks";
import { useState } from "react";
import cx from "classnames";
import styles from "../LeftPanel.module.css";
import immer from "immer";
import { patchDelivery, patchDeliveryAddress } from "api/deliveries/calls";
import { getAnyErrorKey } from "utilities";
import { routeKeys } from "api/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { POLAND_CENTER } from "CONSTANTS";
import { MapOverlay } from "components/common/customerAddressModal/mapOverlay";
import { graphhopper } from "api/graphhopper/graphhopper";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useRouteViewState } from "../../routeCreatorState";
import { Assign } from "utility-types";
import { getFullRouteCoords, getOrdersPositionsBasedOnGraphhopper, getPoints } from "../../utils";

interface Props {
  modal: ToggleHookState;
  order: Route["orders"][number];
  route: Route;
}

interface Values {
  city: string;
  postCode: string;
  street: string;
  point: { lng: number; lat: number } | null;
}

export const OrderDeliveryUpdateModal = ({ modal, order, route }: Props) => {
  const toastr = useToastr();
  const [geolocalizationError, setGeolocalizationError] = useState(false);
  const [isLocalizing, setLocalizing] = useState(false);
  const { isOpen, close } = modal;
  const { handleMutate } = useQueryUtils();
  const geocoder = useGeocoder();
  const actions = useRouteViewState("slave", state => state.actions);

  const reset = () => {
    close();
  };

  const updateClient = (toUpdate: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    companyName?: string;
  }) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(el => el.id === order.id);
      assertIsDefined(orderToUpdate);
      Object.assign(orderToUpdate.client, toUpdate);
      return draft;
    });
  };

  const updateDelivery = (toUpdate: Partial<Route["orders"][number]["delivery"]>) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(el => el.id === order.id);
      assertIsDefined(orderToUpdate);
      Object.assign(orderToUpdate.delivery, toUpdate);
      return draft;
    });
  };

  const updateMutation = useRoutePutMutation();

  const handleUpdateRoute = async (values: Values) => {
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    actions.openLoader("Trwa zmiana punktu zamówienia");

    const newOrdersPositions = immer(route.ordersPositions, draft => {
      const orderToUpdate = draft.find(el => Number(el.id) === order.id);
      assertIsDefined(orderToUpdate);
      orderToUpdate.meta.point = values.point!;
    });

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      updateDelivery(values as Assign<Values, { point: { lat: number; lng: number } }>);
      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          ordersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić punktu zamówienia",
        text: "",
      });
    }
  };

  async function handleUpdateDelivery(
    toUpdate: {
      firstName?: string;
      lastName?: string;
      email?: string;
      phone?: string;
      companyName?: string;
    },
    helpers: StatusHandlerHelpers,
  ) {
    helpers.startFetching();
    const [payload, error] = await patchDelivery(order.delivery.id, toUpdate);
    if (payload) {
      helpers.stopFetching();

      updateClient(toUpdate);
    } else if (error) {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  const handleSubmitForm = async (values: Values, actions: FormikHelpers<Values>) => {
    const [payload, error] = await patchDeliveryAddress(order.delivery.id, values);

    if (payload) {
      setGeolocalizationError(false);
      actions.setTouched({ city: false, street: false, point: false, postCode: false });
      handleUpdateRoute({ ...values, point: payload.geolocation.point });
      actions.setFieldValue("point", null);
    } else if (error) {
      setGeolocalizationError(true);
      actions.setErrors(error);
    }
  };

  //TOODO zerknij jeszcze raz na geolokalizacje
  const geocodeAddress = (
    address: string,
    setFieldValue: (field: string, value: any) => void,
    setLocalizing: (status: boolean) => void,
  ) => {
    setLocalizing(true);

    geocoder.geocode({ address }, (payload: any, status: GoogleGeocodeStatus) => {
      setLocalizing(false);
      if (status === "OK") {
        const location = payload?.[0]?.geometry.location;
        const point = { lat: location.lat(), lng: location.lng() };
        setFieldValue("point", point);
      } else {
        toastr.open({
          type: "warning",
          title: "Niepoprawna lokalizacja",
          text: "Nie możemy znaleźć podanego adresu.",
        });
      }
    });
  };

  const initialValues: Values = {
    street: order.delivery.street,
    postCode: order.delivery.postCode,
    city: order.delivery.city,
    point: null,
  };

  return (
    <Modal
      isOpen={isOpen}
      close={reset}
      overrides={{
        container: {
          className: cx(styles.orderUpdateModalContainer, {
            [styles.orderUpdateModalMargin]: geolocalizationError,
          }),
        },
      }}
    >
      <div>
        <div className="font-bold fs-18 mb-4">Edytuj dane klienta</div>
        <div className="fs-14 font-bold text-color-grey mb-2">Zamówienie</div>
        {geolocalizationError ? (
          <div>
            <div className="fs-14 font-bold ">{order.signature}</div>
            {order.customer && !order.customer.hasDropShipping ? (
              <div className="fs-14 font-bold ">{order.client.companyName}</div>
            ) : (
              <div className="d-flex align-item-center">
                <div className="fs-14 font-bold mr-1">{order.client.firstName}</div>
                <div className="fs-14 font-bold ">{order.client.lastName}</div>
              </div>
            )}
            <div className="fs-14 font-bold ">tel. {order.client.phone}</div>
          </div>
        ) : (
          <div className="fs-14 font-bold mb-3">{order.signature}</div>
        )}
        <div className="fs-14 font-bold text-color-grey mb-1">Dane dostawy</div>
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit} className={cx({ "was-validated": !isValid })}>
              {geolocalizationError && (
                <div className="flex-1 position-relative">
                  <GoogleMap
                    mapContainerStyle={{
                      height: "50vh",
                      width: "100%",
                    }}
                    onClick={(mapEvent: any) => {
                      setFieldValue("point", {
                        lat: mapEvent.latLng.lat(),
                        lng: mapEvent.latLng.lng(),
                      });
                    }}
                    zoom={6}
                    options={{
                      mapTypeControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      draggableCursor: "default",
                    }}
                    center={POLAND_CENTER}
                  >
                    {values.point && (
                      <Marker
                        position={values.point}
                        onClick={() => setFieldValue("point", null)}
                        icon={routeMarkerImg}
                      ></Marker>
                    )}
                  </GoogleMap>
                  <Button
                    disabled={!values.street || !values.postCode || !values.city || isLocalizing}
                    className={styles.localizeBtn}
                    kind="primary"
                    onClick={() =>
                      geocodeAddress(
                        `${values.street} ${values.postCode} ${values.city}`,
                        setFieldValue,
                        setLocalizing,
                      )
                    }
                  >
                    Lokalizuj adres
                  </Button>
                  <MapOverlay />
                </div>
              )}
              <div className="my-3">
                <FormInput
                  name="street"
                  label="Adres"
                  onChange={() => setFieldTouched("street", true)}
                />
              </div>
              <div className="d-flex mt-3 mb-0">
                <div className="w-30 mr-2">
                  <FormInput
                    name="postCode"
                    label="Kod pocztowy"
                    className="mb-3"
                    onChange={() => setFieldTouched("postCode", true)}
                  />
                </div>
                <div className="w-70">
                  <FormInput
                    name="city"
                    label="Miejscowość"
                    className="mb-3"
                    onChange={() => setFieldTouched("city", true)}
                  />
                </div>
              </div>
              {geolocalizationError ? (
                <div className="mt-3">
                  <Button
                    kind="primary"
                    size="medium"
                    onClick={submitForm}
                    disabled={!values.point}
                  >
                    Zapisz
                  </Button>
                </div>
              ) : (
                <div className="mb-4">
                  <Button
                    kind="secondary-stroke"
                    size="small"
                    type="submit"
                    disabled={isSubmitting || !Object.values(touched).some(el => el)}
                    className="d-flex"
                  >
                    <img src={markerImg} alt="" className="mr-1" />
                    Geolokalizuj adres
                  </Button>
                  {Object.values(touched).some(el => el) && (
                    <span className="warning">
                      Aby upewnić się, że zmieniony adres jest poprawny kliknij przycisk powyżej
                    </span>
                  )}
                </div>
              )}
            </form>
          )}
        </Formik>

        {!geolocalizationError && (
          <div>
            {" "}
            {order.customer && !order.customer.hasDropShipping ? (
              <div className="my-3">
                <StatusHandler>
                  {helpers => (
                    <AsyncInput
                      look="common"
                      inProgress={helpers.isFetching}
                      disabled={false}
                      onChange={value => handleUpdateDelivery({ companyName: value }, helpers)}
                      value={order.client.companyName}
                      label="Nazwa firmy"
                    />
                  )}
                </StatusHandler>
              </div>
            ) : (
              <div>
                <div className="my-3">
                  <StatusHandler>
                    {helpers => (
                      <AsyncInput
                        look="common"
                        inProgress={helpers.isFetching}
                        disabled={false}
                        onChange={value => handleUpdateDelivery({ firstName: value }, helpers)}
                        value={order.client.firstName}
                        label="Imię"
                      />
                    )}
                  </StatusHandler>
                </div>
                <div className="my-3">
                  <StatusHandler>
                    {helpers => (
                      <AsyncInput
                        look="common"
                        disabled={false}
                        onChange={value => handleUpdateDelivery({ lastName: value }, helpers)}
                        value={order.client.lastName}
                        inProgress={helpers.isFetching}
                        label="Nazwisko"
                      />
                    )}
                  </StatusHandler>
                </div>
              </div>
            )}
            <div className="my-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    disabled={false}
                    onChange={value => handleUpdateDelivery({ phone: value }, helpers)}
                    value={order.client.phone}
                    label="Nr telefonu"
                    inProgress={helpers.isFetching}
                  />
                )}
              </StatusHandler>
            </div>
            <div className="my-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    disabled={false}
                    onChange={value => handleUpdateDelivery({ email: value }, helpers)}
                    value={order.client.email}
                    label="Email"
                    inProgress={helpers.isFetching}
                  />
                )}
              </StatusHandler>
            </div>
          </div>
        )}
        {!geolocalizationError && (
          <Button
            kind="primary"
            size="medium"
            onClick={() => {
              reset();
            }}
          >
            Gotowe
          </Button>
        )}
      </div>
    </Modal>
  );
};
