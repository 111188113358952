import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import cx from "classnames";
import { ManufacturingStage } from "api/manufacturingNew/models";
import redo from "assets/images/redo.svg";
import { useConfirmModal } from "hooks";
import { useQueryClient } from "react-query";
import { useMutation } from "hooks/useMutation";
import { deleteManufacturingStage } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  manufacturingStage: ManufacturingStage;
}

export const PanelHeader = ({ close, isScrolledOver, manufacturingStage }: Props) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();

  const deleteMutation = useMutation(deleteManufacturingStage, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingStages());
      close();
    },
  });
  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
      })}
    >
      <div className="d-flex w-100 align-items-center">
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{manufacturingStage.name}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button kind="create">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img src={redo} alt="Przywróć" />
              Przywróć ustawienia domyślne
            </div>
          </Button>
          <span className="divider line-divider" />
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="blackST" />}
            menuItems={[
              {
                type: MenuItemType.TEXT,
                options: {
                  color: "red100",
                  iconStart: MdiDelete,
                },
                onClick: () =>
                  confirmModal.open({
                    text: `Czy na pewno chcesz usunąć etap ${manufacturingStage.name}?`,
                    confirmText: "Usuń",
                    callback: () => deleteMutation.mutate(manufacturingStage.id),
                  }),
                text: "Usuń etap",
              },
            ]}
          />
          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </div>
    </RightPanelHeader>
  );
};
