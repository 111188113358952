import { memo } from "react";
import { useSelector } from "hooks";
import styles from "./UserAvatar.module.css";
import cx from "classnames";
import avatarImg from "assets/images/3.svg";
import { InitialsAvatar } from "./InitialsAvatar";

interface Props {
  id?: number;
  avatarSrc?: string | null;
  title?: string;
  type?: "user" | "driver";
  className?: string;
}

const UserAvatarWithInitials = ({ id, title, type = "user", className }: Props) => {
  const user = useSelector(state => state.partials.users.find(user => user.id === id));
  const driver = useSelector(store => store.partials.drivers.find(driver => driver.id === id));

  if ((type === "user" && !user) || (type === "driver" && !driver)) {
    return (
      <div className={cx(styles.avatar, className)} title={title || ""}>
        <img src={avatarImg} alt="Zdjęcie użytkownika" />
      </div>
    );
  }

  if (type === "user" && user) {
    return (
      <InitialsAvatar
        firstName={user.firstName}
        lastName={user.lastName}
        className={className}
        title={title}
        style={{ background: user.initialsBackgroundColor }}
      />
    );
  }

  return (
    <InitialsAvatar
      firstName={driver!.firstName}
      lastName={driver!.lastName}
      className={className}
      title={title}
      style={{ background: "#00cf91" }}
    />
  );
};

export const UserAvatar = memo(({ id, avatarSrc, title, type = "user", className }: Props) => {
  if (avatarSrc) {
    return (
      <div className={cx(styles.avatar, className)} title={title || ""}>
        <img src={avatarSrc} alt="Zdjęcie użytkownika" />
      </div>
    );
  }
  if (!avatarSrc && !id) {
    return (
      <div className={cx(styles.avatar, className)} title={title || ""}>
        <img src={avatarImg} alt="Zdjęcie użytkownika" />
      </div>
    );
  }
  return <UserAvatarWithInitials id={id} title={title} type={type} className={className} />;
});

export const AvatarOrInitials = memo(
  ({
    avatarSrc,
    firstName,
    lastName,
    className,
  }: { firstName: string; lastName: string } & Pick<Props, "avatarSrc" | "className">) => {
    if (avatarSrc) {
      return (
        <div className={cx(styles.avatar, className)} title={`${firstName} ${lastName}`}>
          <img src={avatarSrc} alt="Zdjęcie użytkownika" />
        </div>
      );
    }
    return (
      <InitialsAvatar firstName={firstName || ""} lastName={lastName || ""} className={className} />
    );
  },
);
