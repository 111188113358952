import calculateIcon from "assets/images/calculatePink.svg";
import financeIcon from "assets/images/Invoicing.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { bankAccounts } from "./routes/bankAccounts";
import { dashboard } from "./routes/dashboard";
import { salesInvoices } from "./routes/salesInvoices";
import { purchaseInvoices } from "./routes/purchaseInvoices";
import { correctionInvoices } from "./routes/correctionInvoices";
import { receipts } from "./routes/receipts";
import favicon from "assets/images/favicons/fv_finances.svg";
import { warehouseFinancialState } from "./routes/warehouseFinancialState";
import { myInvoicesToConfirm } from "./routes/myInvoicesToConfirm";
import { soldPackages } from "./routes/soldPackages";
import { soldIndexes } from "./routes/soldIndexes";

export const finances: ModuleNavigation = {
  favicon,
  labelBackground: "#b65eff",
  label: "Finanse",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: financeIcon,
  name: "FINANCES",
  url: "/finances",
  navigationSections: [
    {
      subTitle: "Dokumenty handlowe",
      navigationList: [
        dashboard,
        salesInvoices,
        correctionInvoices,
        receipts,
        purchaseInvoices,
        myInvoicesToConfirm,
        bankAccounts,
      ],
    },
    {
      subTitle: "Dokumenty finansowe",
      navigationList: [warehouseFinancialState],
    },
    {
      subTitle: "Zestawienia",
      navigationList: [soldPackages, soldIndexes],
    },
  ],
};
