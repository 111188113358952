import { PreviewCorrectionDocument } from "api/trading-documents/models";
import { pluralize } from "utilities";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import { WholeAmountToPay } from "./WholeAmountToPay";

interface Props {
  preview: PreviewCorrectionDocument;
  values: CreateCorrectionValues;
}

export const AdditionalPaymentInfoSection = ({ preview, values }: Props) => {
  return (
    <div className="d-flex justify-content-between pt-3">
      <div className="pt-1">
        <div>
          <div className="d-flex align-items-center gap-3 pb-2">
            <div>
              <div className="body-12 fw-700 text-black-6">Forma płatności</div>
              <div>{preview.expectedPaymentForm ? preview.expectedPaymentForm : "---"}</div>
            </div>
            <div>
              <div className="body-12 fw-700 text-black-6">Termin płatności</div>
              {preview.basePaymentDeadlineDelta ? (
                <div>
                  {preview.basePaymentDeadlineDelta}{" "}
                  {pluralize.pl(preview.basePaymentDeadlineDelta, {
                    singular: "dzień",
                    plural: "dni",
                    other: "dni",
                  })}
                </div>
              ) : (
                "---"
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="d-flex align-items-center justify-content-end body-14-500">
            razem do zapłaty:
          </div>

          <div className="d-flex align-items-center justify-content-end">
            <strong className="body-18 fw-800">
              <WholeAmountToPay
                currency={preview.currency}
                fieldName="amountWithTax"
                values={values}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="d-flex align-items-center justify-content-end body-12">
            pozostaje do zapłaty:
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <strong className="body-14 fw-800">
              <WholeAmountToPay
                currency={preview.currency}
                fieldName="amountWithTax"
                values={values}
              />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};
