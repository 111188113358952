import { assertIsDefined } from "utilities/assertIsDefined";
import { tradingDocumentsKeys } from "./keys";
import { tradingDocumentsApi } from "./calls";
import { useMutation } from "hooks/useMutation";
import { useTradingDocument } from "./hooks";
import { useState } from "react";
import { UUID } from "api/types";

const useSetVatRate = (tradingDocumentId: UUID) => {
  const [showInput, setShowInput] = useState(false);
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  const setVatRateMutation = useMutation(
    tradingDocumentsApi.patchTradingDocumentItemsVatRate,
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        return handleMutate(draft => {
          draft.isManagedManually = true;
          draft.items = draft.items.map(item => {
            if (item) {
              item.tradingDocumentItems = item.tradingDocumentItems.map(_tradingDocumentItem => {
                if (_tradingDocumentItem.id === args.tradingDocumentItemsIds[0]) {
                  return {
                    ..._tradingDocumentItem,
                    vatRate: args.vatRate,
                  };
                }
                return _tradingDocumentItem;
              });
            }
            return item;
          });
        });
      },
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zmodyfikowano stawkę VAT",
        });
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );
      },
      onError: (error, _, rollback) => {
        assertIsDefined(rollback);
        rollback(error);
      },
      onSettled: () => {
        setShowInput(false);
      },
    }),
  );

  return {
    setVatRateMutation,
    setShowInput,
    showInput,
  };
};

export const tradingDocumentsActions = {
  useSetVatRate,
};
