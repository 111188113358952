import { DialogOverlay, DialogContent } from "@reach/dialog";
import { animated, useTransition } from "react-spring";
import Media from "react-media";
import closeIcon from "assets/images/close.svg";
import styles from "./CustomModal.module.css";
import cx from "classnames";
import { Button } from "components/common";

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  isShadow?: boolean;
  close: () => void;
  showCloseBtn?: boolean;
  animated?: boolean;
  topPosition?: number;
  leftPosition?: number;
  title?: string;
  titleContent?: React.ReactNode;
  size?:
    | {
        all?: { width: string };
        xlg?: { width: string };
        lg?: { width: string };
        md?: { width: string };
        sm?: { width: string };
      }
    | "auto";
  overrides?: {
    container?: { className: string };
    closeBtn?: { className: string };
    title?: { className: string };
  };
}

const mediaQueries = {
  sm: "(max-width: 599px)",
  md: "(min-width: 600px) and (max-width: 1199px)",
  lg: "(min-width: 1200px) and (max-width: 1399px)",
  xlg: "(min-width: 1400px)",
};

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

export function CustomModal({
  isOpen,
  isShadow = true,
  close,
  children,
  size,
  title = "",
  titleContent,
  topPosition,
  leftPosition,
  showCloseBtn = true,
  overrides = {},
  animated: isAnimated = true,
}: Props) {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: 10 },
    config: { duration: isAnimated ? 150 : 0 },
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props: animationStyles }: any) =>
          item && (
            <AnimatedDialogOverlay
              className={cx(styles.overlay, {
                [styles.noBackground]: !isShadow,
              })}
              style={{
                opacity: animationStyles.opacity,
                zIndex: 21, // incremented from 6 because Autocomplete item was above,
                display: size === "auto" ? "flex" : undefined,
              }}
              onDismiss={close}
              key={key}
            >
              <Media queries={mediaQueries}>
                {matches => (
                  <AnimatedDialogContent
                    aria-label="Wybieranie lokalizacji zamówienia"
                    style={{
                      ...(size === "auto" ? { margin: "auto" } : {}),
                      transform: animationStyles.top.interpolate(
                        (value: number) => `translate3d(0px, ${value}px, 0px)`,
                      ),
                      padding: 0,
                      top: topPosition !== undefined ? topPosition : "",
                      left: leftPosition !== undefined ? leftPosition : "",
                    }}
                    className={overrides.container?.className}
                  >
                    {(title.length > 0 || showCloseBtn) && !titleContent && (
                      <div className="d-flex justify-content-between p-3">
                        <h2 className={overrides.title?.className}>{title}</h2>
                        <Button
                          className={overrides.closeBtn?.className}
                          kind="create-transparent"
                          onClick={close}
                          style={{ display: showCloseBtn ? "block" : "none" }}
                        >
                          <div className="btnBase btnBaseSmall">
                            <img
                              src={closeIcon}
                              alt="zamknij"
                              style={{ height: "16px", width: "16px" }}
                            />
                          </div>
                        </Button>
                      </div>
                    )}
                    {titleContent && (
                      <div className="d-flex align-items-center justify-content-between gap-1 px-3 pb-2 pt-3 borderBottom">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          {titleContent}
                          <Button
                            className={overrides.closeBtn?.className}
                            kind="create-transparent"
                            onClick={close}
                            style={{ display: showCloseBtn ? "block" : "none" }}
                          >
                            <div className="btnBase btnBaseSmall">
                              <img
                                src={closeIcon}
                                alt="zamknij"
                                style={{ height: "16px", width: "16px" }}
                              />
                            </div>
                          </Button>
                        </div>
                      </div>
                    )}
                    {children}
                  </AnimatedDialogContent>
                )}
              </Media>
            </AnimatedDialogOverlay>
          ),
      )}
    </div>
  );
}
