import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { UUID } from "api/types";

export const getTradingDocumentRedirectUrl = (
  type: TradingDocumentType,
  invoiceType: InvoiceType,
  id: UUID,
): string => {
  const tab = (() => {
    if (type === "RECEIPT") return "receipts";
    if (type === "INVOICE" && invoiceType === "SALES") return "sales";
    if (type === "INVOICE" && invoiceType === "PURCHASE") return "purchases";
    if (type === "INVOICE" && invoiceType === "PREPAYMENT") return "prepayments";
    if (type === "INVOICE" && invoiceType === "FINAL") return "finals";
    return "corrections";
  })();
  return `/finances/${tab}/list/all?panelId=${id}`;
};
