import {
  getCustomerOrders,
  getDailyStatistics,
  getOrder,
  getOrderB2b,
  getOrderB2bQuery,
  getOrderIndexPopularity,
  getOrderQuery,
  getOrderForRoute_query,
  getOrders,
  getOrdersLight,
  getOrdersQuery,
  patchOrder,
  patchOrderProduct,
  getOrderNearestRoutes,
  getOrderAdditionalData,
  getJoinedOrder,
  getJoinedOrderQuery,
  getUpholsteries,
  getComplaintParent,
  patchOrderProductPackage,
  getOrderSmsMessages,
  getOrderTransactions,
  getOrderDeliveryInfo,
  getCustomerOrder,
  getListings,
  getLoadingStatus,
  getCustomerOrdersQuery,
  getItemsWithSingleQuantities,
  getSingleItemQuantities,
  getSingleItemQuantitiesDetails,
  getSingleItemQuantitiesForRoute,
} from "api/orders/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { createAsyncUpdateConnector } from "utilities";

export const useOrder = createPrimitiveHook(getOrder);
export const useOrderB2b = createPrimitiveHook(getOrderB2b);
export const useOrders = createPrimitivePaginatedHook(getOrders);
export const useUpdateOrder = createAsyncUpdateConnector(patchOrder);
export const useUpdateOrderProduct = createAsyncUpdateConnector(patchOrderProduct);
export const useUpdateOrderProductPackage = createAsyncUpdateConnector(patchOrderProductPackage);
export const useOrdersLight = createPrimitivePaginatedHook(getOrdersLight);
export const useCustomerOrders = createPrimitivePaginatedHook(getCustomerOrders);
export const useCustomerOrder = createPrimitiveHook(getCustomerOrder);

export const useDailyStatistics = createPrimitiveHook(getDailyStatistics);

export const useOrderIndexPopularity = createPrimitiveHook(getOrderIndexPopularity);

export const useOrderNearestRoutes = createPrimitiveHook(getOrderNearestRoutes);
export const useJoinedOrder = createPrimitiveHook(getJoinedOrder);

export const useProductionOrders = createPrimitivePaginatedHook(getUpholsteries);

// react-query
export const useOrdersQuery = createPaginatedApiQuery(getOrdersQuery);
export const useCustomerOrdersQuery = createPaginatedApiQuery(getCustomerOrdersQuery);
export const useOrderForRoute = createApiQuery(getOrderForRoute_query);
export const useOrderTransactions = createApiQuery(getOrderTransactions);
export const useOrderDeliveryInfo = createApiQuery(getOrderDeliveryInfo);

export const useOrderQuery = createApiQuery(getOrderQuery);
export const useItemsWithSingleQuantity = createPaginatedApiQuery(getItemsWithSingleQuantities);
export const useSingleItemQuantities = createPaginatedApiQuery(getSingleItemQuantities);
export const useSingleItemQuantitiesDetails = createPaginatedApiQuery(
  getSingleItemQuantitiesDetails,
);
export const useSingleItemQuantitiesForRoute = createPaginatedApiQuery(
  getSingleItemQuantitiesForRoute,
);

export const useAdditionalDataQuery = createApiQuery(getOrderAdditionalData);
export const useOrderB2bQuery = createApiQuery(getOrderB2bQuery);
export const useJoinedOrderQuery = createApiQuery(getJoinedOrderQuery);
export const useOrderMessages = createApiQuery(getOrderSmsMessages);
export const useCompareListingsQuery = createApiQuery(getListings);
export const useComplaintParent = createApiQuery(getComplaintParent);
export const useLoadingStatusQuery = createApiQuery(getLoadingStatus);
