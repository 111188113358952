import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import infoSuccessIcon from "assets/images/infoSuccess.svg";
import { AddOrdersToUnloadingResponse } from "api/wms/models";
import { cx, pluralize } from "utilities";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import styles from "./AddOrdersBySignaturesModal.module.css";

interface Props {
  close: () => void;
  state: AddOrdersToUnloadingResponse;
}

export const ResponseModal = ({ close, state }: Props) => {
  const hasInvalidOrders = (): boolean => {
    return (
      Boolean(state.inDifferentUnloading.length) ||
      Boolean(state.missingIndexes.length) ||
      Boolean(state.missingOrders.length) ||
      Boolean(state.missingPackages.length)
    );
  };

  return (
    <Modal close={close} isOpen removeHeader width={430}>
      <>
        <div className={cx("d-flex justify-content-between p-3", styles.successTitle)}>
          <div className="d-flex align-items-center">
            <div>
              <img alt="" src={infoSuccessIcon} />
            </div>
            <Typography color="neutralWhite100" fontSize="20" fontWeight="700">
              Dodano zamówienia do rozładunku
            </Typography>
          </div>
          <IconButton
            icon={<MdiClose color="neutralWhite88" size="16" />}
            onClick={close}
            variant="blackT"
          />
        </div>
        <div className="d-flex flex-column p-3 gap-2">
          {Boolean(state.addedToUnloading.length) && (
            <div>
              <Typography color="success500" fontSize="16" fontWeight="700">
                Dodano {state.addedToUnloading.length}{" "}
                {pluralize.pl(state.addedToUnloading.length, {
                  singular: "zamówienie",
                  plural: "zamówienia",
                  other: "zamówień",
                })}
                .
              </Typography>
            </div>
          )}
          {hasInvalidOrders() && (
            <div>
              <Typography color="warning500" fontSize="18" fontWeight="700">
                To są numery, których nie dodano:
              </Typography>
              <div className="d-flex flex-column gap-2 py-3">
                {Boolean(state.inDifferentUnloading.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Już w innym rozładunku::
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.inDifferentUnloading.map(order => order.id).join(", ")}
                    </Typography>
                  </div>
                )}
                {Boolean(state.missingIndexes.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak indeksów w zamówieniu:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingIndexes.join(", ")}
                    </Typography>
                  </div>
                )}
                {Boolean(state.missingOrders.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak zamówień:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingOrders.join(", ")}
                    </Typography>
                  </div>
                )}
                {Boolean(state.missingPackages.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak paczek w zamówieniu:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingPackages.join(", ")}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          <div>
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Zamknij
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
