import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { countryCodesDict } from "CONSTANTS";
import { dateFns } from "utilities";

interface Props {
  correctionInvoice: TradingDocument;
}

export const AmountSection = ({ correctionInvoice }: Props) => {
  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        {Boolean(correctionInvoice.countryCode.length) ? (
          <img alt="Flaga kraju" src={countryCodesDict[correctionInvoice.countryCode]} />
        ) : (
          <Typography fontSize="14" fontWeight="700">
            ---
          </Typography>
        )}
      </InfoLabel>
      <InfoLabel title="wartość netto">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={correctionInvoice.amountSummary.totalWithoutTax}
        />
        <div className="body-14-600 text-color-grey">{correctionInvoice.currency}</div>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={correctionInvoice.amountSummary.totalTax}
        />
        <div className="body-14-600 text-color-grey">{correctionInvoice.currency}</div>
      </InfoLabel>
      <InfoLabel title="razem do zapłaty">
        <FinanceAmountFormat
          decimalClassName="body-18"
          integerClassName="body-18"
          value={correctionInvoice.amountSummary.totalWithTax}
        />
        <div className="body-16 text-color-grey">{correctionInvoice.currency}</div>
      </InfoLabel>
      <InfoLabel title="waluta">
        <div className="body-14-500">{correctionInvoice.currency}</div>
      </InfoLabel>
      <InfoLabel title="Kurs wymiany">
        {correctionInvoice.lastValidExchangeRate ? (
          <div className="body-14-500">{correctionInvoice.lastValidExchangeRate}</div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
      <InfoLabel title="Data wymiany">
        {correctionInvoice.lastValidExchangeRateDate ? (
          <div className="body-14-500">{correctionInvoice.lastValidExchangeRateDate}</div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
      <InfoLabel title="rejestr VAT">
        {correctionInvoice.vatTaxObligationDate ? (
          <div className="body-14-500">
            {dateFns.format(new Date(correctionInvoice.vatTaxObligationDate), "dd.MM.yyyy")}
          </div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        {correctionInvoice.pitTaxObligationDate ? (
          <div className="body-14-500">
            {dateFns.format(new Date(correctionInvoice.pitTaxObligationDate), "dd.MM.yyyy")}
          </div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
      <InfoLabel
        title={`${correctionInvoice.sellerBankName ? correctionInvoice.sellerBankName : "bank"} [${
          correctionInvoice.currency ? correctionInvoice.currency : "--"
        }]`}
      >
        {correctionInvoice.sellerBankAccountNumber ? (
          <div>****&nbsp;{correctionInvoice.sellerBankAccountNumber.slice(-4)}</div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
