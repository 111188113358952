import styles from "../../../ProductionPlanDetails.module.css";
import shelfStyles from "../../Shelf.module.css";
import localStyles from "./SourcesBar.module.css";
import cx from "classnames";
import { ProductionPlan, SelectedSource } from "api/new-production-plans/models";
import { MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT } from "CONSTANTS";
import { toggleSelectedSources } from "pages/productionPlans/productionPlanDetails/utils/toggleSelectedSources";
import { isSourceSelected } from "pages/productionPlans/productionPlanDetails/utils/isSourceSelected";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPanelOpen } from "components/miloDesignSystem/atoms/icons/MdiPanelOpen";
import { MdiPanelClose } from "components/miloDesignSystem/atoms/icons/MdiPanelClose";

interface Props {
  currentShelfHeight: number;
  selectedSources: SelectedSource[];
  setSelectedSources: React.Dispatch<React.SetStateAction<SelectedSource[]>>;
  slotsSummary: ProductionPlan["slotsSummary"];
  sourcesSummary: ProductionPlan["sourcesSummary"];
  toggleShelf: () => void;
}

const maxSourcesLength = 14;

export const SourcesBar = ({
  currentShelfHeight,
  selectedSources,
  setSelectedSources,
  slotsSummary,
  sourcesSummary,
  toggleShelf,
}: Props) => {
  return (
    <div className={cx(shelfStyles.shelfHeader, "px-3 py-1")}>
      <div className={localStyles.slotsCounter}>
        <span className={localStyles.slotsCounterLabel}>Wypełnione sloty</span>
        <div>
          <strong>{slotsSummary.taken}</strong>
          <span>/{slotsSummary.total}</span>
        </div>
      </div>
      <div className="line-divider new-divider" />
      <div className={cx(localStyles.sourcesContainer, "d-flex position-relative gap-1")}>
        {sourcesSummary.slice(0, maxSourcesLength).map(source => (
          <div
            key={source.id}
            className={cx(localStyles.sourceGroup, localStyles.sourceGroupL)}
            onClick={() => toggleSelectedSources(source.id, setSelectedSources, source.signature)}
            style={{
              borderColor: isSourceSelected(source.id, selectedSources, source.signature)
                ? `${source.color}`
                : "rgba(0, 0, 0, 0.15)",
            }}
          >
            <span className={localStyles.color} style={{ background: source.color }} />
            {source.signature}
          </div>
        ))}
        {sourcesSummary.length > maxSourcesLength && (
          <div className={styles.moreDataCounter}>+{sourcesSummary.length - maxSourcesLength}</div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <div className="position-relative">
          <Tooltip
            title={
              currentShelfHeight > MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT
                ? "Zwiń przybornik"
                : "Otwórz przybornik"
            }
          >
            <IconButton
              icon={
                currentShelfHeight > MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT
                  ? MdiPanelClose
                  : MdiPanelOpen
              }
              onClick={toggleShelf}
              variant="blackT"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
