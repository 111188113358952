import {
  getIndexes,
  getIndex,
  getPackages,
  getPackage,
  getEntries,
  getPackageLocations,
  getPackageIndexes,
  getEntry,
  getEntryPackages,
  getPackagesFromSource,
  getWhEntriesOrders,
  getWhEntriesPackages,
  getWhEntryReturnedPackages,
  getPackageDemandsForItem,
  getWarehouses,
  getWarehouse,
  getRow,
  getRows,
  getHall,
  getHalls,
  getLevel,
  getLevels,
  getColumns,
  getColumn,
  getCell,
  getCells,
} from "api/warehouse/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";

export const useIndexes = createPrimitivePaginatedHook(getIndexes);
export const useIndex = createPrimitiveHook(getIndex);

export const usePackages = createPrimitivePaginatedHook(getPackages);
export const usePackage = createPrimitiveHook(getPackage);
export const usePackageLocations = createPrimitiveHook(getPackageLocations);
export const usePackageIndexes = createPrimitiveHook(getPackageIndexes);

export const useEntries = createPrimitivePaginatedHook(getEntries);
export const useEntry = createPrimitiveHook(getEntry);
export const useEntryPackages = createPrimitiveHook(getEntryPackages);

export const usePackageDemandsForItem = createApiQuery(getPackageDemandsForItem);
export const usePackagesFromSource = createApiQuery(getPackagesFromSource);
export const useWhEntriesOrders = createApiQuery(getWhEntriesOrders);
export const useWhEntryOrderPackages = createApiQuery(getWhEntriesPackages);
export const useWhEntryReturnedPackages = createApiQuery(getWhEntryReturnedPackages);
export const useWarehouses = createPaginatedApiQuery(getWarehouses);
export const useWarehouse = createApiQuery(getWarehouse);

export const useRows = createPaginatedApiQuery(getRows);
export const useRow = createApiQuery(getRow);

export const useHalls = createPaginatedApiQuery(getHalls);
export const useHall = createApiQuery(getHall);

export const useLevels = createPaginatedApiQuery(getLevels);
export const useLevel = createApiQuery(getLevel);

export const useColumns = createPaginatedApiQuery(getColumns);
export const useColumn = createApiQuery(getColumn);

export const useCells = createPaginatedApiQuery(getCells);
export const useCell = createApiQuery(getCell);
