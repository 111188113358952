import { PopupMenu } from "components/common/popupMenu";
import popUpStyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import editIcon from "assets/images/edit.svg";
import eyeIcon from "assets/images/170.svg";
import requestIcon from "assets/images/request.svg";
import binIcon from "assets/images/delete_red.svg";
import styles from "../../RightPanel.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Route } from "api/routes/models";
import { useToggle } from "hooks";
import { IndexFulfillmentModal } from "components/common/indexFulfillmentModal";
import { useDeleteRoute } from "pages/logistics/routesList/hooks/useDeleteRoute";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";

interface Props {
  route: Route;
}

export const MoreOptionsPopUp = ({ route }: Props) => {
  const indexFulfillmentModal = useToggle();
  const deleteRoute = useDeleteRoute({ route });

  return (
    <div className="position-relative">
      <PopupMenu
        customButton={
          <Tooltip title="Więcej...">
            <IconButton
              icon={MdiMoreHoriz}
              variant="blackT"
              data-for="route-more-options"
              data-event="click"
              data-event-off="mouseleave"
              data-tip="2"
            />
          </Tooltip>
        }
        customStyles={popUpStyles}
        dataFor="route-more-options"
      >
        <div className={styles.moreRouteOptionsList}>
          <div className={cx("px-3 py-2", styles.moreRouteOptionRow)}>
            <Link
              className="d-flex align-items-center justify-content-between gap-2"
              to={
                route.status === "SETTLED"
                  ? `/routes/creator/${route.id}/archive`
                  : `/routes/creator/${route.id}`
              }
            >
              <div className="d-flex align-items-center gap-2">
                <img alt="mapa" src={route.status === "SETTLED" ? eyeIcon : editIcon} />
                <div className="body-14-600">Edytuj</div>
              </div>
            </Link>
          </div>

          <hr style={{ margin: "5px 0" }} />
          <div
            className={cx("px-3 py-2", styles.moreRouteOptionRow)}
            onClick={indexFulfillmentModal.open}
          >
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex align-items-center gap-2">
                <img alt="zapotrzebowanie" src={requestIcon} />
                <div className="body-14-600">Pokaż zapotrzebowanie</div>
              </div>
            </div>
          </div>

          <hr style={{ margin: "5px 0" }} />
          <button
            className={cx("px-3 py-2 w-100", styles.moreRouteOptionRow)}
            disabled={
              deleteRoute.isLoading ||
              route.status === "DEPARTED" ||
              route.status === "FINISHED" ||
              route.status === "SETTLED"
            }
            onClick={() => {
              deleteRoute.mutate(route.id);
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between gap-2"
              data-tip
              data-for={`route-deletion-${route.id}`}
            >
              <div className="d-flex align-items-center gap-2">
                <img alt="usuń trasę" src={binIcon} />
                <div className="body-14-600 text-red-4">Usuń</div>
              </div>
            </div>
          </button>
          {(route.status === "DEPARTED" ||
            route.status === "FINISHED" ||
            route.status === "SETTLED") && (
            <ReactTooltip
              className={styles.customTooltip}
              id={`route-deletion-${route.id}`}
              place="top"
              effect="solid"
              arrowColor="transparent"
              offset={{ top: -10 }}
            >
              Nie można usunąć rozpoczętej lub zakończonej trasy
            </ReactTooltip>
          )}
        </div>
      </PopupMenu>
      {indexFulfillmentModal.isOpen && (
        <IndexFulfillmentModal close={indexFulfillmentModal.close} id={route.id} source="route" />
      )}
    </div>
  );
};
