import { createColumnHelper, AccessorFn, DisplayColumnDef } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { Assign } from "utility-types";

export const textColumn = <T,>(
  accessorFn: AccessorFn<T>,
  columnDef: Assign<
    Pick<DisplayColumnDef<T>, "size">,
    { typographyProps?: Partial<TypographyProps>; header: string }
  >,
) => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    header: columnDef.header,
    size: columnDef.size,
    cell: info => {
      const value = info.getValue();
      return (
        <Typography
          fontSize={columnDef.typographyProps?.fontSize || "12"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          noWrap
          {...columnDef.typographyProps}
        >
          {value || emptyValuePlaceholder}
        </Typography>
      );
    },
  });
};

const emptyValuePlaceholder = "---";
