import {
  TradingDocumentPayment,
  TradingDocumentPaymentKind,
  tradingDocumentPaymentKindForPurchaseInvoice,
  tradingDocumentPaymentModeShortcutsDict,
  TradingDocumentPaymentType,
  tradingDocumentPaymentTypeDict,
} from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelTableRow } from "components/utils/drawer";
import { paymentSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import cx from "classnames";
import { dateFns } from "utilities";
import styles from "../PaymentSection.module.css";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";

interface Props {
  payment: TradingDocumentPayment;
  position: number;
}

export const Payment = ({ payment, position }: Props) => {
  return (
    <RightPanelTableRow
      className={cx({
        [styles.tableRowPaymentsReturn]: payment.kind === "REFUND",
      })}
      grid={paymentSectionListConfig.grid}
    >
      <div>{position}.</div>
      <div>
        <StateLabel className="fw-700" kind={getStatusLabelForPaymentKind(payment.kind)}>
          {tradingDocumentPaymentKindForPurchaseInvoice[payment.kind]}
        </StateLabel>
      </div>
      <div>
        <div className={styles.paymentNumber}>
          {payment.paymentNumber ? payment.paymentNumber : "--"}
        </div>
        <div className={styles.date}>
          {payment.paymentDate ? dateFns.format(new Date(payment.paymentDate), "dd.MM.yyyy") : "--"}
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-end">
          <FinanceAmountFormat
            decimalClassName="fw-700 fs-12"
            integerClassName="fw-700 fs-12"
            value={payment.amount}
          />
          <div className="fs-12 fw-700">{payment.currency}</div>
        </div>
        <div className="d-flex align-items-center justify-content-end fw-400">
          {payment.commission !== null ? (
            <>
              <FinanceAmountFormat
                decimalClassName="fs-12"
                integerClassName="fs-12"
                value={payment.commission}
              />
              <div className="fs-12 fw-400">{payment.currency}</div>
            </>
          ) : (
            <div>nie podlega</div>
          )}
        </div>
      </div>
      <div>
        <StateLabel kind={getStatusLabelForPaymentType(payment.type)}>
          {tradingDocumentPaymentTypeDict[payment.type].charAt(0).toLowerCase() +
            tradingDocumentPaymentTypeDict[payment.type].slice(1)}
        </StateLabel>
      </div>
      <div>
        <div className={styles.provider}>{payment.provider}</div>
      </div>
      <div>
        <div className="body-10">{tradingDocumentPaymentModeShortcutsDict[payment.mode]}</div>
      </div>
      {payment.bankTransactionNumber !== "--" ? (
        <div>
          <div className={cx(styles.bankTransactionNumber, "new-link fs-12")}>
            {payment.bankTransactionNumber}
          </div>
          <div className={styles.date}>
            {payment.bankTransactionDate
              ? dateFns.format(new Date(payment.bankTransactionDate), "dd.MM.yyyy")
              : "--"}
          </div>
        </div>
      ) : (
        <div>--</div>
      )}
      <div>
        <div className="d-flex align-items-center">
          {payment.createdBy.avatar ? (
            <div className={cx(styles.avatar, styles.avatarSmall)}>
              <img alt="avatar" src={payment.createdBy.avatar} />
            </div>
          ) : (
            <InitialsAvatar
              className={cx(styles.avatar, styles.avatarSmall)}
              firstName={payment.createdBy.firstName}
              lastName={payment.createdBy.lastName}
              style={{ margin: 0 }}
            />
          )}
        </div>
      </div>
      <div className={styles.date}>{dateFns.format(new Date(payment.createdAt), "dd.MM.yyyy")}</div>
    </RightPanelTableRow>
  );
};

const getStatusLabelForPaymentKind = (kind: TradingDocumentPaymentKind) => {
  if (kind === "ADVANCE") {
    return "limeBlack";
  } else if (kind === "PAYMENT") {
    return "violetWhite";
  } else if (kind === "REFUND") {
    return "darkYellowBlack";
  } else {
    return "default";
  }
};

const getStatusLabelForPaymentType = (type: TradingDocumentPaymentType) => {
  if (type === "CASH") return "green";
  return "transparentViolet";
};
