import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { getTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { purchaseInvoices } from "components/common/moduleNavigation/moduleConfig/finances/routes/purchaseInvoices";
import { useQuery, useToggle } from "hooks";
import { UploadFilesModal } from "components/common/uploadFiles/UploadFiles";
import { postPurchaseInvoicePdf } from "api/trading-documents/calls";
import { useFinancesAmountSummary } from "api/trading-documents/hooks";
import { BottomBarOptions } from "./BottomBarOptions";
import { getSearch } from "pages/tradingDocuments/shared/utils/getSearch";
import { Tab } from "api/trading-documents/models";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
  refetchList: () => void;
  tab: Tab;
}

export const ListHeader = ({ additionalListParams, refetchList, tab }: Props) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const uploadPurchaseInvoicePdf = useToggle();
  const type = "INVOICE";
  const invoiceType = "PURCHASE";
  const search = getSearch({ query, type, invoiceType, tab });

  const { data: amountSummary, isLoading } = useFinancesAmountSummary(search);

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        additionalListParams={additionalListParams}
        bottomBarButtons={
          <BottomBarOptions
            amountSummary={amountSummary}
            isLoading={isLoading}
            uploadPurchaseInvoicePdf={uploadPurchaseInvoicePdf}
          />
        }
        createButton={{ label: "Utwórz fakturę", onClick: () => {} }}
        searchInput={{
          label: "Szukaj wśród faktur zakupowych",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${purchaseInvoices.url}`,
          urlSpan: "list",
        }}
        viewLabel="PURCHASE_INVOICES"
      />
      {uploadPurchaseInvoicePdf.isOpen && (
        <UploadFilesModal
          accepts={[".pdf"]}
          fetcher={postPurchaseInvoicePdf}
          modal={uploadPurchaseInvoicePdf}
          onSuccess={refetchList}
          title="Dodaj PDF z fakturą zakupową"
        />
      )}
    </div>
  );
};
