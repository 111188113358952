import styles from "./CommentsGenericSection.module.css";
import cx from "classnames";
import { useGetComments } from "api/comments/hooks";
import { CommentsList } from "./components/CommentsList";
import send from "assets/images/send.svg";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import { useQueryUtils, useSelector } from "hooks";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useQueryClient } from "react-query";
import openup from "assets/images/open_up.svg";
import React, { useEffect, useRef, useState } from "react";
import { postComment } from "api/comments/calls";
import { useMutation } from "hooks/useMutation";
import { commentsKeys } from "api/comments/keys";
import { PostComment } from "api/comments/models";
import { queryString, yup } from "utilities";
import { MdiVisibility } from "components/miloDesignSystem/atoms/icons/MdiVisibility";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiComment } from "components/miloDesignSystem/atoms/icons/MdiComment";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";

type ParameterName =
  | "tradingDocument"
  | "order"
  | "manufacturingSchema"
  | "stageManufacturingPlan"
  | "manufacturingOrder"
  | "externalManufacturingLineItem";

interface CommentedObject {
  id: string;
}
interface Props {
  commentedObject: CommentedObject;
  shownByDefault?: boolean;
  outsideRef?: React.RefObject<HTMLDivElement>;
  paramName: ParameterName;
  endpointUrl: string;
}

export const CommentsGenericSection = ({
  outsideRef,
  commentedObject,
  shownByDefault = false,
  paramName,
  endpointUrl,
}: Props) => {
  const [pageSize, setPageSize] = useState(3);
  const [commentsShown, setCommentsShown] = useState(shownByDefault);
  const [focused, setFocused] = useState(false);
  const getUrlWithParams =
    endpointUrl + queryString.stringify({ [paramName]: commentedObject.id, pageSize: pageSize });
  const commentsQuery = useGetComments(getUrlWithParams);
  const { rollback, handlePaginatedListUpdate } = useQueryUtils();
  const user = useSelector(state => state.auth.user);
  const queryClient = useQueryClient();
  const resizable = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        outsideRef &&
        focused &&
        outsideRef?.current &&
        outsideRef?.current.contains(event.target as Node)
      ) {
        setFocused(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  const postMutation = useMutation(postComment, {
    onMutate: toUpdate => {
      setFocused(false);
      return handlePaginatedListUpdate(
        commentsKeys.comments.list(getUrlWithParams),
        toUpdate.data.id,
        { ...toUpdate.data, created: new Date().toJSON() },
      );
    },
    onError: (error, newComment, previous) => {
      rollback(commentsKeys.comments.list(getUrlWithParams), previous, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(commentsKeys.comments.list(getUrlWithParams));
    },
  });
  const formRef = useRef<FormikProps<PostComment>>(null);

  if (!user) return null;

  const initialValues: PostComment = {
    id: "",
    body: "",
    user: user.id,
    isOnlyVisibleForTeam: false,
  };
  const validationSchema = yup.object().shape({
    body: yup.string().required("Brak treści komentarza"),
  });

  const handleSubmit = (values: PostComment, actions: FormikHelpers<PostComment>) => {
    postMutation.mutate(
      {
        data: {
          ...values,
          [paramName]: commentedObject.id,
          user: user.id,
          isOnlyVisibleForTeam: values.isOnlyVisibleForTeam,
        },
        endpointUrl: endpointUrl,
      },
      {
        onError: () => {
          actions.setSubmitting(false);
        },
        onSuccess: () => {
          actions.resetForm();
          actions.setSubmitting(false);
        },
      },
    );
  };

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.code === "Enter" && (evt.ctrlKey || evt.metaKey)) {
      formRef.current?.submitForm();
    }
  };

  return (
    <div
      ref={resizable}
      className={
        commentsShown
          ? cx(styles.commentsSection, styles.commentsSectionShown)
          : focused
          ? cx(styles.commentsSection, styles.wrapperFocused)
          : styles.commentsSection
      }
      onClick={() => setFocused(false)}
    >
      <div
        className={
          commentsShown
            ? cx(styles.commentsListWrapper, styles.commentsListWrapperShown)
            : styles.commentsListWrapper
        }
      >
        <div
          className={cx(
            "d-flex align-items-center justify-content-between gap-2 px-3 py-2",
            styles.commentsSmallWrap,
          )}
        >
          <div className="d-flex align-items-center gap-2">
            <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
              Komentarze
            </Typography>
            {Boolean(commentsQuery.data.length) && (
              <div className="d-flex align-items-center gap-2">
                <Tag
                  label={String(commentsQuery.data.length)}
                  startIcon={MdiComment}
                  variant="info"
                />
                <div className="d-flex align-items-center">
                  {commentsQuery.data.map(comment => (
                    <div className={styles.avatarCover} key={user.id}>
                      <Avatar size="xs" theme="light" user={comment.user} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => setCommentsShown(!commentsShown)}
            className={cx(
              styles.btnBase,
              styles.btnBaseSmall,
              styles.btnBgTransparentTextBlack,
              "cursor-pointer",
            )}
          >
            <img
              src={openup}
              className={commentsShown ? styles.onClickRotate : ""}
              alt="Pokaż komentarze"
            />
          </div>
        </div>
        <CommentsList
          comments={commentsQuery.data}
          pageSize={pageSize}
          getUrlWithParams={getUrlWithParams}
          setPageSize={setPageSize}
          userLoggedIn={{ ...user, isActive: true }}
          isLoading={postMutation.isLoading}
          endpointUrl={endpointUrl}
        />
      </div>

      <div
        onClick={evt => evt.stopPropagation()}
        className={focused ? cx(styles.formWrapper, styles.focused) : styles.formWrapper}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
            <form
              onFocus={() => setFocused(true)}
              onKeyDown={() => setFocused(true)}
              onSubmit={handleSubmit}
              className="d-flex align-items-center p-0 m-0 gap-2 px-3 py-2 h-100"
            >
              <AvatarOrInitials
                avatarSrc={user.avatar}
                firstName={user.firstName}
                lastName={user.lastName}
                className={cx(styles.avatar, styles.avatarMedium, "m-0 ")}
              />
              <div className={styles.inputBox}>
                <Field
                  as="textarea"
                  autoComplete="off"
                  name="body"
                  className="input w-100"
                  placeholder="Napisz komentarz"
                  onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) =>
                    handleKeyPress(event)
                  }
                />
                <div className="d-flex align-items-center">
                  <Tooltip
                    title={
                      values.isOnlyVisibleForTeam
                        ? "Widoczny dla zespołu"
                        : "Widoczny dla wszystkich"
                    }
                  >
                    <IconButton
                      icon={
                        values.isOnlyVisibleForTeam ? (
                          <MdiVisibilityOff color="red100" size="20" />
                        ) : (
                          <MdiVisibility color="green100" size="20" />
                        )
                      }
                      onClick={() =>
                        setFieldValue("isOnlyVisibleForTeam", !values.isOnlyVisibleForTeam)
                      }
                      variant="whiteST"
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="position-relative">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={cx(
                    styles.btnSizeSX,
                    styles.btnBgDeepPurple,
                    styles.commentSubmit,
                    "btn uppercase",
                  )}
                >
                  <div className={cx(styles.btnBase, styles.btnBaseSmall)}>
                    <img src={send} alt="Utwórz komentarz" />
                  </div>
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
