import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

export interface UiSchema {
  header: {
    backgroundColor: ColorPalette;
  };
  cell: {
    height: string | number;
  };
}

const DENSITY_STANDARD_CELL_HEIGHT = "34";
const DENSITY_STANDARD_HEADER_BACKGROUND_COLOR: ColorPalette = "grey25";

const DENSITY_COMPACT_CELL_HEIGHT = "26";
const DENSITY_COMPACT_HEADER_BACKGROUND_COLOR: ColorPalette = "neutralWhite100";

const DENSITY_COMFORTABLE_CELL_HEIGHT = "28";
const DENSITY_COMFORTABLE_HEADER_BACKGROUND_COLOR: ColorPalette = "neutralWhite100";

export const detailsListUiSchema: UiSchema = {
  header: {
    backgroundColor: DENSITY_COMPACT_HEADER_BACKGROUND_COLOR,
  },
  cell: {
    height: DENSITY_COMPACT_CELL_HEIGHT,
  },
};

export const mainListUiSchema: UiSchema = {
  header: {
    backgroundColor: DENSITY_STANDARD_HEADER_BACKGROUND_COLOR,
  },
  cell: {
    height: DENSITY_STANDARD_CELL_HEIGHT,
  },
};

export const comfortableListUiSchema: UiSchema = {
  header: {
    backgroundColor: DENSITY_COMFORTABLE_HEADER_BACKGROUND_COLOR,
  },
  cell: {
    height: DENSITY_COMFORTABLE_CELL_HEIGHT,
  },
};
