import { AddedMethodChoices, ShippingPiece } from "api/shipping/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { shippingActions } from "api/shipping/actions";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { UUID } from "api/types";
import { memo, useState } from "react";
import { AsyncInput } from "components/utils";
import styles from "../../RightPanel.module.css";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

export const usePiecesColumns = () => {
  const patchPackage = shippingActions.usePatchShippingPiece();
  const [editingId, setEditingId] = useState("");
  return useCreateTableColumns<ShippingPiece>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "#",
        size: 20,
        cell: info => {
          const counter = info.row.index + 1;
          const row = info.getValue();
          return (
            <Typography fontSize="14" fontWeight="700" {...getExcludedProps(row)}>
              {counter}.
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "package",
        header: () => <HeaderCell label="paczka" />,
        size: 148,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell editingId={editingId} setEditingId={setEditingId} name="name" row={row} />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "weight",
        header: () => <HeaderCell label="waga" />,
        size: 77,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="weight"
              row={row}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "length",
        header: () => <HeaderCell label="długość" />,
        size: 77,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="length"
              row={row}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "width",
        header: () => <HeaderCell label="szerokość" />,
        size: 77,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="width"
              row={row}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "height",
        header: () => <HeaderCell label="wysokość" />,
        size: 77,

        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="height"
              row={row}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "uwzględniaj paczkę",
        size: 100,
        cell: info => {
          const value = info.getValue() as ShippingPiece;
          return (
            <div className="d-flex align-items-center justify-content-center flex-1">
              <Checkbox
                checked={value.isIncludedForSend}
                onChange={isIncludedForSend => {
                  patchPackage.mutate({
                    id: value.id,
                    toUpdate: { isIncludedForSend },
                  });
                }}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: " ",
        size: 26,
        cell: info => {
          const row = info.getValue() as ShippingPiece;
          return <DeletePieceCell row={row} />;
        },
      }),
    ];
  });
};
const DeletePieceCell = ({ row }: { row: ShippingPiece }) => {
  const deletePieceMutation = shippingActions.useDeleteShippingPiece();
  return (
    <div className="d-flex align-items-center justify-content-center flex-1">
      <IconButton
        icon={MdiDelete}
        disabled={
          row.addedMethod === AddedMethodChoices.AUTOMATICALLY || deletePieceMutation.isLoading
        }
        variant="blackT"
        onClick={() => {
          deletePieceMutation.mutate(row.id);
        }}
      />
    </div>
  );
};
const HeaderCell = ({ label }: { label: string }) => {
  return (
    <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="ml-2">
      {label}
    </Typography>
  );
};

const EditableCell = ({
  row,
  name,
  editingId,
  setEditingId,
}: {
  name: keyof ShippingPiece;
  row: ShippingPiece;
  editingId: string;
  setEditingId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const value = String(row[name]);

  const isEditing = getEditingId(row.id, name) === editingId;

  if (!isEditing) {
    const unit = (() => {
      if (name === "weight") return "kg";
      if (name === "name") return "";
      return "cm";
    })();

    return (
      <div onClick={() => setEditingId(getEditingId(row.id, name))} className={styles.editableCell}>
        <Typography
          fontSize={name === "name" ? "14" : "12"}
          fontWeight="600"
          noWrap
          {...getExcludedProps(row)}
        >
          {value} {unit}
        </Typography>
      </div>
    );
  }
  return (
    <EditInput name={name} rowId={row.id} value={value} exitEditingMode={() => setEditingId("")} />
  );
};

const EditInput = memo(
  ({
    name,
    rowId,
    value,
    exitEditingMode,
  }: {
    rowId: UUID;
    name: keyof ShippingPiece;
    value: string;
    exitEditingMode: () => void;
  }) => {
    const patchPiece = shippingActions.usePatchShippingPiece();
    return (
      <AsyncInput
        disabled={patchPiece.isLoading}
        autoFocus
        inProgress={patchPiece.isLoading}
        onChange={async value => {
          await patchPiece.mutateAsync({
            id: rowId,
            toUpdate: { [name]: value },
          });
          exitEditingMode();
        }}
        overwrites={{
          input: { className: styles.input },
        }}
        placeholder={value}
        value={value}
      />
    );
  },
);

const getExcludedProps = (row: ShippingPiece): Pick<TypographyProps, "color" | "className"> => {
  if (!row.isIncludedForSend) {
    return { color: "neutralBlack48", className: "line-through" };
  }
  return {};
};

function getEditingId(rowId: string, name: keyof ShippingPiece) {
  return `${rowId}-${name}`;
}
